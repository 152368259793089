import { faX } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../components/Layout";
import PaymentStep from '../form-new/PaymentStep';
import SelectPlanAndPayment from '../form-new/SelectPlanAndPayment';

const UploadPlan = () => {
    const navigate = useNavigate();
    const [selectItem, setSelectItem] = useState(0);
    const { token } = useSelector(state => state.auth);
    const [planSelect, setPlanSelect] = useState({});
    const location = useLocation();
    const { propertyId } = location.state || {};

    const items = {
        0: <SelectPlanAndPayment
            step={selectItem}
            setStep={setSelectItem}
            selectedPlan={planSelect}
            setSelectedPlan={setPlanSelect}
            showStep={false}
        />,
        1: <PaymentStep
            step={selectItem}
            setStep={setSelectItem}
            propertyId={propertyId}
            selectedPlan={planSelect}
            showStep={false}
        />,
    };

    return (
        <RootLayout admin>
            <div className="bg-gradient-to-r from-purple-500 to-indigo-500 h-[70px] mt-2"></div>
            <div className="flex flex-wrap justify-center items-start min-h-screen -mt-9">
                <div className={`relative flex items-center justify-center bg-white shadow-2xl rounded-lg p-8 w-full mx-4 max-w-4xl`}>
                    <div className="absolute top-4 right-4 cursor-pointer" onClick={() => navigate("/admin/dashboard")}>
                        <FontAwesomeIcon icon={faX} className="text-gray-500 hover:text-gray-700 transition duration-200" />
                    </div>
                    {items[selectItem]}
                </div>
            </div>
        </RootLayout>
    );
};

export default UploadPlan;
