import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PlanCard from '../../../components/cards/PlanCard';
import { getPlans } from '../../../services/payment.service';
import { useNavigate, useLocation } from 'react-router-dom';

const SelectPlanAndPayment = ({ step, setStep, setSelectedPlan, selectedPlan, showStep, contador }) => {
    const { token } = useSelector((state) => state.auth);
    const [plans, setPlans] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchPlans();
    }, [token]);

    const fetchPlans = async () => {
        const resp = await getPlans(token);
        if (resp.data) {
            setPlans(resp.data.membershipPlans);
        }
    };

    const selectPlan = (plan) => {
        setSelectedPlan(plan);
    };

    const onConfirmPlan = () => {
        setStep(step + 1);
    };

    const handleSuccessRedirect = () => {
        navigate('/admin/dashboard/');
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleRegresar = () => {
        console.log(location.state);
        if (location.state?.from === "/admin/dashboard") {
            navigate("/admin/dashboard");
        } else {
            console.log("Ejecutando la lógica actual del botón");
            setStep(prevStep => prevStep - 1)
            console.log("Ejecutando la lógica actual del botón");
        }

    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full"
        >
            {showStep && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Selecciona un plan</h3>
            <p className="text-gray-600 mb-6">Elige el plan que mejor se adapte a tus necesidades para aumentar la visibilidad de tu inmueble.</p>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 pointer-none">
                {plans.map((plan) => (
                    <PlanCard key={plan.id} plan={plan} selectPlan={selectPlan} selectedPlan={selectedPlan} />
                ))}

            </div>
            <div className="flex items-center space-x-2 my-2">
                <input
                    type="checkbox"
                    id="terms"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor="terms" className="text-gray-700">
                    Aceptar{' '}
                    <a href="https://yorento.com/terminos-y-condiciones.pdf" target="_blank" rel="noopener noreferrer" className="font-bold text-blue-600">
                        términos y condiciones
                    </a>
                </label>
            </div>
            <div className="w-full flex flex-col-reverse md:flex-row justify-between items-center gap-4 px-4 md:px-16">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full md:w-auto text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={handleRegresar}
                >
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`w-full md:w-auto text-white transition duration-300 px-6 py-3 rounded-full ${selectedPlan && isChecked ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
                        }`}
                    onClick={onConfirmPlan}
                    disabled={!selectedPlan || !isChecked}
                >
                    Continuar
                </motion.button>
            </div>

        </motion.div>
    );
};

export default SelectPlanAndPayment;
