import React, { useState } from "react";
import TextInput from "../textinput";

const ControlButton = () => {
  const [quantity, setQuantity] = useState(0);

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  return (
    <div className="relative flex items-center max-w-[7rem]">
      <button
        type="button"
        id="decrement-button"
        onClick={handleDecrement}
        className="bg-[#35BCFC] rounded-s-lg p-2 h-8 "
      >
        <svg
          className="w-3 h-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 2"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h16"
          />
        </svg>
      </button>
      <TextInput
        type="text"
        id="quantity-input"
        data-input-counter
        aria-describedby="helper-text-explanation"
        className="bg-[!transparent] border-x-0  h-8 text-center text-gray-700 border  rounded-none border-[#35BCFC] block w-10 py-2.5 focus:outline-none"
        value={quantity}
        readOnly
        required
      />
      <button
        type="button"
        id="increment-button"
        onClick={handleIncrement}
        className="bg-[#35BCFC] rounded-e-lg p-2 h-8"
      >
        <svg
          className="w-3 h-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 1v16M1 9h16"
          />
        </svg>
      </button>
    </div>
  );
};

export default ControlButton;
