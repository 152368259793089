import { motion } from 'framer-motion';
import React from 'react';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/header/Navbar';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5
        }
    }
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        }
    }
};

const AvisoPrivacidad = () => {


    return (
        <>
            <Navbar />
            <div className="bg-gray-100 py-[2rem] text-gray-900">
                <div className="bg-white rounded-lg container mx-auto text-gray-900">
                    <div className="container mx-auto py-12 p-6 lg:px-[6rem] xl:px-[15rem]">
                        <h1 className="text-3xl font-bold text-center mb-6">TÉRMINOS Y CONDICIONES GENERALES</h1>
                        <motion.div
                            className="space-y-6"
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            <motion.section variants={itemVariants}>
                                <>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h1
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        SWMITH, S.A.P.I. DE C.V.{" "}
                                        <span className="p">(en lo sucesivo mejor conocido como “</span>Yo Rento
                                        <a
                                            href="http://www.yorento.comx/"
                                            style={{
                                                color: "black",
                                                fontFamily: "Arial, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "none",
                                                fontSize: "12pt"
                                            }}
                                            target="_blank"
                                        >
                                            ”), con domicilio en calle Avenida Américas #1930, piso 2, interior 7,
                                            colonia Country Club, en la ciudad Guadalajara, Jalisco, México, C.P.
                                            44610, hace de su conocimiento los términos y condiciones generales (en lo
                                            sucesivo “Términos y Condiciones Generales”) que regulan los Servicios que
                                            ofrece a través de su portal de internet{" "}
                                        </a>
                                        <span
                                            style={{
                                                color: "#00F",
                                                fontFamily: "Arial, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "underline",
                                                fontSize: "12pt"
                                            }}
                                        >
                                            www.yorento.com
                                        </span>
                                        <span className="s1"> </span>
                                        <span className="p">
                                            y la aplicación móvil correspondiente (en lo sucesivo y de manera conjunta
                                            la “Plataforma”).
                                        </span>
                                    </h1>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Aceptación del Usuario:
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Los Servicios de <b>Yo Rento </b>están dirigidos a personas físicas o
                                        jurídicas con capacidad legal para contratar. Cualquier persona, al utilizar
                                        la Plataforma tendrá la condición de usuario (en lo sucesivo el "Usuario").
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        No podrán utilizar los Servicios las personas que no tengan esa capacidad,
                                        los menores de edad o los Usuarios que hayan sido suspendidos de la
                                        Plataforma.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Al utilizar y navegar por la Plataforma y/o al registrarse como Usuario
                                        usted declara con carácter de declaración bajo protesta de decir verdad que:
                                        i) es una persona física mayor de 18 años cumplidos, con capacidad jurídica
                                        para contratar; o que ii) es el representante de una persona jurídica y que
                                        tiene poder suficiente para contratar en nombre de dicha persona jurídica.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Estos Términos y Condiciones Generales constituyen un contrato vinculante
                                        entre los Usuarios y <b>Yo Rento</b>, por lo que es importante que los lea
                                        con detenimiento antes de aceptarlos.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Para todos los efectos legales, se entiende que el Usuario ha leído y
                                        acepta, sin limitación ni condición alguna, los presentes Términos y
                                        Condiciones Generales al acceder, navegar y utilizar cualquier componente de
                                        la Plataforma, utilizar sus herramientas, funciones o servicios, registrarse
                                        como miembro, o consultar
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        cualquier texto, gráfico o video mediante cualquier equipo tecnológico, sea
                                        de cómputo, teléfono móvil, tableta o cualquier otro similar o análogo.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Si el Usuario no está de acuerdo con estos Términos y Condiciones Generales
                                        y, por ende, no desea obligarse conforme a los mismos, debe abstenerse de
                                        acceder a la Plataforma y utilizar cualesquiera de los Servicios. Lo
                                        anterior en el entendido de que el abandono de la Plataforma por parte del
                                        Usuario no implicará la liberación respecto de las obligaciones previamente
                                        adquiridas de conformidad con lo dispuesto en los presentes Términos y
                                        Condiciones Generales.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            se reserva el derecho de suspender, cancelar o impedir el uso de la
                                            Plataforma a quienes no cumplan con los términos y condiciones descritos
                                            en este documento.
                                        </span>
                                    </h1>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Servicios
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h1
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            tiene por objeto diseñar, desarrollar, actualizar, administrar, mantener,
                                            comercializar y/u operar todo tipo de sistemas de programas de cómputo
                                            (software), aplicaciones para distintas plataformas y sistemas operativos,
                                            propios o de terceros, que le permiten actuar en la intermediación
                                            inmobiliaria de alquileres.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        En virtud de lo anterior, los Servicios ofrecidos a través de la Plataforma
                                        comprenden: oferta de propiedades para arrendamiento, búsqueda de
                                        propiedades, servicios de asesoramiento, servicios de consulta, comunicación
                                        con asesores, potenciales inquilinos y/o propietarios de inmuebles, así como
                                        servicios de evaluación de potenciales inquilinos (en lo sucesivo los
                                        “Servicios”).
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Cada uno de los Servicios ofrecidos podrán estar sujetos a términos y
                                        condiciones particulares complementarias a las establecidas en los presentes
                                        Términos y Condiciones Generales, las cuales deberán ser aceptadas por el
                                        Usuario previo a su uso. Las condiciones particulares prevalecerán sobre los
                                        presente Términos y Condiciones Generales en caso de conflicto.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Registro de Usuarios
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Los Usuarios no registrados podrán consultar el catálogo de inmuebles que
                                        figuren en el apartado de Anuncios de la Plataforma. Sin embargo, para
                                        publicar Anuncios como Arrendador o, en su caso, tener acceso a los
                                        servicios de mensajería para facilitar la comunicación entre Arrendadores y
                                        inquilinos, así como evaluación, los Usuarios deberán crear una cuenta de{" "}
                                        <b>Yo Rento </b>y registrarse en la Plataforma.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El llenado de los formularios de registro en la Plataforma se someterá a las
                                        siguientes reglas:
                                    </p>
                                    <ul id="l1">
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "147%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                El Usuario se obliga a proporcionar información verídica, actualizada,
                                                completa y precisa.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "146%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                La información y datos proporcionados por el Usuario se regirá por lo
                                                establecido en el Aviso de Privacidad.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "149%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                El Usuario se obliga a actualizar su información, de manera inmediata,
                                                ante la ocurrencia de algún cambio en la misma o ante el advertimiento
                                                de algún error al momento del registro, para efectos que dicha
                                                información permanezca actualizada, auténtica, precisa, y completa.
                                            </p>
                                            <h1
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Yo Rento{" "}
                                                <span className="p">
                                                    se reserva el derecho de suspender, cancelar y/o impedir el uso
                                                    presente o futuro de la Plataforma, de los Servicios o cualquier parte
                                                    de los mismos, cuando se verifique que la información suministrada por
                                                    el Usuario es falsa, inexacta, desactualizada o incompleta, o cuando{" "}
                                                </span>
                                                Yo Rento{" "}
                                                <span className="p">
                                                    tenga motivos suficientes y razonables para sospechar que dicha
                                                    información es falsa, inexacta, desactualizada o incompleta.
                                                </span>
                                            </h1>
                                            <h1
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Manejo de contraseña
                                            </h1>
                                            <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                El Usuario registrado se obliga a mantener su contraseña en secreto. No
                                                deberá compartir su contraseña o el acceso a su cuenta con ninguna otra
                                                persona y respetará las normas de <b>Yo Rento </b>y la legislación
                                                aplicable.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Al momento de registrarse en la Plataforma el Usuario de obliga a:
                                            </p>
                                            <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Elegir una contraseña segura;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Mantener la confidencialidad de la contraseña;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "6pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "146%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Actualizar y cambiar periódicamente, con una frecuencia razonable, la
                                                contraseña;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "148%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Notificar a <b>Yo Rento </b>de manera inmediata de cualquier uso
                                                indebido o sospecha de uso indebido de su cuenta o cualquier otra
                                                vulneración de seguridad.
                                            </p>
                                        </li>
                                    </ul>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario registrado es responsable del uso y todo lo que suceda en su
                                        cuenta, salvo que cierre su sesión o notifique de manera previa y oportuna
                                        que se esté realizando un uso indebido de la misma.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Normas generales
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        De manera enunciativa y no limitativa, el Usuario se compromete de manera
                                        expresa a no realizar los siguientes actos y/o actividades al hacer uso de
                                        nuestra Plataforma:
                                    </p>
                                    <ol id="l2">
                                        <li data-list-text="a)">
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                A ceder o transferir de otro modo el acceso como Usuario a cualquier
                                                otra persona o entidad;
                                            </p>
                                        </li>
                                        <li data-list-text="b)">
                                            <p
                                                style={{ paddingLeft: "40pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Hacer un uso no autorizado o fraudulento de la Plataforma;
                                            </p>
                                        </li>
                                        <li data-list-text="c)">
                                            <p
                                                style={{
                                                    paddingTop: "6pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Acceder o intentar acceder a recursos restringidos de la Plataforma;
                                            </p>
                                        </li>
                                        <li data-list-text="d)">
                                            <p
                                                style={{
                                                    paddingTop: "6pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Utilizar la Plataforma con fines ilícitos, ilegales, contrarios a lo
                                                establecido en los presentes Términos y Condiciones Generales;
                                            </p>
                                        </li>
                                        <li data-list-text="e)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Provocar daños en la Plataforma o en los sistemas de sus proveedores o
                                                de terceros;
                                            </p>
                                        </li>
                                        <li data-list-text="f)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Causar o lanzar cualquier programa o script con el objeto de extraer,
                                                indexar, analizar o de otro modo realizar prospección de datos de
                                                cualquier parte de los Servicios o sobrecargar o bloquear indebidamente
                                                la operación y/o funcionalidad de cualquier aspecto de los Servicios;
                                            </p>
                                        </li>
                                        <li data-list-text="g)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Intentar obtener un acceso no autorizado o dañar cualquier aspecto de la
                                                Plataforma, Servicios o sus sistemas o redes relacionadas;
                                            </p>
                                        </li>
                                        <li data-list-text="h)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Intentar acceder, utilizar y/o manipular los datos de <b>Yo Rento </b>
                                                y/o sus Usuarios;
                                            </p>
                                        </li>
                                        <li data-list-text="i)">
                                            <p
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                No introducir o difundir contenidos o propaganda de carácter racista,
                                                xenófobo o, en general, discriminatorio, pornográfico, de apología del
                                                delito o que atenten, vulneren o pudieren atentar o vulnerar los
                                                derechos humanos;
                                            </p>
                                        </li>
                                        <li data-list-text="j)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Introducir o difundir en la red programas de datos (virus y/o software
                                                nocivo) susceptibles a provocar daños en los sistemas informáticos de{" "}
                                                <b>Yo Rento</b>, sus proveedores, terceros o, en general, cualquier
                                                usuario de la red Internet;
                                            </p>
                                        </li>
                                        <li data-list-text="k)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Difundir, transmitir o poner a disposición de terceros cualquier tipo de
                                                información, elemento o contenido que atente contra los derechos
                                                fundamentales y las libertades públicas reconocidos constitucionalmente
                                                y en los tratados internacionales;
                                            </p>
                                        </li>
                                        <li data-list-text="l)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Difundir, transmitir o poner a disposición de terceros cualquier tipo de
                                                información, elemento o contenido que constituya publicidad engañosa,
                                                ilícita o desleal;
                                            </p>
                                        </li>
                                        <li data-list-text="m)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Transmitir publicidad no solicitada o autorizada, material publicitario,
                                                "correo basura o spam", "cartas en cadena", "estructuras piramidales", o
                                                cualquier otra forma de solicitación, excepto en aquellas áreas (tales
                                                como espacios comerciales) que hayan sido exclusivamente concebidas para
                                                ello mediante una comunicación expresa de <b>Yo Rento</b>, comunicada
                                                debida y oportunamente en la Plataforma y habiendo cubierto previamente
                                                la contraprestación que, en su caso, se haya establecido;
                                            </p>
                                        </li>
                                        <li data-list-text="n)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Introducir o difundir cualquier información y contenidos falsos,
                                                ambiguos o inexactos de forma que induzca a error a los receptores de la
                                                información;
                                            </p>
                                        </li>
                                        <li data-list-text="o)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Difundir, transmitir o poner a disposición de terceros cualquier tipo de
                                                información, elemento o contenido que suponga una violación de los
                                                derechos de propiedad intelectual e industrial, patentes, marcas o
                                                derechos de autor que correspondan a <b>Yo Rento </b>o a terceros;
                                            </p>
                                        </li>
                                        <li data-list-text="p)">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "150%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Difundir, transmitir o poner a disposición de terceros cualquier tipo de
                                                información, elemento o contenido que suponga una violación del secreto
                                                de las comunicaciones y la legislación de datos de carácter personal y,
                                                en general, todas las normas jurídicas que regulen la protección y
                                                promoción del respeto a la vida privada e intimidad de las personas y
                                                sus familias.
                                            </p>
                                        </li>
                                    </ol>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario se obliga a sacar en paz y a salvo y a indemnizar a{" "}
                                        <b>Yo Rento </b>ante cualquier posible reclamación, multa, pena, sanción o
                                        indemnización de cualquier naturaleza, derivada del incumplimiento por parte
                                        del Usuario de cualquiera de las normas de uso antes indicadas,
                                        reservándose, además, <b>Yo Rento </b>el derecho a reclamar la indemnización
                                        por daños y perjuicios que corresponda.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        En el caso de que <b>Yo Rento </b>realice cualquier erogación con motivo de
                                        reclamaciones formuladas por terceros, derivadas de un uso indebido de la
                                        Plataforma por parte del Usuario, <b>Yo Rento </b>se subrogará en los
                                        derechos de la parte reclamante o, en su caso, podrá repetir en contra del
                                        Usuario.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Terminación
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario puede suspender su participación y el acceso a la Plataforma en
                                        cualquier momento.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario reconoce y acepta que <b>Yo Rento </b>puede suspender, cancelar
                                        y/o impedir el uso presente o futuro de la Plataforma, los Servicios o
                                        cualquier parte de los mismos, sin previo aviso y por cualquier razón con la
                                        única obligación de notificarlo a través de medios virtuales o tecnológicos.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario acepta que, al cese o interrupción por cualquier razón,{" "}
                                        <b>Yo Rento </b>puede eliminar toda la información relacionada con el
                                        Usuario y puede impedir el acceso a la Plataforma y la utilización de sus
                                        Servicios.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Anuncios de inmuebles
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Para crear y publicar un Anuncio como Arrendador, al Usuario se le
                                        solicitará información sobre el inmueble que pretende arrendar, entre la
                                        cual se encuentra, de manera enunciativa mas no limitativa, ubicación,
                                        características, fotografías, precio, normas y condiciones de contratación.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Una vez publicado el Anuncio, cualquier persona podrá tener acceso al mismo
                                        y será público en la Plataforma.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Una vez que el inmueble respectivo sea arrendado o no se encuentre
                                        disponible para arrendar con motivo de cualquier impedimento legal, el
                                        Usuario que haya
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        publicado un Anuncio como Arrendador se obliga a marcarlo como rentado o
                                        remover el Anuncio respectivo de la Plataforma / informar a <b>Yo Rento </b>
                                        para efectos de que remueva el Anuncio correspondiente.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        La información relativa a los inmuebles permanecerá guardada por un periodo
                                        12 meses en la Plataforma para que pueda ser utilizada en futuros anuncios
                                        por los Usuarios registrados como Arrendadores, salvo que estos opten por su
                                        eliminación.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Al publicar un Anuncio como Arrendador, el Usuario garantiza que:
                                    </p>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <ul id="l3">
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "146%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                La información proporcionada sobre el inmueble es verídica, actualizada,
                                                completa y precisa;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "148%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Utilizará los servicios de <b>Yo Rento </b>en cumplimiento a la
                                                legislación aplicable y no viola derechos de terceros u obligaciones a
                                                que esté sujeto el inmueble o el Usuario;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "148%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Cuenta con la libre disposición del inmueble o, en su caso, está
                                                autorizado en términos de la legislación civil aplicable para arrendar
                                                el inmueble objeto del Anuncio;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "146%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                El inmueble objeto del Anuncio está libre de cualquier tipo de adeudos,
                                                gravámenes o cualquier otro tipo de limitación para disponer del mismo;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "146%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                El inmueble objeto del Anuncio satisface la regulación aplicable en
                                                materia de seguridad e higiene, salud y protección civil; y
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "149%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                La información que reciba de otros Usuarios, señalando de manera
                                                enunciativa y no limitativa, información crediticia, información
                                                financiera, antecedentes penales, entre otra, será única y
                                                exclusivamente empleada para su valoración como potencial inquilino y
                                                su tratamiento estará limitado exclusivamente en relación con la
                                                potencial celebración de un contrato de arrendamiento.
                                            </p>
                                        </li>
                                    </ul>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            no tiene control del contenido de ninguno de los Anuncios, ni el estado,
                                            situación jurídica o idoneidad de ningún inmueble anunciado, ni es
                                            propietario, poseedor, depositario, administrador ni tenedor de los
                                            inmuebles ofrecidos, por lo que no es responsable por la existencia,
                                            calidad, cantidad, estado, o integridad de
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        los inmuebles ofrecidos o arrendados por los Usuarios, ni por la exactitud,
                                        veracidad, exhaustividad y actualidad de la información contenida en los
                                        Anuncios publicados, ni de la capacidad de los Arrendadores para contratar.
                                        En virtud de lo anterior, <b>Yo Rento </b>no asume responsabilidad alguna en
                                        relación con los Anuncios publicados y su contenido.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El sistema de búsqueda provisto por <b>Yo Rento </b>deberá utilizarse sólo
                                        como una guía complementaria, debiendo el Usuario en todos los casos
                                        confirmar los datos expuestos en la Plataforma con la información
                                        proporcionada por otros Usuarios.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            única y exclusivamente brinda servicios para el acercamiento entre
                                            potenciales arrendadores y inquilinos, así como ofrece herramientas que
                                            permiten la evaluación de estos últimos, en caso de que dicho servicio
                                            específico sea contratado, por lo que no tiene injerencia alguna en la
                                            elaboración, revisión, negociación o formalización de cualquier contrato
                                            de arrendamiento que al efecto celebren sus Usuarios, por lo que el
                                            contenido, alcance, condiciones, términos, obligaciones, derechos y
                                            renuncias de derechos consignados en tales instrumentos quedan al arbitrio
                                            de los partes contratantes, eximiendo en este acto a{" "}
                                        </span>
                                        Yo Rento{" "}
                                        <span className="p">
                                            de cualquier responsabilidad que pudiera derivar del incumplimiento de
                                            alguna de las partes.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        De manera expresa, el Usuario reconoce que la evaluación de potenciales
                                        inquilinos, denominada “Score de Riesgo” no debe entenderse como una
                                        garantía de solvencia económica o de pago de cualquier obligación que asuma
                                        al amparo de un contrato de arrendamiento celebrado con otros Usuarios de la
                                        Plataforma.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Las partes liberan a <b>Yo Rento </b>de cualquier obligación que legalmente
                                        corresponda al arrendador, inquilino o cualquier tipo de garante. Cada
                                        Usuario reconoce que al realizar operaciones con otros Usuarios lo hace bajo
                                        su propio riesgo.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        De igual manera, se hace constar que <b>Yo Rento </b>no tiene injerencia
                                        directa en la fijación de rentas, ni en el cobro de las mismas por parte de
                                        los Usuarios que
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "left"
                                        }}
                                    >
                                        publiquen Anuncios como Arrendador en caso de concretar la celebración de
                                        contratos de arrendamiento.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "left"
                                        }}
                                    >
                                        Asimismo, <b>Yo Rento </b>no cobra comisión alguna sobre el monto de rentas
                                        cobradas, ni realiza retenciones de impuestos o cualquier otro concepto.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Suspensión de la Plataforma
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h1
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            se reserva el derecho a modificar o interrumpir, en cualquier momento, de
                                            manera temporal o permanentemente, la Plataforma, los Servicios o
                                            cualquier parte de los mismos, con la única obligación de notificarlo a
                                            través de medios virtuales o tecnológicos, pudiendo realizarse a través de
                                            la misma Plataforma.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario acepta que <b>Yo Rento </b>no será responsable ante el Usuario ni
                                        ante ningún tercero por ninguna modificación, suspensión o interrupción de
                                        las actividades de la Plataforma.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        No discriminación
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Cualquier usuario podrá acceder a la Plataforma y a los Servicios; en{" "}
                                        <b>Yo Rento </b>no negaremos, condicionaremos, ni estableceremos
                                        preferencias por razones de género, nacionalidad, étnicas, preferencia
                                        sexual, religiosas o cualquiera otro motivo que se traduzca en
                                        discriminación.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        De esta manera, no se llevarán a cabo prácticas de selección de clientela,
                                        exclusión a personas con discapacidad u otras prácticas similares, salvo por
                                        causas que afecten la seguridad o tranquilidad de la Plataforma y/o la
                                        prestación de los Servicios, de otros Usuarios o de las personas
                                        discapacitadas, o se funden en disposiciones expresas de otros ordenamientos
                                        legales.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Enlaces
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        La Plataforma puede contener enlaces a sitios web de terceros o publicidad
                                        de terceros. <b>Yo Rento </b>no se hace responsable de su contenido, ni de
                                        la disponibilidad o exactitud de los mismos. Los enlaces en la Plataforma no
                                        implican ningún respaldo por parte de <b>Yo Rento </b>a los sitios web o
                                        respecto de los productos o servicios
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "left"
                                        }}
                                    >
                                        anunciados, ni su contenido. El Usuario asume bajo su propio riesgo el
                                        acceso a dichos sitios web o del uso de los productos o servicios ofertados.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "left"
                                        }}
                                    >
                                        Algunas partes de la Plataforma funcionan con servicios de terceros como lo
                                        son Facebook, X, LinkedIn, Círculo de Crédito, Google, entre otros.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Privacidad y Cookies
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        <span className="h1">Yo Rento </span>
                                        <span className="p">
                                            utilizará los datos e información proporcionada por el Usuario de
                                            conformidad con su política de protección de datos personales, la cual
                                            puede ser consultada en: https://
                                        </span>
                                        <span className="s2" style={{ backgroundColor: "#FF0" }}>
                                            ***
                                        </span>
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        De igual manera, se hace del conocimiento del Usuario que nuestra Plataforma
                                        puede utilizar cookies, web beacons y otras tecnologías, a través de las
                                        cuales es posible monitorear su comportamiento como usuario de Internet,
                                        brindarle un mejor servicio y experiencia de usuario, así como ofrecerle
                                        nuevos productos y servicios basados en sus preferencias. Para conocer más
                                        sobre ello o cómo limitarlas, consulte nuestra política de protección de
                                        datos personales.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Propiedad intelectual
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario reconoce que los derechos de propiedad intelectual de la
                                        Plataforma, su contenido e información son propiedad exclusiva de{" "}
                                        <b>Yo Rento </b>o sus afiliadas, y quedan expresamente reservados todos los
                                        derechos sobre los mismos, por lo que se prohíbe expresamente su uso total o
                                        parcial sin su autorización expresa, no estando permitida la reproducción
                                        total ni parcial de los mismos, salvo autorización expresa y por escrito
                                        previa.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Usted se compromete a no retirar, alterar ni ocultar avisos de derechos de
                                        autor, marcas comerciales, marcas de servicio u otros derechos de propiedad
                                        que vayan incorporados o que acompañen a la Plataforma.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Todas las marcas, logotipos, avisos comerciales y cualquier otra designación
                                        de propiedad de <b>Yo Rento </b>utilizados en la Plataforma son propiedad
                                        industrial propiedad de <b>Yo Rento </b>o sus afiliadas.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        En el caso de marcas, logotipos, avisos comerciales y cualesquiera otras
                                        designaciones propiedad de terceros utilizados en relación con la Plataforma
                                        se utilizan solamente para fines de identificación y son propiedad de sus
                                        respectivos propietarios.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario no adquiere ningún derecho de propiedad intelectual por el simple
                                        uso de los servicios y contenidos de la Plataforma, y en ningún momento
                                        dicho uso será considerado como una autorización o licencia de los derechos
                                        de propiedad intelectual. Razón por la cual, se prohíbe el uso de estos para
                                        fines distintos a los que se contemplan en los presentes Términos y
                                        Condiciones Generales.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Cualquier uso no autorizado de los contenidos constituirá una violación del
                                        presente documento y a las normas nacionales e internacionales sobre
                                        propiedad intelectual, y a cualquier otra que sea aplicable.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        En caso de encontrar información que pudiera ser violatoria de derechos de
                                        propiedad intelectual, tendrá que ponerse en contacto con nosotros a través
                                        de la cuenta de correo electrónico **@yorento.com.mx, para poder tomarlas
                                        medidas necesarias para el cumplimiento de las leyes y normas aplicables.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Leyes y jurisdicción
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h1
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            opera la Plataforma y todas sus actividades desde México y no garantiza
                                            que el contenido es apropiado o disponible para su uso en otros lugares.
                                            Si usted tiene acceso a la Plataforma desde un lugar ubicado fuera del
                                            territorio mexicano, lo hace bajo su propio riesgo y es responsable del
                                            cumplimiento de las leyes aplicables en su jurisdicción.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Absténgase de utilizar la Plataforma si tal conducta infringe el
                                        ordenamiento jurídico del país donde usted reside o utiliza la Plataforma.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Los presentes Términos y Condiciones se regirán e interpretarán de
                                        conformidad con las leyes de los Estados Unidos Mexicanos,
                                        independientemente de las disposiciones relacionadas con conflictos de
                                        leyes. Cualquier procedimiento de carácter legal que se derive o se
                                        relacione con este Contrato será dirimido en los tribunales competentes de
                                        la zona metropolitana de Guadalajara, Jalisco,
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "left"
                                        }}
                                    >
                                        renunciando expresamente al fuero que pudiese corresponderle por razón de su
                                        domicilio presente o futuro.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "left"
                                        }}
                                    >
                                        Si alguna previsión de estos Términos y Condiciones Generales es declarada
                                        nula, inválida o ineficaz, ello no afectará la validez de las previsiones
                                        restantes.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Modificaciones
                                    </h1>
                                    <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <h1
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            podrá modificar los Términos y Condiciones Generales, o cualquier término
                                            adicional que se aplique a algún Servicio específico.{" "}
                                        </span>
                                        Yo Rento{" "}
                                        <span className="p">
                                            pondrá a disposición, cuando ello fuere posible, un aviso de las
                                            modificaciones de los términos en la Plataforma. Los cambios a los
                                            Términos y Condiciones Generales entrarán en vigor a los 10 días naturales
                                            de su publicación en la Plataforma.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Al momento de registrarse en la Plataforma el Usuario manifiesta haber
                                        leído, comprendido y aceptado expresamente los presentes Términos y
                                        Condiciones, sin que medie algún vicio de la voluntad.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Limitación de responsabilidad
                                    </h1>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario acepta que el uso de la Plataforma se realiza bajo su propia,
                                        exclusiva y única responsabilidad.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            no asume responsabilidad alguna que derive del intercambio de información
                                            electrónica entre sus Usuarios, ni derivado de los actos jurídicos que
                                            estos celebren o dejen de celebrar o cualquier hecho jurídico relacionado
                                            con el uso de la Plataforma.
                                        </span>
                                    </h1>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            no se responsabiliza por ningún daño o perjuicio, directo o indirecto,
                                            incluyendo sin ningún tipo de limitación daños producidos por pérdidas o
                                            deterioros de la información, ni por las demoras, interrupciones y
                                            defectos que pudieran existir en las transmisiones, mediante la
                                            utilización de la Plataforma, así como tampoco de los virus o fallas del
                                            sistema, siempre que ello no sea imputable a{" "}
                                        </span>
                                        Yo Rento<span className="p">. Ni </span>Yo Rento
                                        <span className="p">
                                            , ni sus accionistas, directores, funcionarios, empleados, afiliados o
                                            agentes garantizan que la Plataforma estará libre de interrupciones o
                                            errores; tampoco hace ninguna garantía en cuanto a los resultados que se
                                            pueden obtener del uso de la
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        misma o en cuanto a la exactitud, confiabilidad, adecuación o contenido de
                                        la información que aparece en la Plataforma.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            no será responsable frente al Usuario ni frente ningún tercero por los
                                            daños, infracciones, delitos, contravenciones o cualquier otra clase de
                                            ilícitos que pudieran cometerse, ya se trate de derechos de propiedad
                                            intelectual, al honor y reputación de las personas, sus datos personales,
                                            crediticios, derechos del consumidor, ni cualquier otro derecho que
                                            pudiera ser vulnerado como consecuencias del mal uso de la Plataforma por
                                            parte de los Usuarios.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "150%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Usuario se obliga en forma expresa e irrevocable a mantener absolutamente
                                        libre de responsabilidad a <b>Yo Rento</b>, así como a sus accionistas,
                                        directores, funcionarios, empleados, afiliados o agentes, respecto a
                                        cualquier reclamo y/o demanda y/o sanción, administrativa o judicial,
                                        iniciado por otros Usuarios, terceros o por cualquier Organismo relacionado
                                        con sus actividades en la Plataforma, el cumplimiento o y/o incumplimiento
                                        de estos Términos y Condiciones Generales, o en caso de verificarse la
                                        existencia de multas y/o deudas de cualquier tipo generadas por el Usuario o
                                        la actividad del mismo realizada en o mediante la Plataforma.
                                    </p>
                                </>

                            </motion.section>
                            {/* Puedes añadir más secciones aquí */}
                        </motion.div>
                    </div>
                </div>
            </div>

            <Footer />

        </>

    );
};

export default AvisoPrivacidad;
