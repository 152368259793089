import axios from './index';

export const getAlerts = (query, token = null) => {
    return axios.get(`/app/operation/alerts?${query}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const createAlert = (searchId, display_name, token = null) => {
    if (!searchId) {
        return Promise.reject('searchId is required');
    }

    return axios.post(`/app/operation/alerts/`, { label: display_name, searchId }, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const deleteAlert = (searchId, token = null) => {
    if (!searchId) {
        return Promise.reject('searchId is required');
    }

    return axios.delete(`/app/operation/alerts/${searchId}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};
