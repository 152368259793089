import { faSearch, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const LocationSearchInput = ({ setLocation, location, icons, className = "", placeholder = "" }) => {
  const [loaded, setLoaded] = useState(false);
  const [inputValue, setInputValue] = useState(location.formatted_address);

  useEffect(() => {
    if (window.google && window.google.maps.LatLng) {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    setInputValue(location.formatted_address);  // Actualiza el inputValue cuando cambia el formatted_address
  }, [location.formatted_address]);

  const handleChange = (data) => {
    setInputValue(data);
    setLocation((prev) => ({
      ...prev,
      formatted_address: data
    }));
  };

  const handleSelect = (data) => {
    geocodeByAddress(data)
      .then((results) => results[0])
      .then(async (resp) => {
        const coordinates = await getLatLng(resp);
        setLocation({
          lat: coordinates.lat,
          lng: coordinates.lng,
          formatted_address: data,
          select: true
        });
      })
      .catch((error) => console.error("Error", error));
  };

  if (!loaded) return null;

  const locationBias = {
    radius: 10000,
    center: new window.google.maps.LatLng(20.67379192685719, -103.33541315048933)
  };

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: { country: 'mx' },
        types: ['address'],
        locationBias: locationBias,
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative w-full">
          <div className="flex items-center bg-white border border-blue-500 rounded-full shadow-lg p-4">
            {!icons && <FontAwesomeIcon icon={faSearch} className="text-blue-500 ml-2 mr-4" />}
            <input
              {...getInputProps({
                placeholder: placeholder || "Introduce la calle, colonia o código postal",
                className: "flex-grow bg-transparent outline-none placeholder-blue-500 text-blue-500 pl-2",
              })}
              value={inputValue}  // Aquí sincronizamos el valor del input con la dirección
            />
            {inputValue && !icons && <FontAwesomeIcon icon={faXmarkCircle} className="text-blue-500 cursor-pointer ml-4 mr-2" onClick={() => {
              setInputValue("");
              setLocation({ lat: 20.67379192685719, lng: -103.33541315048933, formatted_address: "", select: false });
            }} />}
          </div>
          {(loading || suggestions.length > 0) && (
            <div className="absolute bg-white border border-gray-300 mt-2 rounded-lg shadow-xl w-full z-10 overflow-auto max-h-60">
              {loading && <div className="p-4 text-center text-gray-500">Cargando...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? "bg-gray-100 cursor-pointer p-4" : "cursor-pointer p-4";
                return (
                  <div {...getSuggestionItemProps(suggestion, { className })}>
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
