// ConfirmStepBackModal.jsx
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ConfirmStepBackModal = ({ show, onClose, onConfirm }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <button className="absolute top-3 right-3" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                </button>
                <h2 className="text-xl font-semibold mb-4">Confirmación</h2>
                <p className="mb-6">La información registrada se perderá si regresa. ¿Desea continuar?</p>
                <div className="flex justify-end space-x-4">
                    <button
                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg"
                        onClick={onConfirm}
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmStepBackModal;