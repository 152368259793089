import RootLayout from "../components/Layout";
import backgroundImage from "../assets/pexels-max-rahubovskiy-7019018 1.png"
import backgroundImage2 from "../assets/banner_large-1.png"
import searchingImage from "../assets/drawe-like-unauth.png"
import goodHouseImage from "../assets/Component-15.png"
import TextInput from "../components/form/textinput"
import SelectInput from "../components/form/selectinput";
import React, { useState, useEffect } from "react";
import Button from "../components/form/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import Radio from "../components/form/radio";
import ControlButton from "../components/form/controlbutton";
import TextIconInput from "../components/form/texticoninput";
import ArticleSection from "../components/properties/ArticleSection";
import { useStore } from "../store/filters.store";
import { queryProperties } from "../services/properties.service";

import { parseStringUriParams } from "../utils";
import ListProperties from "../components/properties-maps/list-properties-home";

import {
  faHome,
  faBuilding,
  faNewspaper,
  faUser,
  faBell,
  faMobileAlt,
  faSignOut, 
  faCommentAlt
} from '@fortawesome/pro-light-svg-icons';



const HomePage = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const [properties, setProperties] = useState([]);
  const [state] = useStore((state) => [state.filters]);
  const [setLocation, removeFilter] = useStore((state) => [state.update, state.remove]);

  const options = [
    { label: "Casas", value: "houses" },
    { label: "Departamentos", value: "departaments" },
  ];

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }

  useEffect(() => {
    handleLocationClick();
  }, []);

  const getPropertieWithFilter = async () => {
    console.log(state)
    if (state.lat && state.lng) {
      if (state.lat && state.lng) {
        setLocation({ name: "lng", value: state.lng });
        setLocation({ name: "lat", value: state.lat });
      }
    }

    const query = parseStringUriParams(state);
    const response = await queryProperties(query);
    if (response.data) {
      setProperties(response.data.properties);
    }
  };

  useEffect(() => {
    getPropertieWithFilter();
  }, [])

  return (
    <RootLayout admin>
      <div className="flex flex-col max-h-[318px]">
        {/* Mitad superior */}
        <div
          className="flex-1 bg-cover bg-center"
          style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
          <div className="flex items-center justify-center h-full">
            <form className="bg-white p-8 flex flex-col md:flex-row items-center justify-center rounded my-28 w-3/4 h-24">
              <div className="relative w-full md:w-1/2">
                <img
                  src={searchingImage}
                  alt="document"
                  className="absolute inset-x-0 -top-10"
                  style={{
                    height: 100,
                    width: "100%",
                    resizeMode: "contain",
                  }}
                />
              </div>
              <div className="w-full md:w-1/2 px-6 my-2 md:my-0">
                <label className="mt-3" htmlFor="grid-last-name">
                  Define tu búsqueda
                </label>
                <div className="relative">
                  <TextInput
                    type="text"
                    placeholder="Introduce la calle, colonia o código postal"
                    className="w-full md:w-[50vh] py-2 mr-4 border rounded border-[#35BCFC]"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-6 mt-2 md:my-0">
                <label className="mb-3" htmlFor="grid-last-name">
                  Tipo de inmueble
                </label>
                <div className="relative">
                  <SelectInput
                    options={options}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    className="w-full md:w-[40vh] py-2 mr-4"
                  />
                  {/* <SelectType className="" /> */}
                </div>
              </div>

              <div className="w-full md:w-2/3 px-6 my-2 md:my-6">
                <Button
                  type="submit"
                  className="w-full md:w-[24vh] mt-6 !bg-[#35BCFC] text-white rounded hover:bg-blue-700"
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" />
                  Buscar
                </Button>
              </div>
            </form>
          </div>
        </div>

        {/* Mitad inferior */}

      </div>
      <div className="flex flex-col max-h-[426px]">
        <div
          className="flex-1 bg-cover bg-center "
          style={{ backgroundImage: `url('${backgroundImage2}')` }}
        >
          {/* <div className="flex-1 bg-gradient-to-br from-blue-500 to-purple-800 bg-cover bg-center h-full"> */}
          <div className="flex items-center justify-center">
            <div className="p-8 flex flex-col md:flex-row items-center justify-center rounded w-3/4">
              <div className="w-full md:w-3/4 px-6 my-2 md:my-0">
                <h1 className="text-4xl text-white">
                  Buenos hogares para buenos
                  inquilinos. Yo rento es la opción que te ofrece
                  seguridad en tu búsqueda
                </h1>
                <p className="text-white text-1xl pt-6">
                  Busca tu casa o departamento y guárdalo en tus favoritos.
                  Contacta <br />
                  con nuestra red de arrendadores verificados.
                </p>
                <div className="pt-6">
                  {/* <Button
                    type="submit"
                    className="!w-[30vh] !h-[6vh] px-6 py-2 bg-[#3E11B5] text-white rounded hover:bg-blue-700"
                  >
                    Descarga la App de Yo rento
                  </Button> */}
                  <a className="inline-block leading-none text-[15px] px-[24px] py-[12px] rounded-[3px] bg-[#3E11B5] text-white hover:bg-blue-700" href="" >
                    <span className="flex justify-center">
                      <span className="grow order-10 inline-block">
                        Descarga la App de Yo rento
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-6 my-2 md:my-0">
                <img
                  className=" h-[250px]"
                  src={goodHouseImage}
                  alt="Descripción de la imagen"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Propiedades recomendadas */}
      <div className="flex flex-col max-h-[1600px]">
        <div className="flex justify-center">
          <h1 className="text-4xl mt-3">
            Recomendaciones de propiedades
          </h1>
        </div>
        <div className="flex justify-center w-4/5 mx-48 mb-3">
          <ListProperties properties={properties} />
        </div>

      </div>
      <div className="flex flex-col max-h-[820px]">
        <div
          className="flex-1 bg-cover bg-center"
          style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
          <div className="flex-shrink-0 p-6">
            <h1 className="text-3xl text-white text-center">
              Calcula el valor de renta de tu inmueble
            </h1>
            <p className="text-white text-1xl text-center pt-4">
              Con nuestra calculadora es muy fácil obtener el valor estimado del
              <br />
              inmueble de acuerdo con su ubicación y características físicas
            </p>
          </div>
          <div className="flex items-center justify-center">
            <div className="block max-w-md rounded-lg bg-white p-6 mb-10 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-white">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-2/2 px-3 mb-6 md:mb-0">
                    <label className="mb-2" htmlFor="grid-first-name">
                      Define tu búsqueda
                    </label>
                    <TextInput
                      className="block w-full bg-gray-200 text-gray-700 border border-[#35BCFC] rounded px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Introduce la dirección exacta"
                    />
                  </div>
                  <div className="w-full md:w-2/3 px-3">
                    <label className="mb-4" htmlFor="grid-last-name">
                      Tipo de inmueble
                    </label>
                    <SelectInput
                      options={options}
                      value={selectedOption}
                      onChange={setSelectedOption}
                      className="mr-4"
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3">
                    <label htmlFor="grid-last-name">¿Es nuevo?</label>

                    <div className="flex gap-x-4 items-center pt-2 ">
                      <div className="flex items-center gap-x-1 ">
                        <TextInput
                          id="is-new-1"
                          name="is-new"
                          type="radio"
                          className="w-4 h-4 text-[blue-600] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 accent-[#3E11B5]"
                        />
                        <label htmlFor="is-new-1">Si</label>
                      </div>

                      <div className="flex items-center gap-x-1 ">
                        <TextInput
                          id="is-new-2"
                          name="is-new"
                          type="radio"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 accent-[#3E11B5]"
                        />
                        <label htmlFor="is-new-2">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/3 px-3 mb-6  md:mb-0">
              
                    <label className="mb-2" htmlFor="grid-first-name">
                      Recámaras
                    </label>
                    <ControlButton className="!w-[24vh] mr-4" />
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label className="mb-2" htmlFor="grid-first-name">
                      Baños
                    </label>
                    <div className="relative">
                      <ControlButton className="!w-[24vh] mr-4" />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label className="mb-2" htmlFor="grid-first-name">
                      Estacionamiento
                    </label>
                    <ControlButton className="!w-[24vh] mr-4" />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/2 px-3 my-6  md:mb-0">
                    <label className="mb-2" htmlFor="grid-first-name">
                      Superficie construida
                    </label>
                    <TextIconInput icon={"m2"} />
                  </div>
                  <div className="w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label className="mb-2" htmlFor="grid-first-name">
                      Superficie de terreno
                    </label>
                    <div className="relative">
                      <TextIconInput icon={"m2"} />
                    </div>
                  </div>
                  <div className="w-full md:w-2/2 px-3 my-6 md:mb-0">
                    <label className="mb-2" htmlFor="grid-first-name">
                      ¿Cuenta con alguna de estas amenidades?
                    </label>
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 px-3 my-6 md:mb-0">
                        <div className="flex items-center gap-x-1">
                          <TextInput
                            id="is-new-1"
                            name="is-new"
                            type="checkbox"
                            className="!w-6 !h-6 bg-[#35BCFC] !border-[#35BCFC] text-[blue-600] rounded"
                          />
                          <label htmlFor="is-new-1">Gimnasio</label>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 my-6 md:mb-0">
                        <div className="flex items-center gap-x-1">
                          <TextInput
                            id="is-new-2"
                            name="is-new"
                            type="checkbox"
                            className="!w-6 !h-6 text-[blue-600] bg-[#12B3FF] border-[#12B3FF] rounded focus:ring-blue-500 "
                          />
                          <label htmlFor="is-new-2">Alberca</label>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 my-6 md:mb-0">
                        <div className="flex items-center gap-x-1">
                          <TextInput
                            id="is-new-3"
                            name="is-new"
                            type="checkbox"
                            className="!w-6 !h-6 text-[blue-600] bg-[#35BCFC] border-[#35BCFC] rounded focus:ring-blue-500 "
                          />
                          <label htmlFor="is-new-3">Seguridad privada</label>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 my-6 md:mb-0">
                        <div className="flex items-center gap-x-1">
                          <TextInput
                            id="is-new-4"
                            name="is-new"
                            type="checkbox"
                            className="!w-6 !h-6 text-[blue-600] bg-[#35BCFC] border-[#35BCFC] rounded focus:ring-blue-500"
                          />
                          <label htmlFor="is-new-4">Otro</label>
                        </div>
                      </div>
                      <div className="w-full md:w-2/2 px-3 mb-6 md:mb-0">
                        <Button
                          right
                          className="bg-[#3E11B5] !h-[3em] border mt-4 !text-[#FFF] text-[16px]"
                        >
                          Valuar este inmueble
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full">
      <div className="container p-4 mx-auto ">
        <h1 className="text-4xl text-center my-10">Artículos</h1>
        <div className="flex">
          <div className="w-2/2 flex items-center justify-center mx-48">
            <ArticleSection />
          </div>
        </div>
      </div>
      </div>
      
    </RootLayout>
  );
};

export default HomePage;
