import axios from "axios";
import { useEffect, useState } from "react";
import { faBriefcase, faCalendarAlt, faDog, faUsers, faChild, faShieldCheck, faInfo, faSuitcaseRolling } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfileModal = ({ show, close, userId }) => {
    const token = localStorage.getItem('token-yo-rento');
    const [info, setInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!show) return;

        const getProfileInfo = async () => {
            setIsLoading(true);
            try {
                const url = `/app/core/passport/${userId}`;
                const response = await axios.get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.status === 200) {
                    setInfo(response.data.passport);
                }
            } catch (error) {
                console.error('Error al obtener información del perfil', error);
            } finally {
                setIsLoading(false);
            }
        };

        getProfileInfo();
    }, [show, userId, token]);

    if (!show) return null;

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            onClick={close}
        >
            <div
                className="bg-white rounded-lg shadow-lg w-11/12 sm:w-96 p-4 relative"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={close}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    &times;
                </button>

                {isLoading ? (
                    <p className="text-center text-gray-500">Cargando...</p>
                ) : (
                    <div>
                        <div className="flex flex-col items-center">
                            <img
                                src={info.profile_picture || "https://via.placeholder.com/150"}
                                alt="Profile"
                                className="w-24 h-24 rounded-full object-cover mb-4"
                            />
                            <h2 className="text-lg font-semibold">{info.name || "Nombre no disponible"}</h2>
                        </div>

                        <div className="mt-4 flex items-center">
                            <FontAwesomeIcon icon={faDog} className="text-indigo-600 text-2xl mr-4 w-8" />
                            <div>
                                <h3 className="text-sm text-gray-400">Mascotas</h3>
                                <p className="text-gray-700">
                                    {info.has_pets === true ? "Sí" : info.has_pets === false ? "No" : "Desconocido"}
                                </p>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center">
                            <FontAwesomeIcon icon={faUsers} className="text-indigo-600 text-2xl mr-4 w-8" />
                            <div>
                                <h3 className="text-sm text-gray-400">Acompañantes</h3>
                                <p className="text-gray-700">
                                    {info.has_companions === true ? `Si, ${info.companions}` : info.has_companions === false ? "No" : "Sin información"}
                                </p>
                            </div>
                        </div>

                        {info.minors && (
                            <div className="mt-4 flex items-center">
                                <FontAwesomeIcon icon={faChild} className="text-indigo-600 text-2xl mr-4 w-8" />
                                <div>
                                    <h3 className="text-sm text-gray-400">Menores de edad</h3>
                                    <p className="text-gray-700">{info.minors || "Desconocido"}</p>
                                </div>
                            </div>
                        )}

                        <div className="mt-4 flex items-center">
                            <FontAwesomeIcon icon={faSuitcaseRolling} className="text-indigo-600 text-2xl mr-4 w-8" />
                            <div>
                                <h3 className="text-sm text-gray-400">Razón de Viaje</h3>
                                <p className="text-gray-700">{info.trip_reason || "Desconocido"}</p>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center">
                            <FontAwesomeIcon icon={faBriefcase} className="text-indigo-600 text-2xl mr-4 w-8" />
                            <div>
                                <h3 className="text-sm text-gray-400">Ocupación</h3>
                                <p className="text-gray-700">{info.job_situation || "Desconocido"}</p>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center">
                            <FontAwesomeIcon icon={faShieldCheck} className="text-indigo-600 text-2xl mr-4 w-8" />
                            <div>
                                <h3 className="text-sm text-gray-400">Identidad Verificada</h3>
                                <p className="text-gray-700">
                                    {info.identity_verified === true ? `Sí, válida hasta el ${info.investigation_validity}` : info.identity_verified === false ? "No" : "Sin información"}
                                </p>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-indigo-600 text-2xl mr-4 w-8" />
                            <div>
                                <h3 className="text-sm text-gray-400">Fecha de nacimiento</h3>
                                <p className="text-gray-700">{info.birth_date || "Desconocido"}</p>
                            </div>
                        </div>

                        <div className="mt-4 flex-col items-center">
                            <div className="flex">
                                <FontAwesomeIcon icon={faInfo} className="text-indigo-600 text-2xl mr-4 w-8" />
                                <h3 className="text-sm text-gray-400">Presentación</h3>
                            </div>
                            <p className="text-gray-700 mt-2">{info.presentation || "Desconocido"}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileModal;
