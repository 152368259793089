import { useState } from 'react';
import axios from 'axios';
import Spinner from '../../../components/Loader/Spinner';
import Conversation from '../../../assets/drawe-selfie.png';
import { faArrowLeft, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import ErrorModal from '../../../components/modals/modal-error';

const PhotoProfile = ({ onSuccess, step, setStep }) => {
    const [imageFile, setImageFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const token = localStorage.getItem('token-yo-rento');
    const [text, setText] = useState('');
    const title = 'Ha ocurrido un error inesperado';

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        if (imageFile) {
            try {
                setIsLoading(true);
                const formData = new FormData();
                formData.append('profilePicture', imageFile);

                const response = await axios.put('/app/core/users', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const userData = JSON.parse(localStorage.getItem('userData'));
                    if (userData && userData.user) {
                        userData.user.profile_picture = response.data.user.profile_picture;
                        localStorage.setItem('userData', JSON.stringify(userData));
                    }
                    onSuccess && onSuccess(true);
                    setStep(2)
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                setShowErrorModal(true);
                setText("Error al subir tu foto de perfil");

            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleBack = () => {
        setStep(0);
    }

    const cerrarErrorModal = () => {
        setShowErrorModal(false);
    }
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white p-8 w-full max-w-md mx-auto">
            <div className="text-center">
                <h2 className="text-2xl font-bold text-purple-700 mb-4">Sube tu foto de perfil</h2>
                <img src={Conversation} alt="Conversación" className="mx-auto h-3/5 w-3/5" />
                <p className="text-gray-600 mt-2">Para mantener nuestra comunidad verificada es fundamental que podamos comprobar tu identidad. Por favor tómate una selfie, tus datos biométricos estarán siempre resguardados.</p>
            </div>
            <input
                type="file"
                accept="image/*"
                className="mt-4 border border-gray-300 rounded-lg px-3 py-2 w-full"
                onChange={handleFileChange}
            />
            {previewUrl && (
                <div className="mt-4">
                    <h3 className="text-lg text-gray-700">Vista previa:</h3>
                    <img
                        src={previewUrl}
                        alt="Vista previa"
                        className="mt-2 rounded-lg shadow-lg max-w-full max-h-60 mx-auto"
                    />
                </div>
            )}
            <div className="mt-6 text-center">
                <motion.button
                    className={`${isLoading ? 'bg-white hover:bg-white' : 'bg-blue-600 hover:bg-blue-700'} text-white px-4 py-2 rounded-lg mr-4   transition ${!imageFile ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleSubmit}
                    disabled={!imageFile || isLoading}
                >
                    {isLoading ? <Spinner /> : 'Enviar'}
                </motion.button>
            </div>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full md:w-auto text-center flex justify-center items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full mt-5"
                onClick={handleBack}
            >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Regresar
            </motion.button>
            <ErrorModal show={showErrorModal} text={text} title={title} onClose={cerrarErrorModal} />
        </motion.div>

    );
};

export default PhotoProfile;
