import { faBell, faTrashAlt, faHouse, faBuilding, faBedFront, faShower, faUsdCircle, faPencil, faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmptyAlertsImage from '../../assets/drawe-like-unauth.png';
import { deleteAlert, getAlerts } from '../../services/alerts.service';
import Pagination from '../../utils/Pagination';
import moment from 'moment';
import axios from 'axios';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const MyAlerts = (props) => {
    const token = localStorage.getItem('token-yo-rento');
    const navigate = useNavigate();
    const [alerts, setAlerts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalAlerts, setTotalAlerts] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editingAlertId, setEditingAlertId] = useState(null);

    const labelRef = useRef({});

    const getAlertsList = async () => {
        try {
            setLoading(true);
            const resp = await getAlerts(`limit=4&skip=${(currentPage - 1) * 4}`, props.token);
            if (resp.data) {
                setTotalAlerts(resp.data.total);
                setAlerts(resp.data.alerts);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const onDeleteAlert = async (alertId) => {
        try {
            await deleteAlert(alertId, props.token);
            setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== alertId));
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditNameAlert = async (alertId) => {
        const newLabel = labelRef.current.value.trim();
        if (newLabel === "") {
            setErrorMessage('El nombre no puede estar vacío.');
            setTimeout(() => {
                setEditingAlertId(null);
            }, 2000);
            return;
        }
        setLoading(true);
        try {
            const data = {
                label: labelRef.current.value,
            };
            const response = await axios.put(`/app/operation/alerts/${alertId}/label`, data, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
                console.log("Nombre updated");
                setAlerts((prevAlerts) => prevAlerts.map(alert => alert.id === alertId ? { ...alert, display_name: labelRef.current.value } : alert));
                setEditingAlertId(null);
                labelRef.current.value = "";
                getAlertsList();
            }
        } catch (error) {
            console.error("Error al updatear el nombre de la alerta", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (alertId) => {

        console.log("aaaaaaaaaaaa", totalAlerts);
        if (editingAlertId === alertId) {
            setEditingAlertId(null);
            if (labelRef.current) {
                labelRef.current.value = "";
            }
        } else {
            setEditingAlertId(alertId);
            const alertToEdit = alerts.find(alert => alert.id === alertId);

            // Check if labelRef.current exists before setting its value
            if (labelRef.current && alertToEdit) {
                labelRef.current.value = alertToEdit.display_name || "";
            }
        }
    };


    useEffect(() => {
        if (props.token) getAlertsList();
    }, [props.token, currentPage]);

    const AlertItem = ({ alert, onDelete }) => {
        return (
            <motion.div
                className="w-full p-4 flex flex-col lg:flex-row justify-between items-center gap-4 my-4 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeUpVariants}
                transition={{ duration: 0.5 }}
            >
                <div className="w-full flex flex-col lg:flex-row gap-4 max-w-full lg:max-w-[85%]">
                    <div className="flex flex-col items-start justify-between w-full">
                        <div className='flex'>
                            <div className="bg-blue-500 rounded-lg p-1 mr-2">
                                <FontAwesomeIcon
                                    icon={editingAlertId === alert.id ? faTimes : faPencil}
                                    className='text-white cursor-pointer'
                                    onClick={() => handleEditClick(alert.id)}
                                />
                            </div>
                            <h3 className="text-lg font-semibold">{alert.display_name || alert.id}</h3>
                        </div>

                        {editingAlertId === alert.id ? (
                            <div className="flex items-center">
                                <input
                                    ref={labelRef}
                                    className="border p-1 rounded w-full"
                                    defaultValue={alert.display_name || ""}
                                />
                                <FontAwesomeIcon icon={faSave} className="ml-2 bg-green-500 text-white p-2 rounded cursor-pointer" onClick={() => handleEditNameAlert(alert.id)} />
                                {errorMessage && <p className="text-red-500 text-sm ml-1">{errorMessage}</p>}
                            </div>
                        ) : (
                            <>
                                <h4 className='text-gray-500'>{alert.search_box}</h4>
                                <span className="text-gray-500 text-sm block mb-1">{alert.filters}</span>
                            </>
                        )}

                        <div className="flex mt-2">
                            {(alert.houses === null || alert.houses === true) && (
                                <div className="flex flex-col mr-3">
                                    <FontAwesomeIcon icon={faHouse} className="text-purple-600" />
                                    <p className="text-purple-600 text-sm">Casas</p>
                                </div>
                            )}
                            {(alert.departments === null || alert.departments === true) && (
                                <div className="flex flex-col mr-3">
                                    <FontAwesomeIcon icon={faBuilding} className="text-purple-600" />
                                    <p className="text-purple-600 text-sm">Dptos</p>
                                </div>
                            )}
                            {alert.rooms && (
                                <div className="flex flex-col mr-3">
                                    <FontAwesomeIcon icon={faBedFront} className="text-purple-600" />
                                    <p className="text-purple-600 text-sm">Recamaras: {alert.rooms}</p>
                                </div>
                            )}
                            {alert.bathrooms && (
                                <div className="flex flex-col">
                                    <FontAwesomeIcon icon={faShower} className="text-purple-600" />
                                    <p className="text-purple-600 text-sm">Baños: {alert.bathrooms}</p>
                                </div>
                            )}
                        </div>
                        <div className="mt-3 flex">
                            {(alert.price_max || alert.price_min) && (
                                <>
                                    <FontAwesomeIcon icon={faUsdCircle} className="text-purple-600 ml-2 mr-3" />
                                    <p className='text-purple-600 text-sm mr-3'>Precio: </p>
                                </>
                            )}
                            {alert.price_max && (
                                <p className="text-purple-600 text-sm mr-3">
                                    Min ${Number(alert.price_min).toLocaleString()}
                                </p>
                            )}
                            {alert.price_max && (
                                <p className="text-purple-600 text-sm">
                                    Max ${Number(alert.price_max).toLocaleString()}
                                </p>
                            )}
                        </div>
                        <p className="text-purple-600 text-sm mt-2 ml-3">{moment(alert.alert_created_at).format("DD MMM YYYY, hh:mm A")}</p>
                        <div
                            className="flex items-center gap-2 mt-3 bg-red-100 py-1 px-2 rounded-xl cursor-pointer hover:bg-red-200 transition-colors duration-200"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(alert.id);
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className="text-red-600" />
                            <span className="text-red-600">Eliminar alerta</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center mt-4 lg:mt-0">
                    <FontAwesomeIcon icon={faBell} color="#FFC800" size="2x" />
                    <span className="text-xs mt-1 text-yellow-500">Activa</span>
                </div>
            </motion.div>
        );
    };

    return (
        <>
            <h1 className="text-[20px] text-purple-800 text-center font-semibold">Mis alertas</h1>
            <div className="w-full min-h-[40vh] rounded-[4px] mt-[10px]">
                <AnimatePresence mode="wait">
                    {alerts.length > 0 ? (
                        <motion.div
                            key={currentPage}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="">
                                {alerts.map((alert, index) => (
                                    <AlertItem
                                        alert={alert}
                                        key={index}
                                        onDelete={(id) => onDeleteAlert(id)}
                                    />
                                ))}
                            </div>
                            <div className="p-4 flex items-end justify-end">
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalAlerts}
                                    pageSize={4}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="empty"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                            className="w-full min-h-full my-[24px] flex flex-col justify-center items-center"
                        >
                            <img
                                src={EmptyAlertsImage}
                                alt="No alerts"
                                className="max-w-full h-auto mb-8"
                            />
                            <h3 className="font-semibold text-[#4C44ED] text-center py-8 text-lg">
                                Vaya... parece que aún no tienes alertas
                            </h3>
                            <p className="text-gray-500 text-center px-4 max-w-md">¡No te pierdas de las mejores oportunidades! <br /><br />
                                Explora las propiedades en tu área favorita y crea una alerta personalizada para recibir notificaciones de las opciones que mejor se adapten a tus necesidades.
                            </p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData: state.auth.userData,
});

export default connect(mapStateToProps)(MyAlerts);
