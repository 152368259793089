import {
  faBedFront,
  faBuilding,
  faCheckCircle,
  faGarageCar,
  faHome,
  faMapMarkerAlt,
  faRulerCombined,
  faShower,
  faTrophy
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../components/footer/Footer';
import NavBar from '../components/header/Navbar';
import { ICON_MAP } from '../constants';
import { getBest, getTypesAmenities } from '../services/properties.service';

const Calculadora = () => {
  const [direccion, setDireccion] = useState('');
  const [tipoInmueble, setTipoInmueble] = useState('Casa');
  const [esNuevo, setEsNuevo] = useState(false);
  const [recamaras, setRecamaras] = useState(1);
  const [banos, setBanos] = useState(1);
  const [estacionamientos, setEstacionamientos] = useState(1);
  const [superficieConstruida, setSuperficieConstruida] = useState('');
  const [superficieTerreno, setSuperficieTerreno] = useState('');
  const [errorDireccion, setErrorDireccion] = useState('');
  const [errorSuperficieTerreno, setErrorSuperficieTerreno] = useState('');
  const [errorSuperficieConstruida, setErrorSuperficieConstruida] = useState('');
  const [resultado, setResultado] = useState(null);
  const { token } = useSelector(state => state.auth);
  const [amenidades, setAmenidades] = useState([]);
  const [bestAmenidades, setBestAmenidades] = useState([]);
  const [amenidadesSeleccionadas, setAmenidadesSeleccionadas] = useState([]);
  const [bestAmenidadesSeleccionadas, setBestAmenidadesSeleccionadas] = useState([]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const [amenitiesResponse, bestAmenitiesResponse] = await Promise.all([
          getTypesAmenities(token),
          getBest(token)
        ]);
        setAmenidades(amenitiesResponse.data.amenities);
        setBestAmenidades(bestAmenitiesResponse.data.bestAmenities);
      } catch (error) {
        console.error('Error al cargar datos:', error);
      }
    };

    cargarDatos();
  }, [token]);

  const calcularValor = async () => {
    let hasError = false;

    if (!direccion) {
      setErrorDireccion('La dirección de la propiedad es obligatoria.');
      hasError = true;
    } else {
      setErrorDireccion('');
    }

    if (!superficieTerreno) {
      setErrorSuperficieTerreno('La superficie de terreno es obligatoria.');
      hasError = true;
    } else if (isNaN(superficieTerreno) || superficieTerreno <= 0) {
      setErrorSuperficieTerreno('La superficie de terreno debe ser un número positivo.');
      hasError = true;
    } else {
      setErrorSuperficieTerreno('');
    }
    if (!superficieConstruida) {
      setErrorSuperficieConstruida('La superficie construida es obligatoria.');
      hasError = true;
    } else if (isNaN(superficieConstruida) || superficieConstruida <= 0) {
      setErrorSuperficieConstruida('La superficie construida debe ser un número positivo.');
      hasError = true;
    } else {
      setErrorSuperficieConstruida('');
    }

    if (!hasError) {

      const amenidadesIds = Object.entries(amenidades)
        .filter(([_, value]) => value)
        .map(([key, _]) => ({ id: key === 'gimnasio' ? 1 : key === 'alberca' ? 2 : key === 'seguridad' ? 3 : 4 }));

      const response = await axios.post('/app/operation/property-calc', {
        address: direccion,
        property_type_id: tipoInmueble === 'Casa' ? 1 : 2,
        rooms: recamaras,
        bathrooms: banos,
        parking_lot: estacionamientos,
        surface_built: superficieConstruida,
        surface: superficieTerreno,
        amenities: amenidadesSeleccionadas,
        bestAmenities: bestAmenidadesSeleccionadas,
        email: 'frankgonzalez@yorento.com'
      });

      const data = response.data;
      console.log(data);


      setResultado({
        direccion,
        tipoInmueble,
        priceMin: data.priceMin,
        priceMax: data.priceMax,
        recamaras,
        banos,
        estacionamientos,
        superficieConstruida,
        superficieTerreno,
        amenidadesSeleccionadas,
        bestAmenidadesSeleccionadas
      });
    }
  };

  const toggleAmenidad = (amenidad) => {
    setAmenidadesSeleccionadas({
      ...amenidadesSeleccionadas,
      [amenidad]: !amenidadesSeleccionadas[amenidad],
    });
  };

  const toggleBestAmenidad = (amenidad) => {
    setBestAmenidadesSeleccionadas({
      ...bestAmenidadesSeleccionadas,
      [amenidad]: !bestAmenidadesSeleccionadas[amenidad],
    });
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const fadeInUpWithDelay = {
    hidden: { opacity: 0, y: 10 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.1, duration: 0.5 },
    }),
  };

  return (
    <>
      <NavBar />
      <div className="bg-gray-100 min-h-screen flex flex-col items-center py-8">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between p-4">
          <div className="lg:w-3/5 w-full mb-8 lg:mb-0">
            <motion.h1
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold mb-4"
            >
              Calcula el valor de renta de tu inmueble
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="mb-8"
            >
              Con nuestra calculadora es muy fácil obtener el valor estimado del inmueble de acuerdo con su ubicación y características físicas.
            </motion.p>
            {!resultado ? (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                className="bg-white p-6 rounded-2xl shadow-lg"
              >
                <div className="mb-4">
                  <label htmlFor="direccion" className="block text-gray-700 text-lg flex items-center">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> Dirección de la Propiedad
                  </label>
                  <motion.input
                    id="direccion"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    type="text"
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    className="mt-1 p-3 border rounded-full w-full focus:border-azul focus:ring focus:ring-blue-200 transition duration-200"
                  />
                  {errorDireccion && (
                    <div className="text-red-500 mt-2">
                      {errorDireccion}
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                  <div>
                    <label htmlFor='tipoInmueble' className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={tipoInmueble === 'Casa' ? faHome : faBuilding} className="mr-2" /> Tipo de Inmueble
                    </label>
                    <motion.select
                      id='tipoInmueble'
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUp}
                      value={tipoInmueble}
                      onChange={(e) => setTipoInmueble(e.target.value)}
                      className="mt-1 p-3 border rounded-full w-full focus:border-azul focus:ring focus:ring-blue-200 transition duration-200"
                    >
                      <option value="Casa">Casa</option>
                      <option value="Departamento">Departamento</option>
                    </motion.select>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> ¿Es Nuevo?
                    </label>
                    <div className="mt-1 flex items-center gap-4">
                      <motion.label
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        className="flex items-center cursor-pointer"
                      >
                        <input
                          type="radio"
                          value="Sí"
                          checked={esNuevo === true}
                          onChange={() => setEsNuevo(true)}
                          className="mr-2"
                        />
                        Sí
                      </motion.label>
                      <motion.label
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        className="flex items-center cursor-pointer"
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={esNuevo === false}
                          onChange={() => setEsNuevo(false)}
                          className="mr-2"
                        />
                        No
                      </motion.label>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
                  <div>
                    <label className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={faBedFront} className="mr-2" /> Recámaras
                    </label>
                    <div className="flex items-center gap-2 mt-1">
                      {[1, 2, '3+'].map((habitacion, index) => (
                        <motion.button
                          key={habitacion}
                          initial="hidden"
                          animate="visible"
                          variants={fadeInUpWithDelay}
                          custom={index}
                          type="button"
                          className={`w-10 h-10 flex justify-center items-center rounded-full border-2 ${recamaras === habitacion ? 'border-green-500 bg-white' : 'border-gray-300 bg-gray-200'} transition-transform duration-200`}
                          onClick={() => setRecamaras(habitacion)}
                        >
                          <span className={`text-lg font-bold ${recamaras === habitacion ? 'text-green-500' : 'text-gray-700'}`}>{habitacion}</span>
                        </motion.button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={faShower} className="mr-2" /> Baños
                    </label>
                    <div className="flex items-center gap-2 mt-1">
                      {[1, 2, '3+'].map((bano, index) => (
                        <motion.button
                          key={bano}
                          initial="hidden"
                          animate="visible"
                          variants={fadeInUpWithDelay}
                          custom={index}
                          type="button"
                          className={`w-10 h-10 flex justify-center items-center rounded-full border-2 ${banos === bano ? 'border-green-500 bg-white' : 'border-gray-300 bg-gray-200'} transition-transform duration-200`}
                          onClick={() => setBanos(bano)}
                        >
                          <span className={`text-lg font-bold ${banos === bano ? 'text-green-500' : 'text-gray-700'}`}>{bano}</span>
                        </motion.button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={faGarageCar} className="mr-2" /> Estacionamientos
                    </label>
                    <div className="flex items-center gap-2 mt-1">
                      {[1, 2, '3+'].map((est, index) => (
                        <motion.button
                          key={est}
                          initial="hidden"
                          animate="visible"
                          variants={fadeInUpWithDelay}
                          custom={index}
                          type="button"
                          className={`w-10 h-10 flex justify-center items-center rounded-full border-2 ${estacionamientos === est ? 'border-green-500 bg-white' : 'border-gray-300 bg-gray-200'} transition-transform duration-200`}
                          onClick={() => setEstacionamientos(est)}
                        >
                          <span className={`text-lg font-bold ${estacionamientos === est ? 'text-green-500' : 'text-gray-700'}`}>{est}</span>
                        </motion.button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                  <div>
                    <label htmlFor='superficieConstruida' className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={faRulerCombined} className="mr-2" /> Superficie Construida (m²)
                    </label>
                    <motion.input
                      id='superficieConstruida'
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUp}
                      type="number"
                      value={superficieConstruida}
                      onChange={(e) => setSuperficieConstruida(e.target.value)}
                      className="mt-1 p-3 border rounded-full w-full focus:border-azul focus:ring focus:ring-blue-200 transition duration-200"
                    />
                    {errorSuperficieConstruida && (
                      <div className="text-red-500 mt-2">
                        {errorSuperficieConstruida}
                      </div>
                    )}
                  </div>
                  <div>
                    <label htmlFor='superficieTerreno' className="block text-gray-700 text-lg flex items-center">
                      <FontAwesomeIcon icon={faRulerCombined} className="mr-2" /> Superficie de Terreno (m²)
                    </label>
                    <motion.input
                      id='superficieTerreno'
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUp}
                      type="number"
                      value={superficieTerreno}
                      onChange={(e) => setSuperficieTerreno(e.target.value)}
                      className="mt-1 p-3 border rounded-full w-full focus:border-azul focus:ring focus:ring-blue-200 transition duration-200"
                    />
                    {errorSuperficieTerreno && (
                      <div className="text-red-500 mt-2">
                        {errorSuperficieTerreno}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-lg flex items-center mb-3">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> Amenidades
                  </label>
                  <div className="mt-1 flex flex-wrap gap-2">
                    {amenidades.map((amenidad, index) => (
                      <motion.button
                        key={amenidad.name}
                        initial="hidden"
                        animate="visible"
                        custom={index}
                        type="button"
                        onClick={() => toggleAmenidad(amenidad.id)}
                        className={`flex items-center px-3 py-1 border rounded-full ${amenidadesSeleccionadas[amenidad.id] ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'} hover:bg-green-400 hover:text-white transition duration-200`}
                      >
                        <FontAwesomeIcon icon={ICON_MAP[amenidad.icon]} className="mr-2" />
                        {amenidad.name.charAt(0).toUpperCase() + amenidad.name.slice(1)}
                      </motion.button>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-lg flex items-center mb-3">
                    <FontAwesomeIcon icon={faTrophy} className="mr-2" /> Las mejores amenidades
                  </label>
                  <div className="mt-1 flex flex-wrap gap-2">
                    {bestAmenidades.map((amenidad, index) => (
                      <motion.button
                        key={amenidad.name}
                        initial="hidden"
                        animate="visible"
                        custom={index}
                        type="button"
                        onClick={() => toggleBestAmenidad(amenidad.id)}
                        className={`flex items-center px-3 py-1 border rounded-full ${bestAmenidadesSeleccionadas[amenidad.id] ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'} hover:bg-green-400 hover:text-white transition duration-200`}
                      >
                        <FontAwesomeIcon icon={ICON_MAP[amenidad.icon]} className="mr-2" />
                        {amenidad.name.charAt(0).toUpperCase() + amenidad.name.slice(1)}
                      </motion.button>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <motion.button
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    onClick={calcularValor}
                    className="w-full bg-purple-800 text-white py-3 rounded-full hover:bg-purple-600 transition duration-300"
                  >
                    Valuar
                  </motion.button>
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="mt-4 p-6 bg-white rounded-2xl shadow-lg"
              >
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
                  Resultado de la Valuación
                </h2>
                <div className="bg-gray-50 p-6 rounded-2xl space-y-4">
                  <p className="flex items-center text-lg">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-azul mr-2" />
                    <strong className="mr-1">Dirección:</strong> {resultado.direccion}
                  </p>
                  <div className="flex flex-wrap gap-2">
                    <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                      <FontAwesomeIcon icon={tipoInmueble === 'Casa' ? faHome : faBuilding} className="text-yellow-500 mr-2" />
                      <strong className="mr-2">Tipo de Inmueble:</strong>
                      <span className="bg-azul text-white px-2 py-1 rounded-full ml-4">{resultado.tipoInmueble}</span>
                    </div>
                    <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                      <FontAwesomeIcon icon={faBedFront} className="text-purple-500 mr-2" />
                      <strong className="mr-2">Recámaras:</strong>
                      <span className="bg-azul text-white px-2 py-1 rounded-full ml-4">{resultado.recamaras}</span>
                    </div>
                    <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                      <FontAwesomeIcon icon={faShower} className="text-azul mr-2" />
                      <strong className="mr-2">Baños:</strong>
                      <span className="bg-azul text-white px-2 py-1 rounded-full ml-4">{resultado.banos}</span>
                    </div>
                    <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                      <FontAwesomeIcon icon={faGarageCar} className="text-green-500 mr-2" />
                      <strong className="mr-2">Estacionamientos:</strong>
                      <span className="bg-azul text-white px-2 py-1 rounded-full ml-4">{resultado.estacionamientos}</span>
                    </div>
                    <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                      <FontAwesomeIcon icon={faRulerCombined} className="text-red-500 mr-2" />
                      <strong className="mr-2">Superficie Construida:</strong>
                      <span className="bg-azul text-white px-2 py-1 rounded-full ml-4">{resultado.superficieConstruida} m²</span>
                    </div>
                    <div className="flex items-center bg-gray-200 px-4 py-2 rounded-full">
                      <FontAwesomeIcon icon={faRulerCombined} className="text-orange-500 mr-2" />
                      <strong className="mr-2">Superficie de Terreno:</strong>
                      <span className="bg-azul text-white px-2 py-1 rounded-full ml-4">{resultado.superficieTerreno} m²</span>
                    </div>
                  </div>
                  <div className="bg-green-50 p-6 rounded-2xl text-center">
                    <p className="text-2xl font-bold">Valor Estimado:</p>
                    <p className="text-4xl font-extrabold text-green-700">${resultado.priceMin} - ${resultado.priceMax}</p>
                  </div>
                  <div className="mt-6">
                    <motion.button
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUp}
                      onClick={() => setResultado(null)}
                      className="w-full bg-red-500 text-white py-3 rounded-full hover:bg-red-600 transition duration-300"
                    >
                      Volver
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
          <div className="lg:w-2/5 w-full lg:pl-16 flex items-center justify-center">
            <motion.img
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
              priority // Indica que es una imagen importante
              layout="responsive"
              src={require(resultado ? '../assets/drawe-woman-approved.webp' : '../assets/drawe-woman-payment.webp')}
              alt="Woman"
              className="max-w-full h-auto"
              width={350}
              height={300}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Calculadora;
