import moment from "moment";
import { logout } from "../redux/actions/auth-action";
import { store } from "../redux/store";

export const validate_session = (jwttoken = null) => {
  if (jwtToken) {
    try {
      const [, payload] = jwtToken.split(".");
      const { exp: expires } = JSON.parse(window.atob(payload));
      if (typeof expires === "number") {
        if (
          moment(new Date(), "MMM DD, YYYY").isSameOrAfter(
            moment(new Date(expires * 1000), "MMM DD, YYYY")
          )
        ) {
          store.dispatch(logout());
        }
      }
    } catch {}
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const validatePassword = (password) => {
  const pattern = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{8,}$/
  );
  return password && pattern.test(password);
};

export const parseStringUriParams = (object = {}) => {
  return "?" + new URLSearchParams(object).toString();
};

export const formatCurrency = (value = 0) => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const getFormatAddress = (item) => {
  let city, postal_code, street_number, colonia, state;
  for (let i = 0; i < item.address_components.length; i++) {
    for (let j = 0; j < item.address_components[i].types.length; j++) {
      switch (item.address_components[i].types[j]) {
        case "locality":
          city = item.address_components[i].long_name;
          break;
        case "postal_code":
          postal_code = item.address_components[i].long_name;
          break;
        case "street_number":
          street_number = item.address_components[i].long_name;
          break;
        case "sublocality":
          colonia = item.address_components[i].long_name;
          break;
        case "administrative_area_level_1":
          state = item.address_components[i].long_name;
          break;
      }
    }
  }
  const {
    geometry: {
      location: { lat, lng },
    },
  } = item;

  return {
    lat,
    lng,
    street_number,
    postal_code,
    city,
    state,
    colonia,
    address_name: item?.address_components[1]?.long_name,
    formatted_address: item.formatted_address,
  };
};
