import { faChalkboardUser, faMagnifyingGlassLocation } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getMeUserInfo } from '../../services/auth.service';
import Spinner from '../../components/Loader/Spinner';
import NoMembership from '../../assets/drawe-men-good.png'

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const MyMembership = ({ token }) => {
    const [userInfo, setUserInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const number = '+525660168962';

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await getMeUserInfo(token);
            if (response.data?.user) {
                setUserInfo(response.data.user);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCardClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="w-full min-h-[40vh] mt-5 p-4 bg-white rounded-xl shadow-md">
            <h1 className="text-[24px] text-purple-800 text-center font-semibold">Mi membresía</h1>
            {isLoading ? (
                <div className="flex items-center justify-center p-4">
                    <Spinner />
                </div>
            ) : userInfo.has_membership ? (
                <div className="flex flex-col mt-5 space-y-4">
                    <motion.div
                        className="p-4 bg-purple-100 rounded-lg shadow cursor-pointer flex items-center"
                        onClick={() => handleCardClick(`https://wa.me/${number}?text=Hola quisiera comunicarme con atención al cliente`)}
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        whileHover={{ scale: 1.05 }}
                    >
                        <FontAwesomeIcon icon={faChalkboardUser} className="text-purple-800 mr-6" />
                        <div>
                            <h2 className="text-lg font-semibold text-purple-800">Coach Yo rento</h2>
                            <p className="text-sm text-purple-700">Pregunta cualquier cosa a nuestro coach</p>
                        </div>
                    </motion.div>
                    <motion.div
                        className="p-4 bg-purple-100 rounded-lg shadow cursor-pointer flex items-center"
                        onClick={() => handleCardClick('https://yorento.com/alertas-inteligentes.pdf')}
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        whileHover={{ scale: 1.05 }}
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlassLocation} className="text-purple-800 mr-6" />
                        <div>
                            <h2 className="text-lg font-semibold text-purple-800">¿Qué están buscando los inquilinos?</h2>
                            <p className="text-sm text-purple-700">Descubre las últimas busquedas.</p>
                        </div>
                    </motion.div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <img src={NoMembership} />
                    <p className="text-center mt-5 text-gray-600">No tienes una membresía activa.</p>
                </div>
            )}
        </div>
    );
};

export default MyMembership;
