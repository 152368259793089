import { faArrowRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import HomeDrawer from '../../../assets/drawe-add-propertie.png';
import Icon from '../../../assets/isotipo.png';
import { useNavigate } from 'react-router-dom';

const PublishInfo = ({ step, setStep }) => {

    const navigate = useNavigate();

    const handleClose = () => {
        navigate("/admin/dashboard");
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-8 rounded-lg"
        >
            <img src={Icon} alt="icon" className="w-[48px] mb-6" />
            <img src={HomeDrawer} alt="add propertie" className="w-24 md:w-[20rem] mb-6" />
            <h3 className="text-[#3E11B5] font-bold text-2xl mb-4">Publica una propiedad y réntala de inmediato</h3>
            <p className="text-gray-700 text-center mb-4">
                Sigue las indicaciones que se te darán a continuación.<br />
                Recuerda que con tu colaboración serás parte de la<br />
                comunidad más segura de arrendadores y inquilinos.
            </p>
            <div className="w-full flex flex-col-reverse md:flex-row text-center justify-between items-center mt-4 gap-y-4 md:space-y-0 md:space-x-4">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full md:w-auto text-center flex justify-center items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={handleClose}
                >
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Ahora no
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full md:w-auto text-center flex justify-center items-center text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step + 1)}
                >
                    Comenzar
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>

        </motion.div>
    );
};

export default PublishInfo;
