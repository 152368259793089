import React from "react";
import Icon from "../../assets/isotipo.png";

const Loader = () => {
    return (
        <div className="flex justify-center items-center h-full w-full">
            <div className="relative">
                <img src={Icon} alt="Logo" className="w-8 h-10 relative z-10" />
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="wave wave1"></div>
                    <div className="wave wave2"></div>
                    <div className="wave wave3"></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
