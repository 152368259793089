import { faBenchTree, faCity, faGasPump, faHospital, faMapMarkerAlt, faPlaceOfWorship, faSchool, faShoppingCart, faStore, faUtensils } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import marcadorPersonalizado from '../../assets/isotipo.png'
import useGoogleMaps from '../../hooks/useGoogleMaps'
import { mapStyles } from '../../utils/mapStyles'

const MapaDetallePropiedad = ({ property }) => {
  const mapRef = useRef()
  const dropdownRef = useRef()
  const [map, setMap] = useState(null)
  const [markers, setMarkers] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const googleMapIsLoaded = useGoogleMaps('AIzaSyCYIkiAIhbK1RzjF2d65xVc4TCMGCXK4jA')

  const filters = [
    { label: 'Escuelas', type: 'school', icon: faSchool },
    { label: 'Iglesias', type: 'church', icon: faPlaceOfWorship },
    { label: 'Restaurantes', type: 'restaurant', icon: faUtensils },
    { label: 'Tiendas', type: 'store', icon: faStore },
    { label: 'Hospitales', type: 'hospital', icon: faHospital },
    { label: 'Parques', type: 'park', icon: faBenchTree },
    { label: 'Supermercados', type: 'supermarket', icon: faShoppingCart },
    { label: 'Gasolineras', type: 'gas_station', icon: faGasPump }
  ]

  useEffect(() => {
    if ((!googleMapIsLoaded || !window.google || !window.google.maps.Map) || !property) return

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: property.location.lat, lng: property.location.lng },
      zoom: 15,
      styles: mapStyles
    })

    new window.google.maps.Marker({
      position: { lat: property.location.lat, lng: property.location.lng },
      label: '',
      icon: {
        url: marcadorPersonalizado,
        scaledSize: new window.google.maps.Size(40, 50)
      },
      map
    })

    setMap(map)
  }, [googleMapIsLoaded, property])

  useEffect(() => {
    if (!map || !window.google) return

    markers.forEach(marker => marker.setMap(null))
    setMarkers([])

    const service = new window.google.maps.places.PlacesService(map)
    selectedFilters.forEach(filterType => {
      const filter = filters.find(f => f.type === filterType)
      if (!filter) return

      const request = {
        location: new window.google.maps.LatLng(property.location.lat, property.location.lng),
        radius: '1000',
        type: filter.type
      }

      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const newMarkers = results.map(place => {
            const iconSvg = `
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 512 512">
                        <path d="M256,0C167.6,0,96,71.6,96,160c0,33.1,10.7,63.7,28.8,88.7L256,512l131.2-263.3C405.3,223.7,416,193.1,416,160
                        C416,71.6,344.4,0,256,0z" fill="#471fa5"/>
                        <path d="${filter.icon.icon[4]}" fill="#ffffff" transform="scale(0.35) translate(${filter.icon.iconName == 'place-of-worship' ? '400, 128' : '450, 255'})"/>
                    </svg>
                `
            const marker = new window.google.maps.Marker({
              position: place.geometry.location,
              map,
              title: place.name,
              icon: {
                url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`,
                scaledSize: new window.google.maps.Size(50, 50)
              }
            })
            return marker
          })
          setMarkers(prevMarkers => [...prevMarkers, ...newMarkers])
        }
      })

    })
  }, [selectedFilters, map])

  const handleFilterChange = (type) => {
    setSelectedFilters(prevFilters =>
      prevFilters.includes(type)
        ? prevFilters.filter(filter => filter !== type)
        : [...prevFilters, type]
    )
  }

  const handleDropdownToggle = () => {
    setIsDropdownOpen(prevState => !prevState)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className='flex items-center justify-between flex-wrap'>
        <h2 className='text-2xl font-bold text-purple-600'>Ubicación</h2>
        <div className='flex space-x-2 justify-around items-center flex-wrap space-y-2'>
          <div className='inline-flex items-center whitespace-nowrap rounded-full mt-2 border px-3 py-1.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-azul text-white shadow-sm md:shadow-lg'>
            <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
            {property?.location?.postal_code || 'No disponible'}
          </div>
          <div className='inline-flex items-center whitespace-nowrap rounded-full border px-3 py-1.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-purple-800 text-white shadow-lg'>
            <FontAwesomeIcon icon={faCity} className='mr-2' />
            {property?.location?.city || 'Zapopan'}
          </div>
          <div className='relative inline-block text-left' ref={dropdownRef}>
            <div>
              <button type='button' onClick={handleDropdownToggle} className='inline-flex justify-center w-full rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' id='menu-button' aria-expanded={isDropdownOpen} aria-haspopup='true'>
                Explora la zona
                <svg className='-mr-1 ml-2 h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                  <path fillRule='evenodd' d='M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                </svg>
              </button>
            </div>
            {isDropdownOpen && (
              <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50' role='menu' aria-orientation='vertical' aria-labelledby='menu-button'>
                <div className='py-1' role='none'>
                  {filters.map(filter => (
                    <label key={filter.type} className='flex items-center px-4 py-2 text-sm text-gray-700'>
                      <input
                        type='checkbox'
                        checked={selectedFilters.includes(filter.type)}
                        onChange={() => handleFilterChange(filter.type)}
                        className='mr-2'
                      />
                      <FontAwesomeIcon icon={filter.icon} className='mr-2' />
                      {filter.label}
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {property.show_address === true && (<p>{property.formatted_address}</p>)}
      <div className='relative w-full h-[24rem]'>
        <div ref={mapRef} className='w-full h-full' />
      </div>
    </>
  )
}

export default MapaDetallePropiedad