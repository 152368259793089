import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useRanger } from 'react-ranger';
import TextInput from '../../../components/form/textinput/index';
import { formatCurrency } from '../../../utils/index';

const DescriptionPropertie = ({ setStep, step, token, onSubmitAddPropertie, setDescriptionsPropertie, typePropertie, descriptionsPropertie, utilsProperties, contador }) => {
    const [valuesProperties, setValuesProperties] = useState({
        description: descriptionsPropertie.description || "",
        head: descriptionsPropertie.head || "",
        monthly_rent: descriptionsPropertie.monthly_rent || 0,
        daily_rent: descriptionsPropertie.daily_rent || 0,
        is_negotiable: descriptionsPropertie.is_negotiable || false,
        maintenance_included: descriptionsPropertie.maintenance_included || true,
        maintenance: descriptionsPropertie.maintenance || 0,
        years: Math.round(descriptionsPropertie.years / 365) || 0,
        extra_info: descriptionsPropertie.extra_info || "",
        share_commission: descriptionsPropertie.share_commission || false,
    });

    console.log(valuesProperties.years);


    useEffect(() => {
        console.log(valuesProperties);

        if (JSON.stringify(valuesProperties) !== JSON.stringify(descriptionsPropertie)) {
            setDescriptionsPropertie(valuesProperties);
        }
    }, [valuesProperties, descriptionsPropertie, setDescriptionsPropertie]);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        description: false,
        head: false,
        monthly_rent: false,
        daily_rent: false,
    });

    const handleYearsChange = (newValue) => {

        setValuesProperties((prevState) => ({
            ...prevState,
            years: newValue,
        }));
    };

    const handleInputChange = (field, value) => {
        setValuesProperties((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const validateFields = () => {
        const newErrors = {
            description: !valuesProperties.description,
            head: !valuesProperties.head,
            monthly_rent: utilsProperties.for_rent.includes(4) && (!valuesProperties.monthly_rent || valuesProperties.monthly_rent <= 0),
            daily_rent: (utilsProperties.for_rent.includes(1) || utilsProperties.for_rent.includes(2) || utilsProperties.for_rent.includes(3)) && (!valuesProperties.daily_rent || valuesProperties.daily_rent <= 0),
            maintenance: (!valuesProperties.maintenance_included && !valuesProperties.maintenance),
        };
        setErrors(newErrors);
        return !newErrors.description && !newErrors.head && !newErrors.monthly_rent && !newErrors.daily_rent && !newErrors.maintenance;
    };

    const confirmDescription = async () => {
        if (validateFields()) {
            setLoading(true);
            try {
                const isEditing = !!descriptionsPropertie.id; // Asumimos que si hay un ID, es una edición.
                await onSubmitAddPropertie({ isEditing }); // Pasamos `isEditing` como parámetro
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
    };

    // Renta mensual
    const handleMonthlyRentChange = (newValue) => {
        setValuesProperties((prevState) => ({
            ...prevState,
            monthly_rent: newValue[0],
        }));
    };

    // Renta diaria
    const handleDailyRentChange = (newValue) => {
        setValuesProperties((prevState) => ({
            ...prevState,
            daily_rent: newValue[0],
        }));
    };

    // Hook para la renta mensual
    const { getTrackProps: getMonthlyTrackProps, handles: monthlyHandles } = useRanger({
        min: 0,
        max: 100000,
        stepSize: 2000,
        values: [valuesProperties.monthly_rent],
        onDrag: handleMonthlyRentChange,
        onChange: handleMonthlyRentChange,
    });

    // Hook para la renta diaria
    const { getTrackProps: getDailyTrackProps, handles: dailyHandles } = useRanger({
        min: 0,
        max: 5000,
        stepSize: 200,
        values: [valuesProperties.daily_rent],
        onDrag: handleDailyRentChange,
        onChange: handleDailyRentChange,
    });

    console.log(valuesProperties.years);

    const { getTrackProps: getTrackProps2, handles: handles2 } = useRanger({
        min: 0,
        max: 40,
        stepSize: 1,
        values: [valuesProperties.years],
        onDrag: (newValue) => handleYearsChange(newValue[0]),
        onChange: (newValue) => handleYearsChange(newValue[0]),
    });

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-12 gap-6 bg-white p-4 md:p-8 rounded-lg w-full"
        >
            <div className="col-span-12 text-center">
                {contador > 0 && (
                    <div className="flex items-center justify-center mb-6">
                    </div>
                )}
                <h3 className="text-2xl font-semibold text-[#3E11B5] mb-2">Describe tu inmueble</h3>
                <span className="text-sm text-gray-500">Cuéntale a la comunidad los detalles más importantes de tu inmueble. Los títulos y descripciones pueden marcar la diferencia en la decisión de los interesados.</span>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label className="block text-sm font-medium text-gray-700">Encabezado</label>
                <div className="relative mt-1">
                    <textarea
                        value={valuesProperties.head}
                        onChange={(e) => handleInputChange('head', e.target.value)}
                        className={`border ${errors.head ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full p-3 focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent`}
                        rows={4}
                        placeholder={`Propiedad justo en el corazón de la ciudad`}
                    ></textarea>
                    {errors.head && <div className="text-red-500 text-xs mt-1">Este campo es obligatorio.</div>}
                </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label className="block text-sm font-medium text-gray-700">Descripción</label>
                <div className="relative mt-1">
                    <textarea
                        value={valuesProperties.description}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        className={`border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full p-3 focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent`}
                        rows={4}
                        placeholder={`Propiedad justo en el corazón de la ciudad`}
                    ></textarea>

                    {errors.description && <div className="text-red-500 text-xs mt-1">Este campo es obligatorio.</div>}
                </div>
            </div>

            {utilsProperties.for_rent.includes(1) || utilsProperties.for_rent.includes(2) || utilsProperties.for_rent.includes(3) ? (
                <div className="col-span-12 lg:col-span-6">
                    <label className="block text-sm font-medium text-gray-700">Determinar el precio máximo de la renta diaria</label>
                    <div className="my-4">
                        <div className="flex items-center gap-4">
                            <div className="w-full">
                                <div
                                    {...getDailyTrackProps({
                                        style: {
                                            height: "6px",
                                            background: "linear-gradient(to right, #4C44ED, #3E11B5)",
                                            borderRadius: "3px",
                                        },
                                    })}
                                >
                                    {dailyHandles.map(({ getHandleProps }) => (
                                        <button
                                            {...getHandleProps({
                                                style: {
                                                    width: "20px",
                                                    height: "20px",
                                                    outline: "none",
                                                    borderRadius: "50%",
                                                    background: "#fff",
                                                    border: "2px solid #3E11B5",
                                                },
                                            })}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg">{formatCurrency(valuesProperties.daily_rent)}</span>
                            </div>
                        </div>
                        <span className="text-xs text-gray-500 mt-2 inline-block">Yo Rento desplegará el precio diario. El arrendador negociará cualquier ajuste directamente con el Inquilino.</span>
                        {errors.daily_rent && <div className="text-red-500 text-xs mt-1">Este campo es obligatorio.</div>}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">O escribe la cantidad diaria exacta</label>
                        <div className="flex items-center gap-x-2 mt-2">
                            <span className="text-gray-700">$</span>
                            <TextInput
                                placeholder="Ejemplo: 250.75"
                                className="w-full !text-left text-[#000] !placeholder-gray-500 !border-gray-300 rounded-lg focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent"
                                value={valuesProperties.daily_rent || '0'}
                                onChange={(e) => handleInputChange('daily_rent', parseFloat(e.target.value) || 0)}
                            />
                        </div>
                    </div>
                </div>
            ) : null}

            {utilsProperties.for_rent.includes(4) ? (
                <div className="col-span-12 lg:col-span-6">
                    <label className="block text-sm font-medium text-gray-700">Determinar el precio máximo de la renta mensual</label>
                    <div className="my-4">
                        <div className="flex items-center gap-4">
                            <div className="w-full">
                                <div
                                    {...getMonthlyTrackProps({
                                        style: {
                                            height: "6px",
                                            background: "linear-gradient(to right, #4C44ED, #3E11B5)",
                                            borderRadius: "3px",
                                        },
                                    })}
                                >
                                    {monthlyHandles.map(({ getHandleProps }) => (
                                        <button
                                            {...getHandleProps({
                                                style: {
                                                    width: "20px",
                                                    height: "20px",
                                                    outline: "none",
                                                    borderRadius: "50%",
                                                    background: "#fff",
                                                    border: "2px solid #3E11B5",
                                                },
                                            })}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg">{formatCurrency(valuesProperties.monthly_rent)}</span>
                            </div>
                        </div>
                        <span className="text-xs text-gray-500 mt-2 inline-block">Yo Rento desplegará el precio mensual. El arrendador negociará cualquier ajuste directamente con el Inquilino.</span>
                        {errors.monthly_rent && <div className="text-red-500 text-xs mt-1">Este campo es obligatorio.</div>}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">O escribe la cantidad mensual exacta</label>
                        <div className="flex items-center gap-x-2 mt-2">
                            <span className="text-gray-700">$</span>
                            <TextInput
                                placeholder="Ejemplo: 7875.50"
                                className="w-full !text-left text-[#000] !placeholder-gray-500 !border-gray-300 rounded-lg focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent"
                                value={valuesProperties.monthly_rent || '0'}
                                onChange={(e) => handleInputChange('monthly_rent', parseFloat(e.target.value) || 0)}
                            />
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="col-span-12 lg:col-span-6">
                <div className="flex items-center justify-between mb-4">
                    <label className="text-sm font-medium text-gray-700">¿El precio es negociable?</label>
                    <div className="flex items-center gap-x-3">
                        <div className="flex items-center gap-x-1">
                            <input
                                id="is-negociable-1"
                                type="checkbox"
                                checked={valuesProperties.is_negotiable}
                                onChange={() => handleInputChange('is_negotiable', true)}
                                className="w-5 h-5 text-blue-600 accent-[#3E11B5] bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-[#3E11B5]"
                            />
                            <label htmlFor="is-negociable-1" className="text-gray-700">Sí</label>
                        </div>
                        <div className="flex items-center gap-x-1">
                            <input
                                id="is-negociable-2"
                                type="checkbox"
                                checked={!valuesProperties.is_negotiable}
                                onChange={() => handleInputChange('is_negotiable', false)}
                                className="w-5 h-5 text-blue-600 accent-[#3E11B5] bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-[#3E11B5]"
                            />
                            <label htmlFor="is-negociable-2" className="text-gray-700">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <div className="flex items-center justify-between mb-4">
                    <label className="text-sm font-medium text-gray-700">¿Comparte Comisión?</label>
                    <div className="flex items-center gap-x-3">
                        <div className="flex items-center gap-x-1">
                            <input
                                id="is-negociable-1"
                                type="checkbox"
                                checked={valuesProperties.share_commission}
                                onChange={() => handleInputChange('share_commission', true)}
                                className="w-5 h-5 text-blue-600 accent-[#3E11B5] bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-[#3E11B5]"
                            />
                            <label htmlFor="is-negociable-1" className="text-gray-700">Sí</label>
                        </div>
                        <div className="flex items-center gap-x-1">
                            <input
                                id="is-negociable-2"
                                type="checkbox"
                                checked={!valuesProperties.share_commission}
                                onChange={() => handleInputChange('share_commission', false)}
                                className="w-5 h-5 text-blue-600 accent-[#3E11B5] bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-[#3E11B5]"
                            />
                            <label htmlFor="is-negociable-2" className="text-gray-700">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <div className="flex items-center justify-between mb-4">
                    <label className="text-sm font-medium text-gray-700">¿Incluye mantenimiento?</label>
                    <div className="flex items-center gap-x-3">
                        <div className="flex items-center gap-x-1">
                            <input
                                id="is-maintenance-1"
                                type="checkbox"
                                checked={valuesProperties.maintenance_included}
                                onChange={() => handleInputChange('maintenance_included', true)}
                                className="w-5 h-5 text-blue-600 accent-[#3E11B5] bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-[#3E11B5]"
                            />
                            <label htmlFor="is-maintenance-1" className="text-gray-700">Sí</label>
                        </div>
                        <div className="flex items-center gap-x-1">
                            <input
                                id="is-maintenance-2"
                                type="checkbox"
                                checked={!valuesProperties.maintenance_included}
                                onChange={() => handleInputChange('maintenance_included', false)}
                                className="w-5 h-5 text-blue-600 accent-[#3E11B5] bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-[#3E11B5]"
                            />
                            <label htmlFor="is-maintenance-2" className="text-gray-700">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label className="block text-sm font-medium text-gray-700">Determinar el precio del mantenimiento en caso de no estar incluido</label>
                <div className="flex items-center gap-x-2 mt-2">
                    <span className="text-gray-700">$</span>
                    <TextInput
                        placeholder={valuesProperties.maintenance_included ? "Mantenimiento incluido" : "Ejemplo: 7875.50"}
                        className={`border ${errors.maintenance ? 'border-red-500' : 'border-gray-300'} w-full !text-left text-[#000] !placeholder-gray-500 rounded-lg focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent`}
                        value={valuesProperties.maintenance || '0'}
                        onChange={(e) => handleInputChange('maintenance', parseInt(e.target.value))}
                        disabled={valuesProperties.maintenance_included}
                    />
                    {errors.maintenance && <div className="text-red-500 text-xs mt-1">Este campo es obligatorio.</div>}
                </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <label className="block text-sm font-medium text-gray-700">Antigüedad (en años)</label>
                <div className="my-4">
                    <div
                        {...getTrackProps2({
                            style: {
                                height: "6px",
                                background: "linear-gradient(to right, #4C44ED, #3E11B5)",
                                borderRadius: "3px",
                            },
                        })}
                    >
                        {handles2.map(({ getHandleProps }) => (
                            <button
                                {...getHandleProps({
                                    style: {
                                        width: "20px",
                                        height: "20px",
                                        outline: "none",
                                        borderRadius: "50%",
                                        background: "#fff",
                                        border: "2px solid #3E11B5",
                                    },
                                })}
                            />
                        ))}
                    </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <span className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg">{valuesProperties.years}</span>
                </div>
                <div className="flex items-center gap-x-2 mt-4">
                    <span className="text-gray-700">Años:</span>
                    <TextInput
                        placeholder="Ejemplo: 10"
                        className="w-full !text-left text-[#000] !placeholder-gray-500 !border-gray-300 rounded-lg focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent"
                        value={valuesProperties.years}
                        onChange={(e) => handleYearsChange(parseInt(e.target.value) || 0)}
                    />
                </div>
            </div>

            {utilsProperties.for_rent.includes(1) || utilsProperties.for_rent.includes(2) || utilsProperties.for_rent.includes(3) ? (
                <div className="col-span-12 lg:col-span-6">
                    <label className="block text-sm font-medium text-gray-700">Información sobre costos extras</label>
                    <div className="relative mt-1">
                        <textarea
                            value={valuesProperties.extra_info}
                            onChange={(e) => handleInputChange('extra_info', e.target.value)}
                            className={`border ${errors.extra_info ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full p-3 focus:ring-2 focus:ring-[#3E11B5] focus:border-transparent`}
                            rows={4}
                            placeholder={`Hay costos extras cuando la renta es corta estancia`}
                        ></textarea>
                    </div>
                </div>
            ) : null}


            <div className="col-span-12 flex justify-between mt-8 space-x-4">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => confirmDescription()}
                    disabled={loading}
                    className={`flex items-center text-white px-6 py-3 rounded-full ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"} transition duration-300`}
                >
                    {loading ? "Subiendo propiedad..." : "Continuar"}
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>
        </motion.div>
    );
};

export default DescriptionPropertie;
