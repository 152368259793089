import { faExclamationCircle, faHouse, faPlus, faKey } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyProperties from "../../assets/empty-properties.svg";
import PropertyStatusModal from "../../components/modals/PropertyStatusModal";
import Propertie from "../../components/properties";
import { getMeUserInfo } from "../../services/auth.service";
import { getMeProperties } from "../../services/properties.service";
import Pagination from '../../utils/Pagination';
import Success from "../../components/modals/modal-success";
import axios from "axios";
import ModalSpinner from "../../components/modals/modal-spinner";
import Spinner from "../../components/Loader/Spinner";
import PropertyHistory from "../../components/properties/PropertyRentHistory";


const PublishedProperties = (props) => {
  const navigate = useNavigate()
  const [propertiesPublish, setPropertiesPublish] = useState([])
  const [rentedProperties, setRentedProperties] = useState([])
  const [userInfo, setUserInfo] = useState({})
  const [sortDate, setSortDate] = useState('none')
  const [sortOrderName, setSortOrderName] = useState('none')
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalProperties, setTotalProperties] = useState(0)
  const [totalRented, setTotalRented] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(null)

  const propertiesPerPage = 10
  const successText = "Propiedad eliminada con éxito"

  const getAllMyProperties = async () => {
    setIsLoading(true);
    try {
      const query = new URLSearchParams({
        limit: propertiesPerPage.toString(),
        skip: ((currentPage - 1) * propertiesPerPage).toString()
      })
      const resp = await getMeProperties(query, props.token)
      if (resp.data.properties) {
        setPropertiesPublish(resp.data.properties)
        setTotalProperties(resp.data.total)
      }
    }
    catch (error) {
      console.error('Error al cargar propiedades', error);
    }
    finally {
      setIsLoading(false);
    }

  }

  const getMyInfo = async () => {
    const resp = await getMeUserInfo(props.token)
    if (resp.data) {
      setUserInfo(resp.data.user)
      setRentedProperties(resp.data.user.rental_history || [])
      setTotalRented(resp.data.user.rental_history?.length || 0)
    }
  }

  useEffect(() => {
    getMyInfo()
    getAllMyProperties()
  }, [currentPage])

  const getNameSort = () => {
    return [...propertiesPublish].sort((a, b) => {
      const propertyA = a.property || a;
      const propertyB = b.property || b;

      const titleA = propertyA.title ? propertyA.title : '';
      const titleB = propertyB.title ? propertyB.title : '';

      if (sortOrderName !== 'none') {
        if (sortOrderName === 'asc') {
          return titleA.localeCompare(titleB);
        } else {
          return titleB.localeCompare(titleA);
        }
      } else if (sortDate !== 'none') {
        const dateA = new Date(propertyA.published_at);
        const dateB = new Date(propertyB.published_at);

        if (sortDate === 'newer') {
          return dateB - dateA;
        } else {
          return dateA - dateB;
        }
      }

      return 0;
    });
  };


  const handleDateChange = (e) => {
    setSortDate(e.target.value)
    setSortOrderName('none')
  }
  const handleOrderChange = (e) => {
    setSortDate('none')
    setSortOrderName(e.target.value)
  }

  const propertiesSorted = getNameSort()

  const handleDelete = async (id) => {
    setIsLoading(true)
    try {
      const response = await axios.delete(`/app/operation/properties/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`
          }
        })
      if (response.status === 200) {
        const newProperties = propertiesSorted.filter(publi => publi.id !== id)
        setPropertiesPublish(newProperties)
        setShowSuccessModal(true)
        setTimeout(() => {
          setShowSuccessModal(false)
        }, 2000)
      }
    } catch (error) {
      console.error('Failed to Delete: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (

    <div className="container mx-auto p-0 md:p-4">
      <Success show={showSuccessModal} text={successText} />
      <div
        className="flex flex-col-reverse md:flex-row items-end md:items-center justify-between mb-0 md:mb-4 p-6 md-p-0">
        <div className="flex items-">
          <h2 className="text-2xl font-semibold text-gray-800">
            <FontAwesomeIcon icon={faHouse} className="mr-2 text-azul" />
            Propiedades añadidas
          </h2>
          <span className="ml-2 bg-azul text-white text-sm px-2 py-1 rounded-full">
            {totalProperties}
          </span>
        </div>

        <div className="flex items-center mb-4 md:mb-0">
          <div>
            <select
              value={sortOrderName}
              onChange={handleOrderChange}
              className="bg-azul text-white text-sm px-4 py-2 rounded-full"
            >
              <option value="none">Ordenar por nombre</option>
              <option value="asc">Ascendente</option>
              <option value="des">Descendente</option>
            </select>
          </div>
        </div>

        <div className="flex items-center mb-4 md:mb-0">
          <div>
            <select
              value={sortDate}
              onChange={handleDateChange}
              className="bg-azul text-white text-sm px-4 py-2 rounded-full"
            >
              <option value="none">Ordenar por Fecha</option>
              <option value="newer">Mas reciente</option>
              <option value="older">Mas antigua</option>
            </select>
          </div>
        </div>

        <div className="flex items-center mb-4 md:mb-0">
          <button onClick={() => navigate('/admin/publish-propertie')}
            className="bg-azul text-white text-sm px-4 py-2 rounded-full">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Añadir una nueva
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <Spinner />
        </div>
      ) :
        (
          <div className="rounded-lg p-4">
            {propertiesSorted.length > 0 ? (
              <>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {propertiesSorted.map((item, index) => (
                    <Propertie
                      item={item}
                      index={item.id}
                      key={item.id}
                      token={props.token}
                      inmob={userInfo && userInfo.roles && userInfo.roles?.find((r) => r === 'estate_agency')}
                      setSelectedProperty={setSelectedProperty}
                      setModalOpen={setModalOpen}
                      onDelete={handleDelete}
                    />
                  ))}
                </div>

                <Pagination
                  currentPage={currentPage}
                  totalCount={totalProperties + totalRented} // Ajuste en el total para cubrir ambas listas
                  pageSize={propertiesPerPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </>
            ) : (
              <div className="flex flex-col items-center my-8">
                <img src={EmptyProperties} alt="empty properties" className="mb-4" />
                <h3 className="font-semibold text-gray-500 text-center">Aún no tienes propiedades añadidas</h3>
                {userInfo && userInfo.roles && userInfo.roles?.find((r) => r === 'estate_agency') && (
                  <div className="flex items-center mt-4 text-center px-4">
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-azul mr-2" />
                    <p className="text-gray-700">
                      Si eres un agente inmobiliario con un alto volumen de propiedades o requieres de uno o más servicios,
                      ponte en contacto con nuestros representantes.
                    </p>
                  </div>
                )}
              </div>

            )}
          </div>
        )}
      {(userInfo.roles?.includes('renter') && rentedProperties.length > 0) && (
        <div>
          <div
            className="flex flex-col-reverse md:flex-row items-end md:items-center justify-between mb-0 md:mb-4 p-6 md-p-0">
            <div className="flex items-center">
              <h2 className="text-2xl font-semibold text-gray-800">
                <FontAwesomeIcon icon={faKey} className="mr-2 text-azul" />
                Propiedades rentadas
              </h2>
              <span className="ml-2 bg-azul text-white text-sm px-2 py-1 rounded-full">
                {totalRented}
              </span>
            </div>
          </div>
          {rentedProperties.length > 0
            ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {rentedProperties.map((item, index) => (
                  <PropertyHistory
                    item={item}
                  />
                ))}
              </div>
            )
            : (
              <div className="flex flex-col items-center my-8">
                <img src={EmptyProperties} alt="empty properties" className="mb-4" />
                <h3 className="font-semibold text-gray-500 text-center">No has rentado ninguna propiedad</h3>
              </div>
            )}
        </div>
      )}

      {modalOpen && (<PropertyStatusModal isOpen={modalOpen} setIsOpen={setModalOpen} property={selectedProperty} />)}

    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  userData: state.auth.userData
})

export default connect(mapStateToProps)(PublishedProperties)
