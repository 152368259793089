import { faStar } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid, faStarHalfStroke } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import DraweEmptyComments from "../../assets/drawe-empty-comments.png";
import StarEmpty from "../../assets/star-empty.png";
import StarFull from "../../assets/star-full.png";
import UserLogo from "../../assets/user-logo.svg";
import { getScoreLessor, getScoreRenter } from "../../services/auth.service";
import moment from "moment";
import Loader from "../../components/Loader";

const MyEval = () => {
    const { user, token } = useOutletContext();
    const [ratings, setRatings] = useState({ lessor: 0, renter: 0 });
    const [comments, setComments] = useState({ lessor: [], renter: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [replyText, setReplyText] = useState({});
    const [showReply, setShowReply] = useState({});

    const replyRefs = useRef({});

    const getInfoComment = async () => {
        setIsLoading(true);
        try {
            const roles = {
                lessor: {
                    getScore: getScoreLessor,
                },
                renter: {
                    getScore: getScoreRenter,
                },
            };

            const newRatings = { lessor: 0, renter: 0 };
            const newComments = { lessor: [], renter: [] };

            for (const [role, services] of Object.entries(roles)) {
                if (user?.roles?.includes(role)) {
                    const respScore = await services.getScore(token);
                    if (respScore.status === 200) {
                        newRatings[role] = respScore.data?.stars || 0;
                        newComments[role] = respScore.data.comments || [];
                    }
                }
            }

            setRatings(newRatings);
            setComments(newComments);
        } catch (error) {
            console.error("Error al asignar roles", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) getInfoComment();
    }, [user]);

    const NoCommentsView = ({ role }) => (
        <div className="flex flex-col items-center mt-6">
            <img src={DraweEmptyComments} alt="No comments" className="max-h-48 mb-4" />
            <h3 className="text-indigo-700 font-semibold text-base mb-2">Aún no tienes comentarios como {role}</h3>
            <div className="text-gray-500">Aquí verás los comentarios que recibas.</div>
        </div>
    );

    const NoRatingView = ({ role }) => (
        <div className="flex items-center mt-6">
            <FontAwesomeIcon icon={faStar} className="text-indigo-500 text-4xl mr-4" />
            <div>
                <h3 className="text-indigo-700 font-semibold text-base mb-2">Aún no te han calificado como {role}</h3>
                <div className="text-gray-500">Aquí verás las calificaciones que recibas.</div>
            </div>
        </div>
    );

    const handleReplyToggle = (commentId) => {
        setShowReply((prevState) => ({
            ...prevState,
            [commentId]: !prevState[commentId],
        }));
    };

    const handleSendReply = (commentId) => {
        const replyValue = replyRefs.current[commentId]?.value || "";
        console.log('Respuesta enviada:', replyValue);

        setReplyText((prevState) => ({
            ...prevState,
            [commentId]: replyValue,
        }));

        setShowReply((prevState) => ({
            ...prevState,
            [commentId]: false,
        }));
    };

    const RoleSection = ({ role, displayName }) => (
        <div className="flex flex-col mb-12">
            <h4 className="text-2xl font-semibold text-gray-700 mb-4">Como {displayName}</h4>
            {ratings[role] > 0 ? (
                <div className="flex flex-col items-center mb-6">
                    <div className="flex mb-4">
                        {[...Array(5)].map((_, index) => {
                            if (index < Math.floor(ratings[role])) {
                                return <FontAwesomeIcon key={index} icon={faStarSolid} className="text-purple-600 h-5 mr-2" />;
                            } else if (index < ratings[role]) {
                                return <FontAwesomeIcon key={index} icon={faStarHalfStroke} className="text-purple-600 h-5 mr-2" />;
                            } else {
                                return <FontAwesomeIcon key={index} icon={faStar} className="text-purple-600 h-5 mr-2" />;
                            }
                        })}
                    </div>
                    <div className="text-gray-600">{ratings[role]} estrellas</div>
                </div>
            ) : (
                <NoRatingView role={displayName} />
            )}
            <div>
                {comments[role].length === 0 ? (
                    <NoCommentsView role={displayName} />
                ) : (
                    comments[role].map((comment, index) => (
                        <div className="bg-sky-100 p-4 rounded-md mb-1 w-full shadow-md flex flex-col mt-2" key={index}>
                            <div className="mt-3 flex items-start">
                                <img src={comment.profile_picture} alt="Profile" className="h-8 w-8 rounded-full mr-3" />
                                <div className="flex flex-col flex-grow">
                                    <p className="font-semibold text-indigo-600">{comment.name}</p>
                                </div>
                            </div>
                            <div className="flex items-center mb-1 mt-2">
                                {[...Array(5)].map((_, starIndex) => {
                                    if (starIndex < Math.floor(comment.stars)) {
                                        return <FontAwesomeIcon key={starIndex} icon={faStarSolid} className="text-purple-600 h-3 mr-2" />;
                                    } else if (starIndex < comment.stars) {
                                        return <FontAwesomeIcon key={starIndex} icon={faStarHalfStroke} className="text-purple-600 h-3 mr-2" />;
                                    } else {
                                        return <FontAwesomeIcon key={starIndex} icon={faStar} className="text-purple-600 h-3 mr-2" />;
                                    }
                                })}
                                <p className="text-gray-500 text-sm">{moment(comment.created_at).format("DD MMM YYYY, hh:mm A")}</p>
                            </div>
                            <div className="mb-2">
                                <p className="text-gray-700 font-medium">{comment.public_comment}</p>
                            </div>
                            {role === "lessor" && (
                                <div>
                                    {!comment.reply.comment ? (
                                        <div className="mb-1 flex justify-end">
                                            <button
                                                onClick={() => handleReplyToggle(index)}
                                                className="w-auto bg-gray-800 text-white font-semibold py-1 px-2 rounded-md shadow-md hover:bg-gray-700 transition duration-300"
                                            >
                                                {showReply[index] ? 'Cancelar' : 'Responder'}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="ml-3">
                                            <div className="mt-3 flex items-start">
                                                <img src={user.profile_picture} alt="Profile" className="h-8 w-8 rounded-full mr-3" />
                                                <div className="flex flex-col flex-grow">
                                                    <p className="font-semibold text-indigo-600">{user.name}</p>
                                                </div>
                                            </div>
                                            <h4>Respuesta </h4>
                                            <p>{comment.reply.comment}</p>
                                            <div className="flex justify-end">
                                                <p className="text-gray-500 text-sm">{moment(comment.created_at).format("DD MMM YYYY, hh:mm A")}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {showReply[index] && (
                                <div className="mt-2">
                                    <textarea
                                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 bg-gray-100"
                                        rows="3"
                                        placeholder="Escribe tu respuesta..."
                                        ref={(el) => (replyRefs.current[index] = el)}
                                    ></textarea>
                                    <div className="flex justify-end mt-2">
                                        <button
                                            onClick={() => handleSendReply(index)}
                                            className="w-auto bg-indigo-600 text-white font-semibold py-1 px-3 rounded-md shadow-md hover:bg-indigo-700 transition duration-300"
                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div >
    );

    const UserProfile = ({ user }) => (
        <div className="flex items-center mb-8">
            <img
                src={user?.profile_picture || UserLogo}
                alt="User Profile"
                className="w-20 h-20 rounded-full border-2 border-indigo-600 mr-4"
            />
            <div>
                <h3 className="text-2xl font-semibold text-gray-800">{user?.name || ""}</h3>
                <div className="text-gray-600">{user?.email}</div>
                <div className="text-gray-600">{user?.phone}</div>
            </div>
        </div>
    );

    return (
        <div className="rounded-2xl p-6">
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <Loader />
                </div>
            ) : (
                <div>
                    <UserProfile user={user} />
                    <div className={`grid gap-8 ${user?.roles?.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`}>
                        {user?.roles?.includes("renter") && (
                            <RoleSection role="renter" displayName="Inquilino" />
                        )}
                        {user?.roles?.includes("lessor") && (
                            <RoleSection role="lessor" displayName="Arrendador" />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyEval;
