import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRight, faLeft } from '@fortawesome/pro-solid-svg-icons';

const NeonText = ({ chatRequestButton }) => {
    return (
        <div className="relative flex items-center justify-center">
            <FontAwesomeIcon icon={faRight} className="absolute left-4 animate-bounce text-cyan-500 text-2xl" />
            <button
                className="relative text-center px-6 py-4 rounded-full bg-gradient-to-br from-cyan-300 via-cyan-400 to-cyan-600 shadow-lg"
                onClick={() => chatRequestButton()}
            >
                <h2 className="font-medium text-xl text-white">
                    ¡Contacta al anunciante!
                </h2>
            </button>
            <FontAwesomeIcon icon={faLeft} className="absolute right-4 animate-bounce text-cyan-500 text-2xl" />
        </div>
    );
};

export default NeonText;
