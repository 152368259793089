import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEnvelope,
  faHeart,
  faMessageLines,
  faShareNodes,
} from "@fortawesome/pro-light-svg-icons";
import ImagesSlider from "./images-slider";
import { Link } from "react-router-dom";
import Dropdown from "../dropdown";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { formatCurrency } from "../../../utils";

const Card = ({
  id,
  title,
  monthly_rent,
  surface,
  maintenance,
  rooms,
  bathrooms,
  parking_lot,
  rent_durations,
  amenities,
  images,
  views,
  status,
  property_type,
}) => {
  return (
    <div className="grid grid-cols-5 py-4">
      <div
        className="col-span-2 border border-l-[#A1A1A1] border-t-[#A1A1A1] border-b-[#A1A1A1] relative min-h-[230px]"
        style={{ height: "auto", width: "100%" }}
      >
        <ImagesSlider images={images} type={property_type} />
      </div>
      <div
        className="col-span-3 border border-r-[#A1A1A1] border-t-[#A1A1A1] border-b-[#A1A1A1] min-h-[230px] overflow-y-auto z-[3]"
        style={{ padding: "15px 21px" }}
      >
        <div className="flex flex-row justify-between mb-3">
          {status === "Rented" ? (
            <div className="border border-[#4F4BB0] bg-[#E8E7FF] p-2 rounded-md text-[#4F4BB0] text-[15px]">
              RENTADO
            </div>
          ) : (
            <div />
          )}
          <div className="flex flex-row items-center justify-between links-text-size gap-x-4 mb-[10px]">
            <div className="flex items-center text-[#777] gap-x-2">
              <Dropdown
                icon={faShareNodes}
                name="Compartir"
                dropdownItems={[
                  {
                    name: "Copiar enlace",
                    onclick: () => {
                      navigator.clipboard.writeText(`/public/properties/${id}`);
                    },
                    icon: <FontAwesomeIcon icon={faCopy} />,
                  },
                  {
                    name: "Enviar por correo",
                    onclick: () => {
                      window.open(
                        `mailto:email@example.com?subject=${title}&body=/public/properties/${id}`,
                        "_blank"
                      );
                    },
                    icon: <FontAwesomeIcon icon={faEnvelope} />,
                  },
                  {
                    name: "Mensaje de texto",
                    onclick: () => {
                      window.open(`tel:/public/properties/${id}`, "_blank");
                    },
                    icon: <FontAwesomeIcon icon={faMessageLines} />,
                  },
                  {
                    name: "Whatsapp",
                    onclick: () => {
                      window.open(
                        `https://web.whatsapp.com/send?text=/public/properties/${id}`,
                        "_blank"
                      );
                    },
                    icon: <FontAwesomeIcon icon={faWhatsapp} />,
                  },
                ]}
              />
            </div>
            <div className="flex items-center text-[#777] gap-x-2 cursor-pointer">
              <a
                href={`/public/propertie/like/${id}`}
                className="flex items-center gap-x-2"
              >
                <span>Likes</span>
                <FontAwesomeIcon icon={faHeart} />
              </a>
            </div>
            <div className="flex items-center text-[#777] gap-x-2">
              <span>Vistas</span>
              <p className="!mb-[0px]">{views || 0}</p>
            </div>
          </div>
        </div>
        <a href={`/public/properties/${id}`} target="_blank">
          <h2 className="title-card mb-[20px]">{title}</h2>
          <div className="grid grid-cols-8 gap-x-[3rem] mb-[12px]">
            <div className="col-span-3">
              <div className="flex justify-between mb-[8px]">
                <span className="subtitle-name">Renta mensual</span>
                <p className="subtitle-value !mb-[0px]">
                  {formatCurrency(Number(monthly_rent))}
                </p>
              </div>
              <div className="flex justify-between mb-[8px]">
                <span className="subtitle-name">Mantenimiento </span>
                <p className="subtitle-value !mb-[0px]">
                  {formatCurrency(Number(maintenance))}
                </p>
              </div>
              <div className="flex justify-between mb-[8px]">
                <span className="subtitle-name">Baños </span>
                <p className="subtitle-value !mb-[0px]">{bathrooms}</p>
              </div>
            </div>
            <div className="col-span-3">
              <div className="flex justify-between mb-[8px]">
                <span className="subtitle-name">Superficie</span>
                <p className="subtitle-value !mb-[0px]">{surface}m²</p>
              </div>
              <div className="flex justify-between mb-[8px]">
                <span className="subtitle-name">Recámaras </span>
                <p className="subtitle-value !mb-[0px]">{rooms}</p>
              </div>
              <div className="flex justify-between mb-[8px]">
                <span className="subtitle-name !mb-[0px]">
                  Estacionamiento{" "}
                </span>
                <p className="subtitle-value !mb-[0px]">
                  {(parking_lot || 0) > 1
                    ? `${parking_lot} autos`
                    : `${parking_lot} auto`}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-row flex-wrap gap-y-3 gap-x-4 my-4 ">
              <div>Amenidades:</div>
              {amenities.map((amenitie) => (
                <div className="rounded-[40px] border boder-[#777] text-[#777] px-[1.28rem]">
                  {amenitie.name}
                </div>
              ))}
            </div>
            <div className="flex flex-row flex-wrap gap-y-3 gap-x-4 my-4">
              <div>Se renta por:</div>
              {[...(rent_durations || [])].map((rents) => (
                <div className="rounded-[40px] border boder-[#777] text-[#777] px-[1.28rem]">
                  {rents.name}
                </div>
              ))}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Card;
