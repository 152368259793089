import axios from "./index";

// Obtener Score de un Arrendador (Renter Score)
export const getRenterScoreById = (renter_id, token = null) => {
    return axios.get(`/app/core/score/renter/${renter_id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Publicar Evaluación para un Arrendador (Post Renter Score)
export const postRenterScore = (data, token = null) => {
    return axios.post(`/app/core/score/renter`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Obtener Score de una Propiedad (Property Score)
export const getPropertyScoreById = (property_id, token = null) => {
    return axios.get(`/app/core/score/property/${property_id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Publicar Evaluación para una Propiedad (Post Property Score)
export const postPropertyScore = (data, token = null) => {
    return axios.post(`/app/core/score/property`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Obtener Todas las Evaluaciones y Scores Globales (Global Scores)
export const getAllScores = (token = null) => {
    return axios.get(`/app/core/scores`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Obtener Evaluaciones de un Usuario por su ID
export const getEvaluationsByUserId = (user_id, token = null) => {
    return axios.get(`/app/core/evaluations/user/${user_id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Publicar Evaluación de un Usuario (Post User Evaluation)
export const postUserEvaluation = (data, token = null) => {
    return axios.post(`/app/core/evaluations/user`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Obtener Evaluaciones de una Propiedad por su ID
export const getEvaluationsByPropertyId = (property_id, token = null) => {
    return axios.get(`/app/core/evaluations/property/${property_id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Publicar Evaluación de una Propiedad (Post Property Evaluation)
export const postPropertyEvaluation = (data, token = null) => {
    return axios.post(`/app/core/evaluations/property`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Obtener el Score Global de un Usuario
export const getGlobalScoreByUserId = (user_id, token = null) => {
    return axios.get(`/app/core/score/global/${user_id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Publicar Score Global para un Usuario
export const postGlobalScore = (data, token = null) => {
    return axios.post(`/app/core/score/global`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Obtener el Score Histórico de un Usuario
export const getHistoricalScoresByUserId = (user_id, token = null) => {
    return axios.get(`/app/core/score/historical/${user_id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Publicar Score Histórico para un Usuario
export const postHistoricalScore = (data, token = null) => {
    return axios.post(`/app/core/score/historical`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const postLessorScore = (data, token = null) => {
    return axios.post(`/app/core/score/lessor`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};