import moment from "moment";

const initState = {
  isLoggedIn: localStorage.getItem("token-yo-rento") ? true : false,
  isLoading: false,
  token: localStorage.getItem("token-yo-rento")
    ? localStorage.getItem("token-yo-rento")
    : null,
  userData: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {},
  error: false,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":

      console.log('action type: LOGIN_SUCCESS' , action)
      if(action.token) {
        localStorage.setItem("token-yo-rento", action.token);
      }
      if(action.userData) {
        localStorage.setItem("userData", JSON.stringify(action.userData));
      }

      localStorage.setItem("dateInit", moment().toISOString());

      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        userData: action.userData,
        token: action.token,
        error: action.error,
      };
      break;
    case "LOGIN_FAILED":
      console.log(action.error, 'error action td')
      return {

        ...state,
        error: action.error,
      };
      break;
    case "UPDATE_DATA":
      localStorage.setItem("userData", JSON.stringify(action.userData));
      return {
        ...state,
        userData: action.userData,
      };
      break;
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        token: null,
      };
      break;
    case "UPDATE_USER_DATA":
      localStorage.setItem("userData", JSON.stringify(action.userData));
      return {
        ...state,
        userData: action.userData,
      };
      break;
    default:
      return state;
  }
};

export default auth;
