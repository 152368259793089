import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

const WelcomeModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        localStorage.setItem('hasVisited', 'true');
    };

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited');

        if (!hasVisited) {
            setIsModalOpen(true);
        }
    }, []);

    return (
        <>
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50" onClick={closeModal}>
                    <div className="bg-white rounded-lg shadow-lg max-w-2xl mx-auto relative" onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault()
                    }}>
                        <div className="bg-purple-800 shadow-md rounded-t-lg flex justify-center items-center border-b-2 border-purple-800 relative text-white">
                            <h2 className="text-2xl font-bold p-4">¡Bienvenido a Yo rento!</h2>
                            <button
                                onClick={closeModal}
                                className="absolute top-0 right-0 p-4 focus:outline-none"
                            >
                                <FontAwesomeIcon icon={faTimes} className="text-white" />
                            </button>
                        </div>

                        <div className="p-8 text-center bg-gray-200 rounded-b-lg">
                            <p className="mb-6">
                                Ya seas arrendador o profesional inmobiliario, este es el momento ideal para mostrar tus inmuebles y conectar con inquilinos de confianza.
                            </p>
                            <p className="mb-6 font-bold text-purple-800">¿Cómo activar tu periodo sin costo?</p>
                            <ol className="list-decimal list-inside  mb-6 text-center">
                                <li>
                                    <span className="font-bold text-purple-800">Crea tu cuenta:</span> Regístrate en Yo Rento fácilmente
                                </li>
                                <li>
                                    <span className="font-bold text-purple-800">Carga tu propiedad:</span> Sube la información de tu inmueble
                                </li>
                                <li>
                                    <span className="font-bold text-purple-800">Publica:</span> Haz visible tu inmueble para miles de inquilinos
                                </li>
                            </ol>
                            <p className="font-bold text-purple-800">¡Publica ya y haz match con tu inquilino ideal!</p>
                            <button
                                onClick={closeModal}
                                className="mt-4 px-4 py-2 bg-purple-800 text-white rounded hover:bg-purple-500"
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WelcomeModal;
