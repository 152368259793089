import Card from "./card";
import Pagination from "../pagination";
import { useMemo, useState } from "react";

const ListProperties = ({ properties }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 3;
    const lastPageIndex = firstPageIndex + 3;
    return properties.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, properties]);

  return (
    <div>
      {currentTableData.map((row) => (
        <Card {...row} />
      ))}
      <Pagination
        currentPage={currentPage}
        totalCount={properties.length}
        pageSize={3}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ListProperties;
