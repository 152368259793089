import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DOTS, usePagination } from "./pagination/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange || currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex bg-white rounded-lg gap-x-3 mt-[10px]">
      <button
        onClick={onPrevious}
        disabled={currentPage === 1}
        className="h-12 border rounded-md text-[#A1A1A1] border-[#A1A1A1]
               px-4 hover:bg-[#4F4BB0] hover:text-white hover:border-[#4F4BB0]"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <button key={pageNumber} className="h-12 border rounded-md text-[#A1A1A1] border-[#777] hover:border-[#4F4BB0] hover:bg-[#4F4BB0] hover:text-white px-4">
              &#8230;
            </button>
          );
        }

        return (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={`h-12 border rounded-md text-[#A1A1A1] border-[#777] hover:border-[#4F4BB0] hover:bg-[#4F4BB0] hover:text-white
                        w-12 ${pageNumber === currentPage &&
              "bg-[#4F4BB0] !text-white"
              }`}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        onClick={onNext}
        disabled={currentPage === lastPage}
        className="h-12 border  border-[#777] hover:border-[#4F4BB0] hover:bg-[#4F4BB0] hover:text-white px-4 rounded-md "
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default Pagination;
