import { faArrowLeft, faBuilding, faUserTie } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMeDocuments } from '../../../services/auth.service';
import axios from 'axios';

const AgentType = ({ step, setStep, setTypeAgent, contador }) => {
    const { token } = useSelector((state) => state.auth);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    useEffect(() => {
        fetchDocuments();
    }, [token]);

    const fetchDocuments = async () => {
        setIsLoadingDocuments(true);
        const resp = await getMeDocuments(token);
        if (resp.data) {
            setDocuments(resp.data.documents);
        }
        setIsLoadingDocuments(false);
    };

    const handleTypeSelection = async (type) => {
        setSelectedType(type);
        setTypeAgent(type);

        if (type === 1) {
            try {
                await axios.put(
                    '/app/core/users',
                    { lessorType: 'independent' },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (error) {
                console.error('Error updating lessor type:', error);
            }
        }

        setTimeout(() => {
            setStep(step + 1);
        }, 300);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-12 min-h-[500px] flex items-center"
        >
            <div className="col-span-12 lg:col-start-3 lg:col-span-8 flex flex-col items-center justify-center">
                {contador > 0 && (
                    <div className="flex items-center justify-center mb-6">
                    </div>
                )}
                <h3 className="text-gray-800 text-center font-semibold text-2xl mb-8">Cuéntanos, ¿eres arrendador(a) independiente o agente inmobiliario?</h3>
                {isLoadingDocuments ? (
                    <div>Cargando...</div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleTypeSelection(1)}
                            className={`shadow-md ${selectedType === 1 ? 'shadow-blue-500/50' : 'shadow-gray-300'} p-6 rounded-lg text-center cursor-pointer transition-transform duration-300 hover:shadow-lg`}
                        >
                            <FontAwesomeIcon icon={faUserTie} className={`text-5xl mb-4 ${selectedType === 1 ? 'text-blue-500' : 'text-[#3E11B5]'}`} />
                            <p className={`font-semibold text-xl ${selectedType === 1 ? 'text-blue-500' : 'text-[#3E11B5]'}`}>Independiente</p>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleTypeSelection(2)}
                            className={`shadow-md ${selectedType === 2 ? 'shadow-blue-500/50' : 'shadow-gray-300'} p-6 rounded-lg text-center cursor-pointer transition-transform duration-300 hover:shadow-lg`}
                        >
                            <FontAwesomeIcon icon={faBuilding} className={`text-5xl mb-4 ${selectedType === 2 ? 'text-blue-500' : 'text-[#3E11B5]'}`} />
                            <p className={`font-semibold text-xl ${selectedType === 2 ? 'text-blue-500' : 'text-[#3E11B5]'}`}>Agente Inmobiliario</p>
                        </motion.div>
                    </div>
                )}
                <div className="w-full flex justify-start items-center mt-8">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                        onClick={() => setStep(step - 1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Regresar
                    </motion.button>
                </div>
            </div>
        </motion.div>
    );
};

export default AgentType;
