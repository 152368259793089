import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

const TextIconInput = ({icon}) => {
  return (
    <div className="flex">
      <span className="inline-flex items-center px-3 text-sm text-[#fff] border rounded-e-0  rounded-s-md bg-[#35BCFC] border-[#35BCFC]">
        {icon}
      </span>
      <input
        type="text"
        id="website-admin"
        className="rounded-none border-[#35BCFC] rounded-e text-center bg-gray-50 border p-1 w-full  focus:outline-none"
        placeholder=""
      />
    </div>
  );
};

export default TextIconInput;
