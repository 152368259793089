import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../Loader/Spinner';

const ReportSection = (props) => {
  const token = localStorage.getItem('token-yo-rento');
  const [isLoading, setIsLoading] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [buttonsData, setButtonsData] = useState([]);
  const [buttonIdClicked, setButtonIdClicked] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    const fetchTokenAndData = async () => {
      setIsLoading(true);
      try {
        const url = "/app/core/report-types?entity=property";
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setButtonsData(response.data.reportTypes);
        } else {
          console.error("Didn't get the data of buttons report types");
        }
      } catch (error) {
        console.log("Error al traer los datos", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTokenAndData();
  }, []);

  const handleButtonClick = (button) => {
    setButtonIdClicked(button.id);
    if (button.name.toLowerCase() === "otro") {
      setShowTextArea(true);
    } else {
      setShowTextArea(false);
    }
  };

  const handleSubmit = async () => {
    if (buttonIdClicked === null) {
      setWarningMessage("No hay botón seleccionado");
      setSuccessMessage('');
      return;
    }

    const { property } = props;
    const data = {
      reportTypeId: buttonIdClicked,
      description: textAreaValue,
      propertyId: property.id,
    };

    setIsLoading(true);

    try {
      const response = await axios.post(`/app/operation/properties/${property.id}/reports`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        setSuccessMessage("Reporte enviado");
        setButtonIdClicked(null);
        setTextAreaValue('');
        setWarningMessage('');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        setSuccessMessage('');
        console.error("Error al enviar el informe");
      }
    } catch (error) {
      console.error("Error al enviar el informe", error);
      setSuccessMessage('');
      setWarningMessage('Error al enviar el informe');
    } finally {
      setIsLoading(false);
    }

    setShowTextArea(false);
    setTextAreaValue('');
  };

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  return (
    <div className="bg-gray-100 border border-gray-300 rounded-lg p-4 md:w-full lg:w-1/2 xl:w-1/2 sm:w-full">
      <div className='flex flex-row'>
        <FontAwesomeIcon icon={faTriangleExclamation} className='w-6 h-4 ml-2 pr-2 pt-2' />
        <h2 className="font-bold text-lg mb-4">¿Tienes algún problema con este aviso?</h2>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center my-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
            {buttonsData.map(button => (
              <button
                key={button.id}
                className={`border border-gray-400 rounded-lg py-2 px-4 w-full ${buttonIdClicked === button.id ? 'bg-purple-400 text-white' : 'bg-white text-black'}`}
                onClick={() => handleButtonClick(button)}
              >
                {button.name}
              </button>
            ))}
          </div>

          {showTextArea && (
            <div className="mt-4">
              <textarea
                className="w-full border border-gray-400 rounded-lg p-2"
                rows="4"
                placeholder="Cuéntanos el motivo..."
                value={textAreaValue}
                onChange={handleTextAreaChange}
              ></textarea>
            </div>
          )}

          {warningMessage && (
            <p className="mt-4 text-red-500">{warningMessage}</p>
          )}

          <button
            className="mt-2 bg-purple-800 text-white border border-blue-600 rounded-lg py-2 px-4 w-full"
            onClick={handleSubmit}
          >
            Enviar
          </button>

          {successMessage && (
            <p className="mt-4 p-2 border border-green-600 bg-green-100 text-green-600 rounded-md">
              {successMessage}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default ReportSection;
