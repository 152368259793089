export const loginSuccess = (userData, token = null) => {
  return {
    type: "LOGIN_SUCCESS",
    userData,
    token,
  };
};

export const _updateData = (data) => {
  return {
    type: "UPDATE_DATA",
    userData: data,
  };
};

export const loginFailed = (error) => {
  return {
    type: "LOGIN_FAILED",
    error,
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export const updateUserData = (data) => ({
  type: 'UPDATE_USER_DATA',
  userData: data,
});