import { faArrowLeft, faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import DrawerHouse from '../../../assets/drawe-house.png';

const DescriptionInfo = ({ step, setStep, contador }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full"
        >
            {contador > 0 && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Describe tu inmueble</h3>
            <img src={DrawerHouse} alt="add propertie" className="w-[18rem] mb-6" />
            <span className="text-[14px] text-center mb-6">
                Cuéntale a la comunidad los detalles más importantes de tu inmueble. Los títulos y descripciones pueden marcar la diferencia en la decisión de los interesados.
            </span>
            <div className="w-full flex flex-col-reverse md:flex-row justify-between items-center px-4 md:px-16 gap-4">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full md:w-auto flex items-center justify-center text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full md:w-auto flex items-center justify-center text-white bg-blue-500 hover:bg-blue-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step + 1)}
                >
                    Añadir descripción
                    <FontAwesomeIcon icon={faPen} className="ml-2" />
                </motion.button>
            </div>

        </motion.div>
    );
};

export default DescriptionInfo;
