const initState = {
  houses: true,
  departaments: true,
  priceMin: 8000,
  priceMax: 60000,
  rooms: "",
  antiquityMin: 1,
  antiquityMax: 365 * 40,
  lat: 0, //deprecated
  lng: 0, //deprecated
  radius: 0, //deprecated
  minLng: 0,
  maxLng: 0,
  minLat: 0,
  maxLat: 0,
  publishedFrom: ""
};

const filters = (state = initState, action) => {
  switch (action.type) {
    case "MOD_FILTER":
      return {
        ...state,
        [action.name]: action.value,
      };
    case "MULTIPLE_FILTER":
      return {
        ...state,
        ...action.filters,
      };
    case "CLEAR_FILTERS":
      return {
        ...state,
        houses: true,
        departaments: true,
        priceMin: 8000,
        priceMax: 60000,
        rooms: "",
        antiquityMin: 1,
        antiquityMax: 365 * 40,
        format_address: "",
        publishedFrom: "",
        search: "",
      };
    default:
      return state;
  }
};

export default filters;
