import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import TarjetaDetallePropiedad from '../components/cards/TarjetaDetallePropiedad';
import NavBar from '../components/header/Navbar';
import CarouselDestacadas from '../components/map/carousel/CarouselDestacadas';
import { AuthContext } from '../provider/auth';
import { getPropertie } from "../services/properties.service";
import NotFoundProperty from './errors/NotFoundProperty';

const DetallePropiedad = ({ token }) => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [zona, setZona] = useState(null);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [propiedadesSimilares, setPropiedadesSimilares] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const obtenerPropiedad = async (id) => {
    if (id) {
      try {
        const resp = await getPropertie(id, token, true);
        setZona(resp.data.property.location);
        setProperty(resp.data.property);
        setPropiedadesSimilares(resp.data.suggestions);
      } catch (error) {
        if (error.status === 404) {
          setNotFound(true);
        }
        console.error('Error al obtener la propiedad:', error);

      }
    }
  };

  useEffect(() => {
    obtenerPropiedad(id);
  }, [id]);

  if (notFound === true) {
    return <NotFoundProperty />;
  }

  if (!property) {
    return null;
  }

  const formatData = (data, fallback = 'No disponible') => {
    return data ?? fallback;
  };

  return (
    <>
      <Helmet>
        <title>{`Yo rento | ${formatData(property.title)}`}</title>
        <meta property="og:title" content={formatData(property.title)} />
        <meta property="og:description" content={`${formatData(property.rooms)} Recámaras, ${formatData(property.bathrooms)} Baños, ${formatData(property.surface)} m²`} />
        <meta property="og:image" content={formatData(property.images[0]?.image)} />
        <meta property="og:url" content={`http://yorento.com/propiedad/${formatData(property.id)}/detalle`} />
        <meta property="og:type" content="article" />
      </Helmet>

      <NavBar />

      <motion.div
        className='container mx-auto my-5 p-4 md:p-6 space-y-8 bg-white rounded-lg shadow-2xl'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5 }}
      >
        <TarjetaDetallePropiedad property={property} />
        <CarouselDestacadas propiedadesDestacadas={propiedadesSimilares} />
      </motion.div>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(DetallePropiedad);
