import {
  faBell,
  faBlog,
  faCalculator,
  faCommentAlt,
  faHome,
  faQuestionCircle,
  faPlus,
  faSignOut,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import FacebookIcon from '../assets/facebook.png';
import GoogleSimpleIcon from '../assets/googleSimple.svg';
import InstagramIcon from '../assets/instagram.png';
import FacebookSquareIcon from '../assets/square-facebook.svg';
import LinkedInSquareIcon from '../assets/square-linkedin.svg';
import TwitterIcon from '../assets/twitter.png';

import {
  faBenchTree,
  faCameraMovie,
  faChampagneGlasses,
  faDogLeashed,
  faDumbbell,
  faElevator,
  faHandshake,
  faPartyHorn,
  faPersonRunning,
  faPersonSledding,
  faPuzzle,
  faTablePicnic,
  faUsersRectangle,
  faWashingMachine,
  faWaterLadder,
  faWifi
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faBaby,
  faBath,
  faBedFront,
  faBellConcierge,
  faBus,
  faCouch,
  faFootball,
  faGrill,
  faHotTubPerson,
  faLocationDot,
  faPaw,
  faTv,
  faUmbrellaBeach,
  faUserPolice,
  faUsers
} from '@fortawesome/pro-solid-svg-icons';


const userAgent = navigator.userAgent || navigator.vendor || window.opera;

export const menuTitles = [
  { name: 'Cargar una nueva propiedad', url: '/auth/register', icon: faPlus, type: "button" },
  { name: 'Inicio', url: '/' },
  // { name: "Calculadora", url: "/calculadora" },
  { name: "Nosotros", url: '/nosotros', icon: faUsers },
  // { name: "Revista", url: "https://revista.yorento.com", icon: faBlog },
  { name: 'Iniciar sesión', url: '/auth/login' },
  { name: "App Yo rento", url:  userAgent.includes('Android') ? "https://play.google.com/store/apps/details?id=com.yorento" : "https://apps.apple.com/us/app/yo-rento/id6467521308", type: "button" },
];

export const menuAdminTitles = [
  { name: 'Cargar una nueva propiedad', url: '/admin/publish-propertie', icon: faPlus, type: "button" },
  { name: 'Inicio', url: '/', icon: faHome },
  // { name: "Calculadora", url: "/calculadora", icon: faCalculator },
  // { name: "Revista", url: "https://revista.yorento.com", icon: faBlog },
  { name: "Nosotros", url: '/nosotros', icon: faUsers },
  { name: 'Mis Propiedades', url: '/admin/dashboard', icon: faUser },
  {
    name: 'Mi cuenta', dropdown:
      [
        { name: "Información Personal", url: "/admin/personal-panel/data-personal" },
        { name: "Mis Likes", url: "/admin/personal-panel/my-likes" },
        { name: "Documentos", url: "/admin/personal-panel/my-documents" }
      ], icon: faUser
  },
  { name: '', url: '/admin/notificaciones', icon: faBell },
  { name: '', url: '/admin/chats', icon: faCommentAlt },
  { name: '', url: '/admin/help', icon: faQuestionCircle },
  { name: "App Yo rento", url:  userAgent.includes('Android') ? "https://play.google.com/store/apps/details?id=com.yorento" : "https://apps.apple.com/us/app/yo-rento/id6467521308", type: "button" },
  { name: '', url: '/admin/logout', icon: faSignOut },
];

export const menuFooterTitle = [
  { name: 'Promotor', url: 'https://yorento.com/proximamente/' },
  { name: 'Agente inmobiliario', url: 'https://yorento.com/proximamente/' },
  { name: 'Preguntas frecuentes', url: 'https://yorento.com/faq' },
  { name: 'Visítanos', url: 'https://yorento.com/proximamente/' },
];

export const redesSociales = [
  { image: FacebookIcon, link: 'https://facebook.com/' },
  {
    image: InstagramIcon,
    link: 'https://instagram.com/yorento.com.mx?igshid=OGQ5ZDc2ODk2ZA==',
  },
  { image: TwitterIcon, link: 'https://twitter.com/MxYorento' },
];

export const itemsLeftNavar = [
  // { name: 'Información personal', id: '/admin/info' },
  // { name: 'Mis likes', id: '/admin/likes' },
  // { name: 'Chats', id: '/admin/chats' },
  // { name: 'Mis notificaciones', id: '/admin/notificaciones' },
  // { name: 'Documentos', id: '/admin/documents' },
  // { name: 'Propiedades publicadas', id: '/admin/dashboard' },
  // { name: 'Ayuda', id: '/admin/help' },
];

export const optionsSessions = [
  {
    title: 'Facebook',
    icon: <img src={FacebookSquareIcon} className="h-4" />,
  },
  {
    title: 'Google',
    icon: <img src={GoogleSimpleIcon} className="h-4" />,
  },
  {
    title: 'LinkedIn',
    icon: <img src={LinkedInSquareIcon} className="h-4" />,
  },
];

export const ROOMS = [
  { name: '1', id: 1 },
  { name: '2', id: 2 },
  { name: '3 o más', id: 3 },
  { name: 'Loft', id: 'loft' },
];

export const BATHROOMS = [
  { name: '1', id: 1 },
  { name: '1.5', id: 1.5 },
  { name: '2', id: 2 },
  { name: '2.5', id: 2.5 },
  { name: '3 o más', id: 4 },
];

export const PARKING = [
  { name: 'No tiene', id: 0 },
  { name: '1 auto', id: 1 },
  { name: '2 autos', id: 2 },
  { name: '3 o más', id: 3 },
];

export const AMENITIES = [
  'Áreas comunes',
  'Terraza',
  'Piscina',
  'Gimnasio',
  'Coworking',
  'Canchas deportivas',
  'Fire pit',
  'Elevadores',
  'Sala de cine',
];

export const BEST_PROPERTIE = [
  'Ubicación',
  'Transporte cercano',
  'Recepción',
  'Amoblado',
  'Vigilancia',
];

export const CHILDRENS = [
  { id: false, name: 'No se permiten', isAllowed: false, icon: faBaby },
  { id: true, name: 'Permitidos', isAllowed: true, icon: faBaby },
];

export const PETS = [
  { id: false, name: 'No se permiten', isAllowed: false, icon: faPaw },
  { id: true, name: 'Permitidas', isAllowed: true, icon: faPaw },
];

export const RENT_FOR = ['Días', 'Semanas', 'Meses', '12+ meses'];

export const API_KEY = process.env.REACT_APP_GOOGLE_KEY;

export const ICON_MAP = {
  // Amenidades regulares
  'fa-solid fa-grill-hot': faGrill,
  'fa-duotone fa-dumbbell': faDumbbell,
  'fa-duotone fa-handshake': faHandshake,
  'fa-duotone fa-court-sport': faFootball,
  'fa-duotone fa-camera-movie': faCameraMovie,
  'fa-duotone fa-elevator': faElevator,
  'fa-duotone fa-water-ladder': faWaterLadder,
  'fa-duotone fa-person-running-fast': faPersonRunning,
  'fa-duotone fa-party-horn': faPartyHorn,
  'fa-duotone fa-washing-machine': faWashingMachine,
  'fa-duotone fa-puzzle': faPuzzle,
  'fa-duotone fa-dog-leashed': faDogLeashed,
  'fa-duotone fa-champagne-glasses': faChampagneGlasses,
  'fa-duotone fa-person-sledding': faPersonSledding,
  'fa-duotone fa-bench-tree': faBenchTree,
  'fa-duotone fa-table-picnic': faTablePicnic,
  'fa-duotone fa-users-rectangle': faUsersRectangle,

  // Best amenities
  'fa-duotone fa-bus': faBus,
  'fa-solid fa-bell-concierge': faBellConcierge,
  'fa-regular fa-location-dot': faLocationDot,
  'fa-solid fa-user-police': faUserPolice,
  'fa-duotone fa-couch': faCouch,
  'fa-duotone fa-hot-tub-person': faHotTubPerson,
  'fa-duotone fa-elevator': faElevator,
  'fa-duotone fa-tv': faTv,
  'fa-duotone fa-wifi': faWifi,
  'fa-duotone fa-bath': faBath,
  'fa-duotone fa-wave': faUmbrellaBeach,
  'fa-duotone fa-bed-front': faBedFront,
};