import {

    faCheckCircle,
    faChevronLeft,
    faComment,
    faImage,
    faUser,
} from '@fortawesome/pro-light-svg-icons';
import axios from "axios";
import Spinner from "../../components/Loader/Spinner";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChatsInProperty = (props) => {

    const token = localStorage.getItem('token-yo-rento');
    const [isLoading, setIsLoading] = useState(false);
    const [chatsInside, setChatsInside] = useState([]);

    useEffect(() => {
        const fetchPropertyChatData = async () => {
            setIsLoading(true);
            try {
                const url = `/app/chats/properties/${props.property.id}?limit=10&skip=0`
                const respChatProperty = await axios.get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                })

                if (respChatProperty.status === 200) {
                    setChatsInside(respChatProperty.data.chatRooms);
                    console.log(chatsInside);
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setIsLoading(false);
            }
        }
        fetchPropertyChatData();
    }, []);

    const handleClick = () => {
        props.backToProperties();
    }

    const handleChatClick = (item) => {
        props.handleChatClick(item);
    }

    return (
        <div className="flex flex-col space-y-4 min-w-[300px] flex-grow">
            <div className='flex items-center space-x-4'>
                <button className='pb-2' onClick={handleClick}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <h2 className='text-lg font-medium text-purple-800 truncate pr-4 pb-2'>{props.property.title}</h2>
            </div>
            <div className="flex flex-col space-y-4 min-w-[300px] flex-grow">
                {chatsInside.map((item, index) => (
                    <div
                        key={index}
                        className="bg-white border-b-2 w-full"
                    >
                        <div
                            className="flex items-center p-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleChatClick(item)}
                        >
                            <div className="flex-shrink-0 w-12 h-12 relative rounded-full overflow-hidden">
                                {item.profile_picture ? (
                                    <img
                                        src={item.profile_picture}
                                        alt="User Profile"
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-500">
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                )}
                                {item.investigation_done && (
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="absolute bottom-0 right-0 w-4 h-4 text-green-500"
                                    />
                                )}
                            </div>
                            <div className="ml-4 flex flex-col flex-grow">
                                <h3 className="text-lg font-medium text-blue-500 truncate" title={item.user}>
                                    {item.user}
                                </h3>
                                <div className="flex items-center gap-1 mt-2 text-gray-600 text-sm truncate" title={item.message || 'Te ha enviado una invitación a chatear'}>
                                    {item.message_type === 'image' ? (
                                        <div className="flex items-center">
                                            <FontAwesomeIcon icon={faImage} className="mr-2" />
                                            [Imagen]
                                        </div>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faComment}
                                                className={`mr-2 ${item.chat_status === 'requested'
                                                    ? 'text-orange-500'
                                                    : item.chat_status === 'accepted'
                                                        ? 'text-green-500'
                                                        : 'text-gray-500'
                                                    }`}
                                            />
                                            <span className="truncate">{item.message || 'Te ha enviado una invitación a chatear'}</span>
                                        </>
                                    )}
                                </div>

                            </div>

                            <div className="flex flex-col items-end gap-1 p-3">
                                <span className={`text-sm ${item.unread_messages > 0 ? 'text-green-500' : 'text-gray-500'}`}>
                                    {new Date(item.last_message).toLocaleDateString() === new Date().toLocaleDateString()
                                        ? new Date(item.last_message).toLocaleTimeString()
                                        : new Date(item.last_message).toLocaleDateString()}
                                </span>
                                {item.unread_messages > 0 && (
                                    <span className="bg-green-500 text-white rounded-full px-2 py-1 text-xs">
                                        {item.unread_messages}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )

}

export default ChatsInProperty;