import { faArrowRight, faBaby, faBan, faBedFront, faCrown, faGarageCar, faImages, faInfoCircle, faPaw, faRulerCombined, faShower, faFileCertificate, faSealExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const formatData = (data, fallback = 'No disponible') => {
  return data !== null && data !== undefined ? data : fallback;
};

const renderCharacteristic = (icon, label, value, isAllowed = true) => {
  return (
    <div className='flex flex-col items-center space-y-1 text-center'>
      <div className="relative w-6 h-6">
        <FontAwesomeIcon icon={icon} className='text-azul text-xl' />
        {!isAllowed && (
          <FontAwesomeIcon icon={faBan} className="absolute w-full h-full left-0 top-0 text-red-500" />
        )}
      </div>
      <span className='text-sm font-semibold'>{value}</span>
    </div>
  );
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

const TarjetaPropiedad = ({ propiedad, expandir = false, onRemove }) => {
  const navigate = useNavigate();
  const esDestacada = propiedad.is_highlighted;
  const modalidad = propiedad.monthly_rent ? 'por mes' : 'por día';
  const costoRenta = propiedad.monthly_rent ? propiedad.monthly_rent : propiedad.daily_price;

  if (!propiedad) return null;

  if (propiedad.imagen === null || propiedad.imagen === undefined) {
    const imagen_frontal = propiedad.images.find(img => img.front_page) || propiedad.images[0];
    propiedad.imagen = imagen_frontal.image;
  }

  const handleNavigate = () => {
    window.open(`/propiedad/${propiedad.id}/detalle`);
    //navigate(`/propiedad/${propiedad.id}/detalle`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.div
      className={`max-h-150 rounded-xl shadow-lg transition-shadow duration-300 cursor-pointer bg-white ${esDestacada ? 'gradient-border' : 'transparente'} ${expandir ? 'w-full h-full' : ''} md:max-w-sm md:w-sm`}
      initial={{ opacity: 0, y: 20 }}
      onClick={handleNavigate}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      layout
    >
      <div className='flex flex-col h-full justify-between'>

        <div className="relative rounded-xl overflow-hidden h-48">
          <img
            className="w-full h-48 object-cover transition-transform duration-500 hover:scale-105"
            src={propiedad.imagen}
            alt={propiedad.title}
            loading="lazy"
          />

          {esDestacada && (
            <div className='absolute top-0 right-0 flex items-center justify-end w-full p-2 rounded-full'>
              <div className='w-fit rounded-full border px-2 py-1 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-purple-800 text-white flex items-center shadow-lg'>
                <FontAwesomeIcon icon={faCrown} className='text-yellow-500 text-sm mr-2' />
                Destacada
              </div>
            </div>
          )}

          {!!propiedad.validated_at && (
            <div className="absolute top-2 left-2 flex p-0 z-[2]">
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="inline-flex w-fit items-center whitespace-nowrap rounded-lg border px-3 py-1.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-azul text-white shadow-lg relative group"
              >
                <FontAwesomeIcon icon={faSealExclamation} />
                <p className="mx-2 text-xs">Confía en <br /> lo que ves</p>
                <FontAwesomeIcon icon={faFileCertificate} />
                <div className="absolute top-10 left-0 hidden text-white bg-gray-800 p-2 rounded-lg shadow-lg group-hover:block z-10 whitespace-normal text-xs">
                  Esta insignia indica que el personal de Yo rento ha validado la información publicada sobre la propiedad.
                </div>
              </motion.div>
            </div>
          )}

          <div className='absolute bottom-0 left-0 flex items-center justify-between w-full p-0 badges-container'>
            <div className='inline-flex w-fit items-center whitespace-nowrap rounded-full border ml-2 px-3 py-1.5 text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent hover:bg-primary/80 bg-purple-800 text-white shadow-lg'>
              {formatData(propiedad.property_type)}
            </div>

            <div className="flex space-x-5 p-2">
              <div className='flex text-s/ items-center space-x-2 bg-white p-2 py-1 rounded-full shadow-lg'>
                <FontAwesomeIcon icon={faImages} className='text-purple-800' />
                <span>{formatData(propiedad.images ? propiedad.images.length : 0)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 flex flex-col justify-between">
          <div>
            <div className="font-bold text-xl mb-1 text-gray-800">
              {formatCurrency(costoRenta)} / {modalidad}
            </div>
            <div className="text-sm text-gray-600 mb-2">
              <FontAwesomeIcon icon={faInfoCircle} className='mr-2 text-purple-800' />
              {propiedad.maintenance_included ? 'Mantenimiento incluido' : `Mantenimiento: ${formatCurrency(propiedad.maintenance)}`}
            </div>
            <div className="font-bold text-xl mb-1 text-gray-800 line-clamp-1">{propiedad.title}</div>
            <p className="text-gray-600 text-base mb-2 line-clamp-3">{propiedad.description}</p>
            <div className="flex flex-wrap justify-around pt-3 border-t border-gray-200">
              {renderCharacteristic(faBedFront, 'Recámaras', propiedad.rooms)}
              {renderCharacteristic(faShower, 'Baños', propiedad.bathrooms)}
              {renderCharacteristic(faPaw, 'Mascotas', propiedad.is_pet_friendly ? 'Mascotas' : 'No', propiedad.is_pet_friendly)}
              {renderCharacteristic(faRulerCombined, 'Tamaño', `${propiedad.surface} m²`)}
              {renderCharacteristic(faGarageCar, 'Autos', propiedad.parking_lot)}
              {renderCharacteristic(faBaby, 'Niños', propiedad.child_allowed ? 'Niños' : 'No', propiedad.child_allowed)}
            </div>
          </div>
        </div>

        <div className="px-4 py-3 flex flex-col justify-between">
          <div className="flex justify-end">
            <button
              onClick={handleNavigate}
              className="bg-gradient-to-r from-cyan-300 via-cyan-400 to-cyan-500 text-sm hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center transition-transform duration-300 transform hover:scale-105"
            >
              Visitar
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TarjetaPropiedad;
