import { faGaugeLow, faStar } from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import { useOutletContext } from "react-router-dom";
import DraweRating from "../../assets/drawe-rating.png";
import UserLogo from "../../assets/user-logo.svg";
import { getScoreLessor, getScoreRenter } from "../../services/auth.service";
import Loader from "../../components/Loader";
import ScoreIndicator from "./ScoreIndicator";

const MyEval = () => {
    const { user, token } = useOutletContext();
    const [score, setScore] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [allInfo, setAllInfo] = useState({});

    const getInfoComment = async () => {
        setIsLoading(true);
        try {
            const roles = {
                lessor: {
                    getScore: getScoreLessor,
                },
                renter: {
                    getScore: getScoreRenter,
                },
            };

            const newScore = { lessor: 0, renter: 0 };
            const newAllInfo = {};

            for (const [role, services] of Object.entries(roles)) {
                if (user?.roles?.includes(role)) {
                    const respScore = await services.getScore(token);
                    if (respScore.status === 200) {
                        newScore[role] = respScore.data.score || 0;
                        newAllInfo[role] = respScore.data || null;
                    }
                }
            }

            setScore(newScore);
            setAllInfo(newAllInfo);
        }
        catch (error) {
            console.error("Error al asignar roles", error);
        }
        finally {
            setIsLoading(false);
        }


    };

    useEffect(() => {
        if (user) getInfoComment();
    }, [user]);

    const NoCommentsView = ({ role }) => (
        <div className="flex flex-col items-center mt-6">
            <img src={DraweRating} alt="No comments" className="max-h-48 mb-4" />
            <h3 className="text-indigo-700 font-semibold text-base mb-2">Aún no tienes comentarios como {role}</h3>
            <div className="text-gray-500">Aquí verás los comentarios que recibas.</div>
        </div>
    );

    const NoRatingView = ({ role }) => (
        <div className="flex items-center mt-6">
            <FontAwesomeIcon icon={faGaugeLow} className="text-indigo-700 text-4xl mr-4" />
            <div>
                <h3 className="text-indigo-700 font-semibold text-base mb-2">Aún no tienes puntaje como {role}</h3>
                <div className="text-gray-500">Aquí verás el puntaje que acumules.</div>
            </div>
        </div>
    );

    const RoleSection = ({ role, displayName }) => (

        <div className="flex flex-col mb-12">
            <h4 className="text-2xl font-semibold text-gray-700 mb-4">Como {displayName}</h4>
            {score[role] > 0 ? (
                <div className="flex flex-col items-center mb-6">
                    <ScoreIndicator value={score[role]} maxValue={100} />
                    {role === "lessor" ? (
                        <div className="grid grid-cols-12 gap-y-6 items-center px-4 sm:px-10 md:px-30 mt-4">
                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.lessor.complianceStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Cumplimiento <br />de las normas <br /> del contrato
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.lessor.kindnessStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Trato <br /> y<br /> amabilidad
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.lessor.cleanlyStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Condiciones <br /> del <br /> inmueble
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.lessor.expectationsStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Entrega <br />en tiempo <br /> y forma
                                </div>
                            </div>
                        </div>


                    ) : (
                        <div className="grid grid-cols-12 gap-y-6 items-center px-4 sm:px-10 md:px-30 mt-4">
                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.renter.optimalConditionStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Responsabilidad <br /> del <br /> inquilino
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.renter.renewContractStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Trato <br /> y <br /> amabilidad
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.renter.punctualityStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Puntualidad <br /> en los <br /> pagos
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12 md:col-span-6 xl:col-span-3 flex flex-col items-center justify-center">
                                <div className="bg-transparent flex items-center justify-center border rounded-full border-indigo-700 p-3 w-14 h-14">
                                    <span className="text-center text-base">{`${allInfo.renter.complianceStars * 20}%`}</span>
                                </div>
                                <div className="text-sm mt-4 text-center">
                                    Cumplimiento <br /> de las <br /> normas
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            ) : (
                <NoRatingView role={displayName} />
            )
            }
            <div>
            </div>
        </div >
    );

    const UserProfile = ({ user }) => (
        console.log(user),
        <div className="flex items-center mb-8">
            <img
                src={user?.profile_picture || UserLogo}
                alt="User Profile"
                className="w-20 h-20 rounded-full border-2 border-indigo-600 mr-4"
            />
            <div>
                <h3 className="text-2xl font-semibold text-gray-800">{user?.name || ""}</h3>
                <div className="text-gray-600">{user?.email}</div>
                <div className="text-gray-600">{user?.phone}</div>
            </div>
        </div>
    );

    return (

        <div className="rounded-2xl p-6">
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <Loader />
                </div>) : (
                <div>
                    <UserProfile user={user} />
                    <div className={`grid gap-8 ${user?.roles?.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`}>
                        {user?.roles?.includes("renter") && (
                            <RoleSection role="renter" displayName="Inquilino" />
                        )}
                        {user?.roles?.includes("lessor") && (
                            <RoleSection role="lessor" displayName="Arrendador" />
                        )}
                    </div>
                </div>
            )}

        </div>
    );
};

export default MyEval;
