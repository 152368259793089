import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import LocationSearchInput from '../../../components/maps/filters/input-location';
import MapsWrapper from '../../../components/maps/maps-wrapper';
import useGoogleMaps from '../../../hooks/useGoogleMaps';

const AddressSearch = ({ step, setStep, handlePreviousStep, updateFormData, contador }) => {
    const loaded = useGoogleMaps();

    // Estado centralizado para manejar la ubicación
    const [location, setLocation] = useState({
        lat: 20.6736,
        lng: -103.344,
        formatted_address: 'Av Juárez 148, Zona Centro, 44100 Guadalajara, Jal., México',
        postal_code: '44100',
        city: 'Guadalajara',
        state: 'Jalisco',
        select: false,
    });

    // Estado para controlar si mostrar la dirección en el anuncio
    const [show_address, setshow_address] = useState(false);

    // Función para actualizar la ubicación en el estado y el formData
    const handleLocationChange = (newLocation) => {
        setLocation((prev) => ({
            ...prev,
            ...newLocation,
        }));
        updateFormData('location', {
            ...location,
            ...newLocation,
        });
    };

    // Función para actualizar el show_address en el estado y el formData
    const handleCheckboxChange = () => {
        setshow_address(!show_address);
        updateFormData('show_address', !show_address);  // Actualizar el formData con el nuevo valor
    };

    const onConfirmAddress = () => {
        setStep(step + 1);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full"
        >
            {contador > 0 && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h2 className="text-[#3E11B5] font-semibold text-3xl mb-6">Ubica tu inmueble</h2>
            <h4 className="text-[#3E11B5] font-semibold text-center text-xl mb-6">Puedes ingresar la dirección <br/>  o arrastrar el pin para ubicar el inmueble</h4>
            <div className="w-full mb-4">
                {loaded && (
                    <LocationSearchInput
                        setLocation={handleLocationChange}  // Pasamos la función para actualizar la ubicación
                        location={location}  // Pasamos la ubicación actualizada al input
                    />
                )}
            </div>
            <div className="relative w-full">
                {loaded && (
                    <MapsWrapper
                        zoom={16}
                        handleLocationChange={handleLocationChange}  // Pasamos la función para actualizar desde el mapa
                        location={location}  // Pasamos la ubicación al mapa
                    />
                )}
            </div>
            <div className="flex items-center space-x-2 mt-3">
                <input
                    type="checkbox"
                    id="show_address"
                    className="form-checkbox h-5 w-5 text-blue-600"
                    checked={show_address}
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="show_address" className="text-gray-700">
                    Mostrar dirección en el anuncio
                </label>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-[44px]">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={handlePreviousStep}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center text-white transition duration-300 px-6 py-3 rounded-full ${(location.select) ? 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700' : 'bg-gray-300 cursor-not-allowed'}`}
                    onClick={onConfirmAddress}
                    disabled={!location.select}
                >
                    Continuar
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>
        </motion.div>
    );
};

export default AddressSearch;
