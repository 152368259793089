import { faAddressCard, faPlus, faX } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RootLayout from "../../components/Layout";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import Radio from "../../components/form/radio";
import { useSelector } from "react-redux";
import { uploadDocuments } from "../../services/documents.service";

const MainPublish = () => {
    const navigate = useNavigate();
    const [selectItem, setSelectItem] = useState(0);
    const { token } = useSelector(state => state.auth);
    const [documentsUpload, setDocumentsUpload] = useState({
        ine: "",
        passport: "",
    });

    const onSubmitAddPropertie = async () => {
        try {
            const documentsFormData = new FormData();
            if (documentsUpload.ine)
              documentsFormData.append("idFront", documentsUpload.ine);
    
            if (documentsUpload.passport)
              documentsFormData.append("passportFront", documentsUpload.passport);
    
            await uploadDocuments(documentsFormData, token); // se cargan las imagenes (documentos) del usuario a servidor
            navigate(-1);
        } catch (e) {
          console.log(e);
        }
    };

    const items = {
        0: <UploadMain onSubmitAddPropertie={onSubmitAddPropertie} setDocumentsUpload={setDocumentsUpload} documentsUpload={documentsUpload} />,
    }

    return (
        <RootLayout admin>
            <div className="bg-[#F0F4F5] h-[92px]"></div>
            <div className="px-4 mx-auto lg:max-w-7xl md:items-center py-8 -mt-4">
                <div className="grid grid-cols-12 -mt-16">
                    <div className="col-span-12 lg:col-start-3 lg:col-span-8 bg-[#FFF] border border-[#D0CFCF] shadow-lg px-[32px] py-[29px] relative min-h-[200px]">
                        <div className="absolute top-[29px] left-[32px] cursor-pointer" onClick={() => navigate("/admin/dashboard")}><FontAwesomeIcon icon={faX} /></div>
                        {items[selectItem]}
                    </div>
                </div>
            </div>
        </RootLayout>
    )
}

const UploadMain = ({ onSubmitAddPropertie, setDocumentsUpload, documentsUpload }) => {
    const ref = useRef(null);
    const [name, setName] = useState("ine");

    const onChangeDocument = (e) => {
        const file = e.target.files[0];

        setDocumentsUpload({
            ...documentsUpload,
            [name]: file,
        });
    }

    return (
        <>
            <input type="file" className="hidden" accept=".png, .jpg, .jpeg, .pdf" ref={ref} onChange={e => onChangeDocument(e)} />
            <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-start-3 lg:col-span-8">
                    <div className="flex flex-col justify-center items-center">
                        <h3 className="text-[24px] font-semibold mb-4">Sube tu documentación</h3>
                        {/* <div className="mb-[4rem]">
                            <span className="text-[14px] text-[#777] text-center">Selecciona un documento y añade la foto del documento requerido</span>
                            <div className="mt-[4rem]">
                                <div className="flex items-center gap-x-3">
                                    <FontAwesomeIcon icon={faAddressCard} />
                                    <label className="text-[14px]">Identificación oficial </label>
                                </div>
                                <div className="border-t border-t-[#D8D8D9] my-[11px]" />
                                <div className="w-full px-2 flex justify-between pt-3">
                                    <Radio label="INE" name="radio-document" value="ine" checked={documentsUpload.ine} />
                                    <div className="text-[#3E11B5] cursor-pointer"
                                        onClick={() => {
                                            setName("ine");
                                            ref.current?.click();
                                        }}
                                        ><FontAwesomeIcon icon={faPlus} color="#3E11B5" /> Añadir</div>
                                </div>
                                <div className="w-full px-2 flex justify-between pt-2">
                                    <Radio label="Pasaporte" name="radio-document" value="passport" checked={documentsUpload.passport} />
                                    <div className="text-[#3E11B5] cursor-pointer"
                                     onClick={() => {
                                        setName("passport");
                                        ref.current?.click();
                                    }}
                                    ><FontAwesomeIcon icon={faPlus} color="#3E11B5" /> Añadir</div>
                                </div>
                                <div className="border-t border-t-[#D8D8D9] my-[11px]" />
                            </div>
                        </div> */}

<div className="mb-[4rem]">
    <span className="text-[14px] text-[#777] text-center">Selecciona un documento y añade la foto del documento requerido</span>
    <div className="mt-[4rem]">
        <div className="flex items-center gap-x-3">
            <FontAwesomeIcon icon={faAddressCard} />
            <label className="text-[14px]">Identificación oficial </label>
        </div>
        <div className="border-t border-t-[#D8D8D9] my-[11px]" />

        <div className="w-full px-2 flex justify-between pt-3 cursor-pointer"
            onClick={() => {
                setName("ine");
                ref.current?.click();
            }}
        >
            <Radio label="INE" name="radio-document" value="ine" checked={documentsUpload.ine} />
            <div className="text-[#3E11B5]">
                <FontAwesomeIcon icon={faPlus} color="#3E11B5" /> Añadir
            </div>
        </div>

        <div className="w-full px-2 flex justify-between pt-2 cursor-pointer"
            onClick={() => {
                setName("passport");
                ref.current?.click();
            }}
        >
            <Radio label="Pasaporte" name="radio-document" value="passport" checked={documentsUpload.passport} />
            <div className="text-[#3E11B5]">
                <FontAwesomeIcon icon={faPlus} color="#3E11B5" /> Añadir
            </div>
        </div>

        <div className="border-t border-t-[#D8D8D9] my-[11px]" />
    </div>
</div>

                        <div className="items-center px-16 mb-[3rem]">
                            <span onClick={() => (documentsUpload.ine || documentsUpload.passport) ? onSubmitAddPropertie() : {}} className={`text-[${(documentsUpload.ine || documentsUpload.passport) ? "#3E11B5": "#777"}] text-center cursor-pointer`}>Guardar</span>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}

export default MainPublish