const Modal = ({ children }) => {
    return (
        <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-4">
            <div className="relative w-auto my-6 mx-auto max-w-3xl min-w-[30%] bg-[#FFF] rounded-[10px]">
                <div className="border border-[#9E9E9E] rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {children}
                </div>
            </div>
        </div>
        <div className="opacity-[65%] fixed inset-0 z-40 bg-[#000]" />
        </>
    )
}

export default Modal;