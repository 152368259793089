// src/hooks/useGoogleMaps.js
import { useEffect, useState } from 'react';
import { API_KEY } from '../constants';

const useGoogleMaps = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleMaps = () => {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve(window.google.maps);
        } else {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = () => resolve(window.google.maps);
          script.onerror = (error) => reject(error);
          document.head.appendChild(script);
        }
      });
    };

    loadGoogleMaps()
      .then(() => setLoaded(true))
      .catch((error) => console.error("Error loading Google Maps:", error));
  }, [API_KEY]);

  return loaded;
};

export default useGoogleMaps;
