import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHome } from "@fortawesome/pro-light-svg-icons";

const Breadcrumb = ({ options = [] }) => {
  return (
    <div className="flex flex-row gap-3 items-center">
      <a href={localStorage.getItem("token-yo-rento") ? "/admin/dashboard" : "https://yorento.com"}>
        <FontAwesomeIcon icon={faHome} color="#777" />
      </a>
      <FontAwesomeIcon icon={faChevronRight} />
      {options.map((item, index) => (
        <Link to={item.link} key={index}>
          <span className="text-[#777] text-[12px]">{item.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default Breadcrumb;
