import { faArrowRight, faCog, faCommentDots, faEnvelopeOpenText, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RootLayout from '../../components/Layout';
import LayoutHome from '../../components/LayoutHome';
import { getNotifications } from '../../services/auth.service';

const NotificationPage = (props) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataNotification = async () => {
    try {
      const resp = await getNotifications(props.token);
      if (resp.data) {
        setNotifications(resp.data.notifications);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.token) getDataNotification();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [props.token]);

  const handleNotificationClick = (notif) => {
    switch (notif.payload.data) {
      case 'request-chat':
        navigate('/admin/chats');
        break;
      case 'accept-chat':
        navigate('/admin/chats?chat=1');
        break;
      case 'investigation-requested':
        navigate('/admin/documents-upload');
        break;
      case 'property-rented':
        navigate('/public/properties');
        break;
      case 'identification-investigation-requested':
        navigate('/admin/identification-documents');
        break;
      default:
        break;
    }
  };

  const handleSettingsClick = () => {
    navigate('/admin/notificacionesSettings');
  };

  const getIcon = (data) => {
    switch (data) {
      case 'request-chat':
        return faCommentDots;
      case 'accept-chat':
        return faCommentDots;
      case 'investigation-requested':
      case 'identification-investigation-requested':
        return faExclamationCircle;
      default:
        return faEnvelopeOpenText;
    }
  };

  return (
    <RootLayout admin>
      <LayoutHome token={props.token}>
        <div className="container mx-auto px-4 md:px-8 mt-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl text-indigo-700 font-semibold">Notificaciones</h1>
            <button
              onClick={handleSettingsClick}
              className="text-indigo-600 hover:text-indigo-800 transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faCog} size="lg" />
            </button>
          </div>
          <div className="w-full min-h-[30vh] rounded-lg mt-2 bg-white shadow-sm">
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="grid grid-cols-1 gap-6"
              >
                {notifications.map((notif, index) => (
                  <motion.div
                    key={index}
                    className="p-4 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer flex items-start"
                    onClick={() => handleNotificationClick(notif)}
                    whileHover={{ scale: 1.02 }}
                  >
                    <FontAwesomeIcon icon={getIcon(notif.payload.data)} className="text-indigo-600 text-xl mr-4" />
                    <div>
                      <h2 className="text-lg md:text-xl font-medium mb-2">{notif.title}</h2>
                      <p className="text-base font-normal mb-2">{notif.message}</p>
                      <div className="text-base font-normal text-indigo-600 flex items-center">
                        {notif.payload.data === 'accept-chat'
                          ? 'Ir a chats'
                          : notif.payload.data === 'request-chat'
                            ? 'Ver personas'
                            : notif.payload.data === 'investigation-requested'
                              ? 'Ir a documentos'
                              : notif.payload.data === 'property-rented'
                                ? 'Ir a propiedades'
                                : notif.payload.data === 'identification-investigation-requested'
                                  ? 'Validar identidad'
                                  : ''}
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </div>
        </div>
      </LayoutHome>
    </RootLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps)(NotificationPage);
