import React, { useState } from 'react';
import Switch from 'react-switch';

const GroupSwitch = ({checked, onSwitchChange}) => {

  return (
    <div>
      <Switch
        checked={checked}
        onChange={onSwitchChange}
        offColor="#888"
        onColor="#4D368A"
        offHandleColor="#FFF"
        onHandleColor="#FFF"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        height={30}
        width={60}
      />
    </div>
  );
};

export default GroupSwitch;
