// SelectInput.js
import React from "react";

const SelectInput = ({
  options,
  onChange,
  value,
  className = "",
}) => {
  return (
    <div className={`relative w-full ${className}`}>
      {/* Contenedor del select */}
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-4 py-1.5 border border-[#35BCFC] rounded-md focus:outline-none appearance-none"
      >
        {/* Opciones del select */}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg
          className="w-5 h-5 text-[#35BCFC] fill-current"
          viewBox="0 0 20 20"
        >
          <path d="M 10 13 L 9.868 13.295 L 10 13.414 l 5.707 -5.707 a 1 1 0 1 0 -1.414 -1.414 L 10 10.586 L 5.293 5.879 a 1 1 0 0 0 -1.414 1.414 l 6 6 z" />
        </svg>
      </div>
    </div>
  );
};

export default SelectInput;
