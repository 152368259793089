// ArticleSection.js
import React from "react";
import Card from "../cards/CardArticle";
import backgroundImage1 from "../../assets/im_bg.png";
import backgroundImage2 from "../../assets/im_bg-1_1.png";
import backgroundImage3 from "../../assets/im_bg-2_2.png";
//import backgroundImage from "../../assets/pexels-max-rahubovskiy-7019018 1.png";

const ArticleSection = () => {
  // Puedes proporcionar tus propios datos para las tarjetas
  const cardsData = [
    {
      id: 1,
      image: backgroundImage1,
      title: "5 pasos para encontrar el hogar de nuestros sueños",
      date: "Octubre 4, 2023",
      description:
        "No importa si lo que estamos buscando sea una casa o un apartamento, comprar o alquilar. Sea cual sea nuestro objetivo...",
    },
    {
      id: 2,
      image: backgroundImage2,
      title: "5 pasos para encontrar el hogar de nuestros sueños",
      date: "Octubre 4, 2023",
      description:
        "No importa si lo que estamos buscando sea una casa o un apartamento, comprar o alquilar. Sea cual sea nuestro objetivo...",
    },
    {
      id: 3,
      image: backgroundImage3,
      title: "5 pasos para encontrar el hogar de nuestros sueños",
      date: "Octubre 4, 2023",
      description:
        "No importa si lo que estamos buscando sea una casa o un apartamento, comprar o alquilar. Sea cual sea nuestro objetivo...",
    },
    {
      id: 4,
      image: backgroundImage1,
      title: "5 pasos para encontrar el hogar de nuestros sueños",
      date: "Octubre 4, 2023",
      description:
        "No importa si lo que estamos buscando sea una casa o un apartamento, comprar o alquilar. Sea cual sea nuestro objetivo...",
    },
    {
      id: 5,
      image: backgroundImage2,
      title: "5 pasos para encontrar el hogar de nuestros sueños",
      date: "Octubre 4, 2023",
      description:
        "No importa si lo que estamos buscando sea una casa o un apartamento, comprar o alquilar. Sea cual sea nuestro objetivo...",
    },
    {
      id: 6,
      image: backgroundImage3,
      title: "5 pasos para encontrar el hogar de nuestros sueños",
      date: "Octubre 4, 2023",
      description:
        "No importa si lo que estamos buscando sea una casa o un apartamento, comprar o alquilar. Sea cual sea nuestro objetivo...",
    },
  ];

  return (
    <div className="flex flex-wrap justify-space-between gap-8">
      {cardsData.map((card) => (
        <div key={card.id} className="w-[24rem]">
          <Card
            key={card.id}
            image={card.image}
            title={card.title}
            date={card.date}
            description={card.description}
          />
        </div>
      ))}
    </div>
  );
};

export default ArticleSection;
