import { faArrowLeft, faBuilding, faHome, faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMeDocuments, getMeUserInfo } from '../../../services/auth.service';

const PropertyType = ({ step, setStep, setTypePropertie, contador }) => {
    const { token } = useSelector((state) => state.auth);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        fetchDocuments();
        getMyInfo();
    }, [token]);

    const fetchDocuments = async () => {
        setIsLoadingDocuments(true);
        const resp = await getMeDocuments(token);
        if (resp.data) {
            setDocuments(resp.data.documents);
        }
        setIsLoadingDocuments(false);
    };

    const getMyInfo = async () => {
        const resp = await getMeUserInfo(token);
        if (resp.data) {
            setUserInfo(resp.data.user);
        }
    };

    const handleTypeSelection = (type) => {
        setSelectedType(type);
        setTypePropertie(type);
        setTimeout(() => {
            if (!userInfo.lessor_type) {
                setStep(step + 1);
                console.log(userInfo.lessor_type);
            } else if (userInfo.lessor_type === 'agent') {
                setStep(step + 3);
                console.log(userInfo.lessor_type);
            } else {
                setStep(step + 2);
                console.log(userInfo.lessor_type);
            }
        }, 300);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-12 min-h-[500px] flex items-center"
        >
            <div className="col-span-12 lg:col-start-3 lg:col-span-8 flex flex-col items-center justify-center">
                {contador > 0 && (
                    <div className="flex items-center justify-center mb-6">
                    </div>
                )}
                <h3 className="text-[#3E11B5] font-semibold text-2xl mb-8">¿Qué tipo de inmueble deseas publicar?</h3>
                {isLoadingDocuments ? (
                    <div>Cargando...</div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full">
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleTypeSelection(1)} // Casa
                            className={`shadow-md ${selectedType === 1 ? 'shadow-blue-500/50' : 'shadow-gray-300'} p-6 rounded-lg text-center cursor-pointer transition-transform duration-300 hover:shadow-lg`}
                        >
                            <FontAwesomeIcon icon={faHome} className={`text-5xl mb-4 ${selectedType === 1 ? 'text-blue-500' : 'text-[#3E11B5]'}`} />
                            <p className={`font-semibold text-xl ${selectedType === 1 ? 'text-blue-500' : 'text-[#3E11B5]'}`}>Casa</p>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleTypeSelection(2)} // Departamento
                            className={`shadow-md ${selectedType === 2 ? 'shadow-blue-500/50' : 'shadow-gray-300'} p-6 rounded-lg text-center cursor-pointer transition-transform duration-300 hover:shadow-lg`}
                        >
                            <FontAwesomeIcon icon={faBuilding} className={`text-5xl mb-4 ${selectedType === 2 ? 'text-blue-500' : 'text-[#3E11B5]'}`} />
                            <p className={`font-semibold text-xl ${selectedType === 2 ? 'text-blue-500' : 'text-[#3E11B5]'}`}>Departamento</p>
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleTypeSelection(3)} // Co-living
                            className={`shadow-md ${selectedType === 3 ? 'shadow-blue-500/50' : 'shadow-gray-300'} p-6 rounded-lg text-center cursor-pointer transition-transform duration-300 hover:shadow-lg`}
                        >
                            <FontAwesomeIcon icon={faUsers} className={`text-5xl mb-4 ${selectedType === 3 ? 'text-blue-500' : 'text-[#3E11B5]'}`} />
                            <p className={`font-semibold text-xl ${selectedType === 3 ? 'text-blue-500' : 'text-[#3E11B5]'}`}>Co-living</p>
                        </motion.div>
                    </div>
                )}
                <div className="w-full flex justify-start items-center mt-8">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                        onClick={() => setStep(step - 1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Regresar
                    </motion.button>
                </div>
            </div>
        </motion.div>
    );
};

export default PropertyType;
