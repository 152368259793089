import { create } from "zustand";
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useStore = create((set) => ({
  filters: {
    lat: null,
    lng: null,
    minLng: 0,
    maxLng: 0,
    minLat: 0,
    maxLat: 0,
    format_address: "",
    houses: true,
    departaments: true,
    priceMin: 8000,
    priceMax: 60000,
    rooms: null,
    antiquityMin: 1,
    antiquityMax: 40*365,
    radius: 0,
    publishedFrom: null,
    latP: null,
    lngP: null
  },
  update: ({ name, value }) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [name]: value,
      },
    })),
  updateType: (name) =>
    set((state) => ({
      filters: {
        ...state.filters,
        houses: name === "departaments" ? false : true,
        departaments: name === "houses" ? false : true,
      },
    })),
  remove: () =>
    set(() => ({
      filters: {
        lat: null,
        lng: null,
        minLng: 0,
        maxLng: 0,
        minLat: 0,
        maxLat: 0,
        format_address: "",
        houses: true,
        departaments: true,
        priceMin: 8000,
        priceMax: 60000,
        rooms: null,
        antiquityMin: 1,
        antiquityMax: 40*365,
        radius: 0,
        publishedFrom: null,
        latP: null,
        lngP: null
      },
    })),
}),
/* {
  name: 'yorento-storage', // name of the item in the storage (must be unique)
  storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
},
  ) */
);



if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useStore);
}
