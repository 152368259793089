import axios from "axios";


const base = process.env.REACT_APP_BACKEND_URL || 'https://qa-api.yorento.com';

axios.defaults.baseURL = base;

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// Añadir un interceptor de solicitud
axios.interceptors.request.use(
  function (config) {
    // Hacer algo antes de que la solicitud sea enviada
    const token = localStorage.getItem('token-yo-rento');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Hacer algo con el error de la solicitud
    return Promise.reject(error);
  }
);

// Añadir un interceptor de respuesta
axios.interceptors.response.use(
  function (response) {
    if (response.headers['token']) {
      localStorage.setItem("token-yo-rento", response.headers['token']);
    }

    // Cualquier código de estado que esté dentro del rango de 2xx causa que esta función sea activada
    return response;
  },
  function (error) {
    console.log(error);

    if (error && error.response && error.response.status === 401 && window.location.pathname !== '/auth/login') {
      localStorage.removeItem('token-yo-rento');
      localStorage.removeItem('userData');
      window.location.href = '/auth/login';
    }

    // Cualquier código de estado que esté fuera del rango de 2xx causa que esta función sea activada
    console.log(
      "Error en la llamada API:",
      error.response ? error.response.data : error.message
    );
    return Promise.reject(error);
  }
);

export default axios;
