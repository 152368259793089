import { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import TextInput from "../../components/form/textinput";
import Button from "../../components/form/button";
import ReactCodeInput from "react-code-input";
import RootLayout from "../../components/Layout";
import {
  sendCode,
  sendCodeEmail,
  verifiedCode,
  verifiedCodeEmail,
  putProfileUser,
  sendReferralCode
} from "../../services/auth.service";
import { useNavigate } from "react-router-dom"; // Importa BrowserRouter
import * as actions from "../../redux/actions/auth-action";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Success from "../../components/modals/modal-success";

function Signup(props) {
  const tokenId = null;
  //const location = useLocation();
  const [state, setState] = useState({});
  //const state = location.state;
  const navigate = useNavigate(); // Usa useNavigate para navegación en v6

  useEffect(() => {
    if (localStorage.getItem("inmobiliaria-data")) {
      setState(JSON.parse(localStorage.getItem("inmobiliaria-data")));
    }
  }, [])

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [refCode, setRefCode] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [steps, setSteps] = useState(1);
  const [code, setCode] = useState("");
  const [token, setToken] = useState("");

  const onSubmit = async (type = "renter") => {
    try {
      const payload = {
        email: tokenId ? "email desde google" : email,
        name: name,
        userType: type,
        password: tokenId ? undefined : password,
        token_id: tokenId ?? undefined,
        acceptTerms: true,
        privacyRead: true
      };

      const resp = await putProfileUser(payload, token);
      if (resp.data && resp.data.updated) {
        //console.log(resp.data)
        getDataUser(resp.data);
      }
    } catch (e) {
      console.log(e);
      alert("Ocurrio algo inesperado intentalo mas tarde");
    }
  };

  const getDataUser = async (data) => {
    localStorage.setItem("token-yo-rento", data.token);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: data.token,
        role: "USER",
        id: data.user.userId,
        user: data.user,
      })
    );
    console.log(data);

    props.loginSuccess(
      {
        token: data.token,
        role: state && state.name && state.code ? "INMOBILIARIA" : "USER",
        id: data.user.userId,
        user: data.user,
      },
      data.token
    );


    window.location = "/";
    

    return;
  };

  const renderStep = {
    1: (
      <StepCodePhone
        state={state}
        phone={phone}
        setPhone={setPhone}
        setSteps={setSteps}
        setCode={setCode}
        setToken={setToken}
        tokenId={tokenId}
        token={token}
        code={code}
        steps={steps}
        {...props}
      />
    ),
    2: (
      <StepCodeEmail
        email={email}
        setEmail={setEmail}
        setSteps={setSteps}
        setCode={setCode}
        phone={phone}
        code={code}
        steps={steps}
        {...props}
      />
    ),
    3: (
      <StepReferralCode
        refCode={refCode}
        setRefCode={setRefCode}
        setSteps={setSteps}
        steps={steps}
        {...props}
      />
    ),

    4: (
      <StepNameComplete
        name={name}
        setName={setName}
        password={password}
        setPassword={setPassword}
        setSteps={setSteps}
        setConfirmPass={setConfirmPass}
        confirmPass={confirmPass}
        steps={steps}
        {...props}
      />
    ),
    5: (
      <StepTermAndConfition
        onSubmit={onSubmit}
        setSteps={setSteps}
        steps={steps}
        {...props}
      />
    ),
  };

  return (
    <RootLayout>
      <div className="flex flex-col">
        <div className="py-[12px]">
          <Breadcrumb options={[{ name: "Registro", link: "/signin" }]} />
        </div>
        <div className="grid grid-cols-12 sm:grid-cols-3 sm:gap-4 justify-center">
          <div className="col-span-1"></div>
          <div className="col-span-10 sm:col-span-1">{renderStep[steps]}</div>
        </div>
      </div>
    </RootLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.auth.isLoading,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (data, token = null) => dispatch(actions.loginSuccess(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

const StepCodePhone = ({
  state,
  phone,
  setPhone,
  setSteps,
  steps,
  code,
  setCode,
  setToken,
  tokenId,
}) => {
  const [timer, setTimer] = useState(false);
  const [existingAcc, setExistingAcc] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendPhoneCode = async () => {
    if (loading || phone.length !== 10) { return; }
    setLoading(true);
    let dataInmob = {}
    if (state && state.code && state.name) {
      dataInmob = {
        "code": state.code,
        "estate_agency": state.name
      }
    }

    try {
      const resp = await sendCode({ phone: `+52${phone}`, ...dataInmob });
      if (resp.status === 201) {
        setTimer(true);
        setTimeout(() => {
          setTimer(false);
        }, 5000);
        return;
      }
    } catch (error) {
      if (error.response.data.message) {
        setExistingAcc(true);
        setTimeout(() => {
          setExistingAcc(false);
        }, 3000);

      } else {
        alert("Ocurrio algo inesperado, comunicate con el administrador");
      }
    } finally {
      setLoading(false);
    }
  };

  const validationCode = async () => {
    const { data } = await verifiedCode(`+52${phone}`, code);
    if (data) {
      setCode("");
      setToken(data.token);
      if (tokenId) {
        setSteps(3);
        return;
      }

      setSteps(steps + 1);
      return;
    }

    alert("Codigo incorrecto");
  };

  return (
    <div className="mb-10">
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Registro
      </h2>
      <p className="text-center mb-12">
        Por favor comparte tu número de cel. <br />
        Recibirás un código vía mensaje de texto
      </p>

      <div className="relative">
        <div className="absolute pr-2 top-2.5 left-2 border-r border-r-[#3E11B5] border-dashed">
          <span className="text-[#3E11B5]">+52</span>
        </div>
        <TextInput

          placeholder="Tu número de cel aquí"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          styleinput={{ paddingLeft: 20 }}
        />
        <div className="absolute top-2.5 right-2 cursor-pointer" onClick={() => setPhone("")}>
          <FontAwesomeIcon icon={faXmarkCircle} color="#777777" />
        </div>
        {timer && (
          <p className="text-#4C44ED text-center">
            Código enviado correctamente
          </p>
        )}
        {existingAcc && (
          <p className="text-red-500 text-center">
            Este número ya tiene una cuenta de Yo rento asociada.
          </p>
        )}

        <div className="w-full mt-[40px]">
          <Button onClick={() => sendPhoneCode()} disabled={phone.length !== 10 || loading} right>
            {loading ? "Enviando..." : "Enviar"}
          </Button>
        </div>

        <p className="text-center text-[#777] mt-[25px] cursor-pointer" onClick={() => sendPhoneCode()} >
          {loading ? "Enviando..." : "Enviar código de nuevo"}
        </p>

        <h2 className="!text-[#3E11B5] text-[15px] font-semibold text-center my-12">
          Ingresa el código que se te ha enviado
        </h2>

        <div className="flex flex-row justify-center ">
          <ReactCodeInput
            autoFocus={false}
            type="number"
            fields={4}
            value={code}
            className="!flex w-full justify-between"
            onChange={(value) => setCode(value)}
            inputStyle={{
              textAlign: "center",
              width: 47,
              height: 44,
              borderColor: "#3E11B5",
              borderStyle: "solid",
              borderWidth: 1,
              borderRadius: 4,
            }}
          />
        </div>

        <div className="w-full mt-[40px]">
          <Button onClick={() => validationCode()} disabled={!code} right>
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

const StepCodeEmail = ({
  email,
  setEmail,
  setSteps,
  steps,
  setCode,
  code,
  phone,
}) => {
  const [timer, setTimer] = useState(false);
  const [securePass, setSecurePass] = useState(true);
  const [existingAcc, setExistingAcc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const sendEmailCode = async () => {
    if (!email.match(emailRegex)) return;

    setIsLoading(true);
    try {
      const resp = await sendCodeEmail(email, `+52${phone}`);
      if (resp.status === 201) {
        setTimer(true);
        setTimeout(() => {
          setTimer(false);
        }, 5000);
        return;
      }
    } catch (error) {
      if (error.response.data.message) {
        setExistingAcc(true);
        setTimeout(() => {
          setExistingAcc(false);
        }, 3000);
        console.log(error.response.data.message);
      } else {
        alert("Ocurrio algo inesperado, comunicate con el administrador");
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  const validationCode = async () => {
    try {
      const { data } = await verifiedCodeEmail(email, code);
      if (data) {
        setSteps(steps + 1);
        return;
      }

      alert("Codigo incorrecto");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Registro
      </h2>
      <p className="text-center mb-12 text-[#000000]">
        ¿Nos compartes tu correo electrónico?
      </p>

      <div className="relative">
        <TextInput
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="absolute top-2.5 right-2 cursor-pointer" onClick={() => setEmail("")}>
          <FontAwesomeIcon icon={faXmarkCircle} color="#777777" />
        </div>
        {timer && (
          <p className="text-#4C44ED text-center">
            Código enviado correctamente
          </p>
        )}
        {existingAcc && (
          <p className="text-red-500 text-center">
            Este correo ya tiene una cuenta de Yo rento asociada.
          </p>
        )}
        <div className="w-full mt-[40px]">
          <Button onClick={() => sendEmailCode()} disabled={!email.match(emailRegex) || isLoading} right>
            {isLoading ? 'Enviando...' : 'Enviar'}
          </Button>
        </div>

        <p className="text-center text-[#777] mt-[25px] cursor-pointer" onClick={sendEmailCode}>
          Enviar código de nuevo
        </p>

        <h2 className="text-[#000000] text-[15px] text-center my-12">
          Hemos enviado un código a tu correo electrónico, por favor ingrésalo
          aquí
        </h2>
        <div className="relative">
          <TextInput
            type={securePass ? "password" : "text"}
            placeholder="Ingresa tu código aquí"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          {securePass ? (
            <FontAwesomeIcon
              icon={faEye}
              style={{ position: "absolute", right: 10, top: 14 }}
              onClick={() => setSecurePass(!securePass)}
              className="cursor-pointer"
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              style={{ position: "absolute", right: 10, top: 14 }}
              onClick={() => setSecurePass(!securePass)}
              className="cursor-pointer"
            />
          )}
        </div>

        <div className="w-full mt-[40px]">
          <Button onClick={() => validationCode()} disabled={!code} right>
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

const StepReferralCode = ({
  refCode,
  setRefCode,
  setSteps,
  steps,
}) => {
  const [timer, setTimer] = useState(false);
  const [securePass, setSecurePass] = useState(true);
  const [existingAcc, setExistingAcc] = useState(false);
  const [showModalWrong, setShowModalWrong] = useState(false);
  const text = 'Código de referido inválido o ya en uso.';

  const sendReferralPatch = async () => {
    try {
      const resp = await sendReferralCode(refCode);
      if (resp.status === 200) {
        setSteps(steps + 1);
      } else {
        setShowModalWrong(true);
      }
    } catch (error) {
      console.error("Error al enviar codigo de referido", error);
    }
  };

  const handleSkipReferral = () => {
    setSteps(steps + 1);
  };

  return (
    <div>
      <Success show={showModalWrong} text={text} />
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Registro
      </h2>
      <p className="text-center mb-12 text-[#000000]">
        ¿Tienes un código de referido?
      </p>

      <div className="relative">
        <TextInput
          placeholder="Ingresa tu código de referido"
          value={refCode}
          onChange={(e) => setRefCode(e.target.value)}
        />
        <div className="w-full mt-[40px]">
          <Button onClick={sendReferralPatch} disabled={!refCode} right>
            Continuar
          </Button>
        </div>

        <Button
          className="text-center text-[#777] mt-[25px] cursor-pointer"
          onClick={handleSkipReferral}
          right
        >
          No cuento con ningún código de referido
        </Button>
      </div>
    </div>
  );
};

const StepNameComplete = ({
  setSteps,
  steps,
  name,
  setName,
  password,
  setPassword,
  confirmPass,
  setConfirmPass,
}) => {
  const [securePass, setSecurePass] = useState({
    newPass: true,
    confirmNewPass: true
  })
  const [matchVerified, setMatchVerified] = useState({
    mayuscula: null,
    numero: null,
    especial: null,
    tamano: null,
  });
  const [percentage, setPercentage] = useState(0);
  const verifiedRegexPassword = () => {
    setMatchVerified({
      mayuscula: password.match(/[A-Z]/g) !== null,
      numero: password.match(/[0-9]/g) !== null,
      especial:
        password.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== null,
      tamano: password.length > 7,
    });
  };

  const getPercentage = () => {
    let complete = 0;
    const verify = Object.values(matchVerified);
    complete = verify.filter((v) => v).length;
    setPercentage((complete * 25) / 100);
  };

  useEffect(() => {
    verifiedRegexPassword();
  }, [password]);

  useEffect(() => {
    getPercentage();
  }, [password, matchVerified]);

  return (
    <div>
      <div>
        {(percentage * 100 === 100 && password === confirmPass && name) &&
          <div className="flex flex-row items-center justify-center p-2 gap-x-2 mb-[10px] bg-[#CFF1FF] border border-[#0097DF] rounded-[4px] min-w-[20%]">
            <p className="text-[#0097DF]">Verificación exitosa</p>
            <FontAwesomeIcon icon={faCheckCircle} color="#0097DF" />
          </div>
        }
      </div>
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Registro
      </h2>

      <p className="text-center mb-4 mt-20">Por favor escribe tu nombre</p>
      <div className="relative ">
        <TextInput
          placeholder="Tu nombre aquí"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="absolute top-2.5 right-2 cursor-pointer" onClick={() => setName("")}>
          <FontAwesomeIcon icon={faXmarkCircle} color="#777777" />
        </div>
      </div>

      <div className="relative mb-[68px]">
        <p className="text-center mb-4 mt-12">Crea una contraseña</p>
        <TextInput
          placeholder="Contraseña"
          type={securePass.newPass ? "password" : "text"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {securePass.newPass ? (
          <FontAwesomeIcon
            icon={faEye}
            style={{ position: "absolute", right: 10, top: 53 }}
            onClick={() => setSecurePass({ ...securePass, newPass: !securePass.newPass })}
            className="cursor-pointer"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            style={{ position: "absolute", right: 10, top: 53 }}
            onClick={() => setSecurePass({ ...securePass, newPass: !securePass.newPass })}
            className="cursor-pointer"
          />
        )}

        <p className="text-center mb-4 mt-12">Confirma tu contraseña</p>
        <TextInput
          placeholder="Confirma tu contraseña"
          type={securePass.confirmNewPass ? "password" : "text"}
          value={confirmPass}
          onChange={(e) => setConfirmPass(e.target.value)}
        />
        {securePass.confirmNewPass ? (
          <FontAwesomeIcon
            icon={faEye}
            style={{ position: "absolute", right: 10, top: 185 }}
            onClick={() => setSecurePass({ ...securePass, confirmNewPass: !securePass.confirmNewPass })}
            className="cursor-pointer"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            style={{ position: "absolute", right: 10, top: 185 }}
            onClick={() => setSecurePass({ ...securePass, confirmNewPass: !securePass.confirmNewPass })}
            className="cursor-pointer"
          />
        )}
      </div>

      <div className="mb-[4rem]">
        <Progress percent={percentage * 100} strokeWidth={1} theme={{
          active: {
            color: '#30D45E'
          },
          success: {
            symbol: "100%",
            color: '#30D45E'
          }
        }} />

        <div className="flex flex-row items-center gap-x-3 py-1 mt-2">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.tamano ? "#30D45E" : "#777"}
          />
          <p>Al menos 8 caracteres</p>
        </div>
        <div className="flex flex-row items-center gap-x-3 py-1">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.mayuscula ? "#30D45E" : "#777"}
          />
          <p>Al menos una mayúscula</p>
        </div>
        <div className="flex flex-row items-center gap-x-3 py-1">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.numero ? "#30D45E" : "#777"}
          />
          <p>Al menos un números</p>
        </div>
        <div className="flex flex-row items-center gap-x-3 py-1">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.especial ? "#30D45E" : "#777"}
          />
          <p>Al menos un carácter especial</p>
        </div>
      </div>

      <div className="w-full mt-[40px]">
        <Button
          onClick={() => setSteps(steps + 1)}
          disabled={
            !name || !password || !confirmPass || password !== confirmPass
          }
          right
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

const StepTermAndConfition = ({ onSubmit }) => {
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  return (
    <div className="mt-[40px]">
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Te damos la bienvenida a Yo rento
      </h2>

      <p className="mb-4 mt-20">
        Esta es una comunidad segura para rentar o publicar tu inmueble.
        <br />
        <br />
        Para continuar te pedimos que aceptes los términos y condiciones. Puedes
        consultar los mismos en el link de abajo.
      </p>
      <p className="text-#4C44ED">Ver más</p>

      <div className="pt-6">
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={terms}
            onChange={() => setTerms(!terms)}
            className="w-4 h-4 text-blue-600 accent-#4C44ED bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            className="ms-2 text-sm font-medium  cursor-pointer"
            onClick={() => window.open("https://yorento.com/terminos-y-condiciones.pdf", "_blank")}
          >
            He leído y acepto los <span className="font-bold underline">términos y condiciones</span> de uso.
          </label>
        </div>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={privacy}
            onChange={() => setPrivacy(!privacy)}
            className="w-4 h-4 text-blue-600 accent-#4C44ED bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            className="ms-2 text-sm font-medium cursor-pointer"
            onClick={() => window.open("https://yorento.com/aviso-de-privacidad.pdf", "_blank")}
          >
            Conozco el <span className="font-bold underline">aviso de privacidad</span>.
          </label>
        </div>
      </div>

      <div className="w-full mt-[40px]">
        <Button onClick={() => onSubmit()} disabled={!privacy || !terms} right>
          Aceptar y continuar
        </Button>
      </div>
    </div>
  );
};
