import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default function RootLayout({ children, admin, className }) {
  return (
    <div>
      <Header admin={admin} />
      <div className="min-h-[80vh] bg-[#FFF]">
        <div
          className={admin ? "" : `px-4 mx-auto lg:max-w-7xl md:items-center ${className ?? ""}`}
        >
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}
