import { faArrowLeft, faArrowRight, faBath, faBed, faBroom, faCouch, faEllipsisH, faHouse, faImage, faPlus, faTree } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

const UploadPhotos = ({ step, setStep, setPhotosProperties, photosProperties, contador, setMainPhoto }) => {
    const ref = useRef(null);
    const [facade, setFacade] = useState(photosProperties.facade || ["", "", ""]);
    const [rooms, setRooms] = useState(photosProperties.rooms || ["", "", ""]);
    const [bathrooms, setBathrooms] = useState(photosProperties.bathrooms || ["", "", ""]);
    const [livingRoom, setLivingRoom] = useState(photosProperties.livingRoom || ["", "", ""]);
    const [serviceRoom, setServiceRoom] = useState(photosProperties.serviceRoom || ["", "", ""]);
    const [externalViews, setExternalViews] = useState(photosProperties.externalViews || ["", "", ""]);
    const [other, setOther] = useState(photosProperties.other || ["", "", ""]);
    const [checkImage, setCheckImage] = useState(photosProperties.checkImage || { category: null, imageIndex: null });

    const [pressValue, setPressValue] = useState({
        id: 0,
        values: [],
        onPress: () => { },
    });

    useEffect(() => {
        if (photosProperties) {
            setFacade(photosProperties.facade || ["", "", ""]);
            setRooms(photosProperties.rooms || ["", "", ""]);
            setBathrooms(photosProperties.bathrooms || ["", "", ""]);
            setLivingRoom(photosProperties.livingRoom || ["", "", ""]);
            setServiceRoom(photosProperties.serviceRoom || ["", "", ""]);
            setExternalViews(photosProperties.externalViews || ["", "", ""]);
            setOther(photosProperties.other || ["", "", ""]);
            setCheckImage(photosProperties.checkImage || { category: null, imageIndex: null });
        }
    }, [photosProperties]);

    const onPressMediaLibrary = async (e) => {
        try {
            const file = e.target.files[0];
            let prev = pressValue.values;
            if (pressValue.id <= pressValue.values.length - 1) {
                prev[pressValue.id] = file;
            } else {
                const index = prev.findIndex((pv) => pv === "");
                if (index !== -1) {
                    prev[index] = file;
                } else {
                    prev.push(file);
                }
            }

            pressValue.onPress(prev);
            setPressValue({
                id: 0,
                values: [],
                onPress: () => { },
            });
        } catch (e) {
            alert("Error Uploading Image " + e.message);
        }
    };

    const confirmPhotos = () => {
        if (
            facade.find(f => f !== "") ||
            rooms.find(f => f !== "") ||
            bathrooms.find(f => f !== "") ||
            livingRoom.find(f => f !== "") ||
            serviceRoom.find(f => f !== "") ||
            externalViews.find(f => f !== "") ||
            other.find(f => f !== "")
        ) {
            setPhotosProperties({
                facade,
                rooms,
                bathrooms,
                livingRoom,
                serviceRoom,
                externalViews,
                other,
                checkImage,
            });

            setStep(step + 1);
        } else {
            alert("Debe subir por lo menos una foto");
        }
    };

    const handleImageSelection = (category, index) => {
        setCheckImage({ category, imageIndex: index + 1 });
        console.log(setMainPhoto);

        if (setMainPhoto) { // Verificar si setMainPhoto es una función
            setMainPhoto({ category, imageIndex: index }); // Aquí actualizas el estado
        } else {
            console.error('setMainPhoto is not a function');
        }
    };


    const renderImageUpload = (label, items, setItems, category, icon) => {
        const displayItems = [...items];

        while (displayItems.length < 3) {
            displayItems.push("");
        }

        return (
            <div className="col-start-2 col-span-4 mb-4">
                <div className="flex flex-row justify-between items-center mb-2">
                    <label className="text-[#3E11B5] text-[15px] flex items-center gap-x-2">
                        <FontAwesomeIcon icon={icon} />
                        {label}
                    </label>
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex items-center cursor-pointer gap-x-1 bg-blue-500 text-white px-4 py-2 rounded-full"
                        onClick={() => {
                            ref.current.click();
                            setPressValue({
                                id: items.length,
                                onPress: setItems,
                                values: items,
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <span>Añadir</span>
                    </motion.div>
                </div>
                <div className="w-full flex overflow-x-scroll space-x-4 py-2">
                    {displayItems.map((item, i) => (
                        <div className="relative" key={i}>
                            <div
                                onClick={() => {
                                    ref.current.click();
                                    setPressValue({ id: i, onPress: setItems, values: displayItems });
                                }}
                                className="cursor-pointer w-[135px] h-[86px] bg-[#ECECEC] rounded-[5px] border-[#3E11B5] flex flex-col justify-center items-center"
                                style={{
                                    borderStyle: item ? "solid" : "dashed",
                                    borderWidth: 0.8,
                                }}
                            >
                                {item ? (
                                    typeof item === 'string' ? (
                                        <img
                                            src={item}
                                            alt=""
                                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                        />
                                    ) : (
                                        <img
                                            src={URL.createObjectURL(item)}
                                            alt=""
                                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                        />
                                    )
                                ) : (
                                    <FontAwesomeIcon icon={faImage} size="2x" color={"#3E11B5"} />
                                )}
                            </div>
                            <input
                                type="checkbox"
                                checked={checkImage.category === category && checkImage.imageIndex === i + 1}
                                onChange={() => handleImageSelection(category, i)}
                                className="w-4 h-4 absolute bottom-2 right-4 z-10 text-green-500 bg-gray-100 border-gray-300 rounded focus:ring-green-500 accent-[#3E11B5]"
                                disabled={item === ""}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full"
        >
            {contador > 0 && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Selecciona las mejores fotos</h3>
            <span className="text-[#777777] text-center mb-6">Añade por lo menos una foto por categoría y una vez cargadas selecciona con un check la foto que deseas aparezca en portada.</span>
            <input type="file" className="hidden" accept=".png, .jpg, .jpeg" ref={ref} onChange={(e) => onPressMediaLibrary(e)} />
            <div className="w-full md:grid md:grid-cols-6 md:gap-y-6">
                {renderImageUpload("Fachada", facade, setFacade, "facade", faHouse)}
                {renderImageUpload("Recámaras", rooms, setRooms, "rooms", faBed)}
                {renderImageUpload("Baños", bathrooms, setBathrooms, "bathrooms", faBath)}
                {renderImageUpload("Sala", livingRoom, setLivingRoom, "livingRoom", faCouch)}
                {renderImageUpload("Cuarto de servicio", serviceRoom, setServiceRoom, "serviceRoom", faBroom)}
                {renderImageUpload("Vistas exteriores", externalViews, setExternalViews, "externalViews", faTree)}
                {renderImageUpload("Otros", other, setOther, "other", faEllipsisH)}
            </div>
            <div className="w-full flex justify-between mt-6">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-blue-500 hover:bg-blue-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={confirmPhotos}
                >
                    Continuar
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>
        </motion.div>
    );
};

export default UploadPhotos;
