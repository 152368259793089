import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Importa el ícono de WhatsApp
import {
  faBars,
  faX
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../assets/icon.png';
import { menuAdminTitles, menuTitles } from '../../constants';
import { getNotifications } from '../../services/auth.service';
import Dropdown from './Dropdown';
import { faHeadset } from '@fortawesome/pro-solid-svg-icons';

const Navbar = ({ user }) => {

  const User = user.user;
  const listHeader = !User ? menuTitles : menuAdminTitles;
  const [notifications, setNotifications] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  const getDataNotification = async () => {
    try {
      const resp = await getNotifications(token);
      if (resp.data) {
        setNotifications(resp.data.notifications);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (token) {
      getDataNotification();
    }
  }, [token]);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [navbarRef.current]);

  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <nav ref={navbarRef} className="fixed top-0 left-0 w-full z-10 py-1">
        <div className="container bg-white mx-auto rounded-full shadow px-8 py-4 flex items-center justify-between">
          <Link to="/" className="flex items-center">
            <img src={Icon} alt="icon" className="w-24" />
          </Link>
          <div className="flex-1 flex items-center justify-end lg:hidden">
            <button
              className="p-2 text-gray-700 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
              onClick={() => setNavbar(!navbar)}
              aria-label={navbar ? 'Cerrar menú de navegación' : 'Abrir menú de navegación'}
            >
              {navbar ? (
                <FontAwesomeIcon icon={faX} className="text-black p-1 flex items-center"
                  aria-label={navbar ? 'Cerrar menú de navegación' : 'Abrir menú de navegación'} />
              ) : (
                <FontAwesomeIcon icon={faBars} className="text-black p-1 flex items-center"
                  aria-label={navbar ? 'Cerrar menú de navegación' : 'Abrir menú de navegación'} />
              )}
            </button>

          </div>

          <div className="hidden lg:flex flex-1 justify-end items-center space-x-6">
            {listHeader.map(({ name, url, dropdown, type, icon }, index) => (
              <div key={index} className="relative">
                {dropdown ? (
                  <div className="group inline-block relative">
                    <button className={`font-medium text-lg transition-all duration-300 flex items-center ${location.pathname === url ? 'text-purple-600 border-b-4 border-purple-600' : 'text-gray-800 hover:text-azul'}`}>
                      {name}
                      <svg className="fill-current h-4 w-4 ml-2 mt-1 hidden lg:inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                      </svg>
                      {icon && (
                        <FontAwesomeIcon icon={icon} className="ml-2 lg:hidden" />
                      )}
                    </button>
                    <ul className="absolute hidden text-gray-700 group-hover:block bg-white shadow-lg rounded-md">
                      {dropdown.map((item, i) => (
                        <li key={i} className="">
                          <Link
                            to={item.url}
                            className={`py-2 px-4 block whitespace-no-wrap transition-all duration-300 ${location.pathname === item.url ? 'bg-purple-800 text-white' : 'text-gray-800 hover:bg-gray-200'}`}
                            aria-label={item.name}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Link
                    to={url}
                    role="button"
                    className={`font-medium text-lg transition-all duration-300 flex items-center ${location.pathname === url ? (name ? 'text-purple-600 border-b-4 border-purple-600' : 'bg-purple-800 px-3 py-2 text-white rounded-full') : 'text-gray-800 hover:text-azul'} ${(type === 'button' || type === 'buttom') ? 'bg-gradient-to-r from-cyan-300 via-cyan-400 to-cyan-500 text-white px-4 py-2 rounded-full hover:bg-cyan-500' : ''}`}
                    aria-label={name || 'Abrir enlace'}
                  >
                    {name && (
                      <span className="hidden lg:inline">{name}</span>
                    )}
                    {icon && (
                      <FontAwesomeIcon icon={icon} className={`${name ? 'ml-2 lg:hidden' : ''}`} />
                    )}
                  </Link>

                )}
              </div>
            ))}
          </div>

          <div className={`fixed inset-0 bg-white flex flex-col items-center justify-center z-10 transition-transform transform ${navbar ? 'translate-x-0' : 'translate-x-full'} lg:hidden`}>
            <button
              className="absolute top-4 right-4 p-2 text-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
              onClick={() => setNavbar(false)}
              aria-label="Cerrar el menú de navegación"
            >
              <FontAwesomeIcon icon={faX} className="text-black" />
            </button>
            <ul className="flex flex-col items-center space-y-4">
              {listHeader.map(({ name, url, dropdown, type, icon }, index) => (
                <li key={index} className={`font-medium text-lg transition-all duration-300 ${location.pathname === url ? 'text-purple-600 border-b-4 border-purple-600' : 'text-gray-800 hover:text-azul'}`}>
                  {dropdown ? (
                    <Dropdown name={name} dropdownItems={dropdown} />
                  ) : (
                    <Link to={url} className={`flex items-center transition-all duration-300 ${(type === 'button' || type === 'buttom') ? 'bg-gradient-to-r from-cyan-300 via-cyan-400 to-cyan-500 text-white px-4 py-2 rounded-full hover:bg-cyan-500' : ''}`}>
                      {name}
                      {icon && (
                        <FontAwesomeIcon icon={icon} className="ml-2" />
                      )}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Botón flotante de WhatsApp */}
      <a
        href="https://wa.me/5215654337226?text=Hola,%20quisiera%20comunicarme%20con%20atención%20al%20cliente."
        className={`fixed ${location.pathname === '/admin/chats' ? 'bottom-3 left-7' : 'bottom-5 right-5'} z-[9999999999] bg-blue-500 w-12 h-12 flex justify-center items-center rounded-full shadow-lg text-white hover:bg-blue-600 transition-colors`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Enviar mensaje de WhatsApp"
      >
        <FontAwesomeIcon icon={faHeadset} size="2x"
          aria-label="Enviar mensaje de WhatsApp" />
      </a>


      <div className='bg-white' style={{ paddingTop: `${navbarHeight}px` }}></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.userData,
});

export default connect(mapStateToProps)(Navbar);
