import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

const ImagesSlider = ({ images, type }) => {
  const [mainImg, setMainImg] = useState({
    id: 0,
    image: "",
  });

  const [position, setPosition] = useState(0);

  return (
    <figure className="sixteen-nine-img">
      {type && (
        <div
          style={{
            position: "absolute",
            left: 20,
            top: 15,
            background: "#FFF",
            borderRadius: 5,
            textTransform: "capitalize",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          {type}
        </div>
      )}
        <div
          style={{
            position: "absolute",
            cursor: "pointer",
            width: "1.8rem",
            height: "3.2rem",
            zIndex: 2,
            right: 0,
            fontSize: 16,
            top: "38%",
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            backgroundColor: "rgba(255, 255, 255, 0.90)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFF",
          }}
          onClick={() => {
            if(position === images.length - 1){
              setMainImg(images[0]);
              setPosition(0);
            } else{
              setMainImg(images[position + 1]);
              setPosition(position + 1);
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} color="#3E11B5" size="lg" />
        </div>
        <div
          style={{
            position: "absolute",
            cursor: "pointer",
            width: "1.8rem",
            height: "3.2rem",
            zIndex: 2,
            left: 0,
            fontSize: 16,
            top: "38%",
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            backgroundColor: "rgba(255, 255, 255, 0.90)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFF",
          }}
          onClick={() => {
            if(position === 0){
              setMainImg(images[images.length - 1])
              setPosition(images.length - 1);
            }else{
              setMainImg(images[position - 1]);
              setPosition(position - 1);
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="#3E11B5" size="lg" />
        </div>
      <img src={mainImg.image || images[0]?.image } alt={mainImg.id + ""} style={{ objectFit: "cover", height: 300, width: "100%" }} />
    </figure>
  );
};

export default ImagesSlider;
