import React from "react";

const TextInput = ({
  label,
  className = "",
  classNameLabel = "",
  placeholder,
  type,
  id,
  isValid,
  ...props
}) => {
  let inputStyle = {};

  if (isValid === "") {
    inputStyle = { border: "1px solid transparent" };
  } else if (isValid === "error") {
    inputStyle = { border: "1px solid #e53e3e" };
  } else if (isValid === "success") {
    inputStyle = { border: "1px solid rgb(34 197 94)" };
  }

  return (
    <>
      {label && (
        <label
          htmlFor={id ?? "text-input-1"}
          className={`block mb-2 text-sm font-medium text-gray-900 ${classNameLabel}`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        {isValid === "success" && (
          <div className="absolute inset-y-0 left-0 pl-2 flex items-center text-green-500">
            <svg
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        )}
        {isValid === "error" && (
          <div className="absolute inset-y-0 left-0 pl-2 flex items-center text-red-500">
            <svg
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        )}
        <input
          id={id ?? "text-input-1"}
          type={type ?? "text"}
          className={`my-2 bg-gray-50 border text-center text-gray-900 text-sm rounded-[4px] block w-full p-2.5 focus:outline-none focus:shadow-outline ${className}`}
          placeholder={placeholder ?? "Ingresa tu texto aquí"}
          style={inputStyle}
          {...props}
        />
      </div>
    </>
  );
};

export default TextInput;
