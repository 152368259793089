import React, { useEffect, useState } from 'react';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSheetPayment, setPropertyPlan } from '../../../services/payment.service';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentStep = ({ selectedPlan, propertyId, setStep }) => {
    const { token } = useSelector((state) => state.auth);
    const [paymentIntentSecret, setPaymentIntentSecret] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(''); // 'success', 'error', ''
    const [paymentType, setPaymentType] = useState(''); // 'payment' or 'setup'
    const navigate = useNavigate();

    useEffect(() => {
        const initializePayment = async () => {
            try {
                const { paymentIntent, setupIntent } = await getSheetPayment(
                    { plan_id: selectedPlan.id, propertyId: propertyId },
                    token
                );

                if (setupIntent) {
                    setPaymentIntentSecret(setupIntent);
                    setPaymentType('setup');
                } else if (paymentIntent) {
                    setPaymentIntentSecret(paymentIntent);
                    setPaymentType('payment');
                }
                setIsLoading(false);
            } catch (error) {
                console.error("Error initializing payment:", error);
                setIsLoading(false);
            }
        };

        if (selectedPlan) {
            initializePayment();
        }
    }, [selectedPlan, token]);

    const handleSetPropertyPlan = async () => {
        setIsLoading(true);
        const response = await setPropertyPlan({ planId: selectedPlan.id, propertyId: propertyId }, token);
        if(response){
            handlePaymentSuccess();
        }
        else{
            setPaymentStatus('error');
        }
        setIsLoading(false);
    }   

    const handlePaymentSuccess = () => {
        navigate('/admin/dashboard/');
        setPaymentStatus('success');
    };


    if(!selectedPlan.requires_payment_method){
        return <>
            <div className='w-full h-full flex flex-col justify-center items-center'>
            <h1 className='text-[#3E11B5] text-2xl font-semibold mb-6'>Completar Pago</h1>
            <br />
            <h3 className="text-gray-600 mb-6 text-center">
                El plan seleccionado no requiere pago.
            </h3>
            <button className='text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-6 py-3 rounded-full mt-6 w-full' 
                onClick={handleSetPropertyPlan} disabled={isLoading}>
                {isLoading ? 'Activando...' : 'Activar Propiedad'}
            </button>
            </div>
        </>;
    }

    return (
        <>
            {isLoading ? (
                <p>Cargando...</p>
            ) : paymentIntentSecret ? (
                <div>
                    <Elements stripe={stripePromise} options={{ clientSecret: paymentIntentSecret }}>
                        <StripeForm
                            onPaymentSuccess={handlePaymentSuccess}
                            setPaymentStatus={setPaymentStatus}
                            paymentType={paymentType}
                            selectedPlan={selectedPlan}
                        />
                    </Elements>
                    <div className='w-full mt-6 flex justify-between'>
                        <button
                            type="button"
                            className="text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                            onClick={() => setStep(prevStep => prevStep - 1)}
                        >
                            Regresar
                        </button>
                    </div>
                </div>
            ) : (
                <p>Error al cargar el formulario de pago. Por favor intenta nuevamente.</p>
            )}
        </>
    );
};

const StripeForm = ({ onPaymentSuccess, setPaymentStatus, paymentType, selectedPlan }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setProcessing(true);
        setError(null);

        if (!stripe || !elements) {
            setError("Stripe o elementos no cargados.");
            setProcessing(false);
            return;
        }

        try {
            let payload;

            if (paymentType === 'setup') {
                payload = await stripe.confirmSetup({
                    elements,
                    confirmParams: {
                        return_url: `${window.location.origin}/admin/dashboard`,
                    },
                });
            } else {
                payload = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: `${window.location.origin}/admin/dashboard`,
                    },
                });
            }

            if (payload.error) {
                setError(`Error: ${payload.error.message}`);
                setPaymentStatus('error');
            } else {
                setSucceeded(true);
                onPaymentSuccess();
                setPaymentStatus('success');
            }
        } catch (err) {
            console.error("Error confirmando el pago:", err);
            setError("Error al confirmar el pago. Por favor intenta nuevamente.");
            setPaymentStatus('error');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full max-w-lg mx-auto">
            <h3 className="text-[#3E11B5] text-2xl font-semibold mb-6">Completar Pago</h3>
            <p className="text-gray-600 mb-6 text-center">
                Ingresa los detalles de tu pago para completar la transacción.
            </p>
            <div className="w-full">
                <PaymentElement className="mb-4" />
                <button
                    disabled={processing || succeeded || !stripe}
                    className="text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-6 py-3 rounded-full mt-6 w-full"
                    id="submit"
                >
                    {processing ? "Cargando..." : succeeded ? "Completado" : `Pagar $${selectedPlan.price_value} MXN`}
                </button>
                {error && (
                    <div className="text-red-600 mt-4" role="alert">
                        {error}
                    </div>
                )}
            </div>
        </form>
    );
};

export default PaymentStep;
