const Delete = ({ show, onClose, onConfirm }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-6 md:mx-8 lg:mx-12 p-6">
                <h2 className="text-lg font-semibold mb-4">¿Deseas eliminar la propiedad?</h2>
                <p className="mb-4">
                    Si decides continuar y tienes un plan actual activo en el que se muestra tu propiedad, ésta en automático se dejará de mostrar.
                </p>
                <p className="mb-6">
                    Así mismo, tus datos no serán almacenados en nuestros servidores.
                </p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={onConfirm}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Delete;
