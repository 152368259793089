import { motion } from 'framer-motion';
import React from 'react';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/header/Navbar';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5
        }
    }
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        }
    }
};

const AvisoPrivacidad = () => {


    return (
        <>
            <Navbar />
            <div className="bg-gray-100 py-[2rem] text-gray-900">
                <div className="bg-white rounded-lg container mx-auto text-gray-900">
                    <div className="container mx-auto py-12 p-6 lg:px-[6rem] xl:px-[15rem]">
                        <h1 className="text-3xl font-bold text-center mb-6">Aviso de privacidad</h1>
                        <motion.div
                            className="space-y-6"
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            <motion.section variants={itemVariants}>
                                <>
                                    <h1
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        SWMITH, S.A.P.I. DE C.V.{" "}
                                        <span className="p">(en lo sucesivo mejor conocido como “</span>Yo Rento
                                        <span className="p">” o el “</span>Responsable
                                        <a href="http://www.yorento.com.mx/" className="s1" target="_blank">
                                            ”), con domicilio en calle Avenida Américas #1930, piso 2, interior 7,
                                            colonia Country Club, en la ciudad Guadalajara, Jalisco, México, C.P.
                                            44610, y portal de internet{" "}
                                        </a>
                                        <span className="p">
                                            www.yorento.com.mx, es el responsable del uso y protección de sus datos
                                            personales, y al respecto le informamos lo siguiente:
                                        </span>
                                    </h1>
                                    <h1 style={{ paddingTop: "7pt", textIndent: "0pt", textAlign: "center" }}>
                                        DATOS PERSONALES
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Para llevar a cabo las finalidades descritas en el presente aviso de
                                        privacidad, utilizaremos los siguientes datos personales:
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Datos de identificación
                                    </h1>
                                    <ul id="l1">
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Nombre
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Estado Civil
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Registro Federal de Contribuyentes(RFC)
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Número de Afiliación al Instituto Mexicano del Seguro Social
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Clave única de Registro de Población (CURP)
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Lugar de nacimiento
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Fecha de nacimiento
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Nacionalidad y, en su caso, información migratoria
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Edad
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Firma autógrafa
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Firma electrónica
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Imagen y/o fotografía del rostro.
                                            </p>
                                            <h1
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Datos de contacto
                                            </h1>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Domicilio
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Teléfono particular
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Teléfono celular
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Correo electrónico
                                            </p>
                                            <h1
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Datos laborales o profesionales
                                            </h1>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Puesto o cargo que desempeña
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Domicilio de trabajo
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Correo electrónico institucional
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Teléfono institucional
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Referencias laborales
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Trayectoria educativa
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Títulos profesionales
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Cédula profesional
                                            </p>
                                            <h1
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                Información patrimonial
                                            </h1>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Bienes muebles
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Bienes inmuebles
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Información fiscal
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Historial crediticio
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Ingresos
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Egresos
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Cuentas bancarias
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Número de tarjetas de crédito
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Seguros
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Afores
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Información sobre comportamiento crediticio
                                            </p>
                                        </li>
                                    </ul>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Los datos personales descritos con anterioridad, se recolectan mediante: i)
                                        el suministro voluntario y directo de tu información a través de nuestros
                                        formatos impreso, digitalmente a través de formularios en nuestro sitio web
                                        o vía correo electrónico; ii) el suministro voluntario de tu información y
                                        tus datos personales en entrevistas presenciales o telefónicas con nuestro
                                        personal autorizado; y iii) la utilización de fuentes de acceso público,
                                        como las redes sociales, y de otras fuentes públicas disponibles en el
                                        mercado.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        De igual manera, los datos personales proporcionados pueden constar en
                                        información que puede ser solicitada a los titulares, dependiendo el proceso
                                        o trámite que se realice ante <b>Yo Rento</b>, los cuales de manera
                                        enunciativa más no limitativa los documentos serán:
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        i) Comprobantes de domicilio; ii) Identificaciones oficiales (credencial de
                                        elector, pasaporte, cédula profesional); iii) Estados de Cuenta; iv)
                                        declaraciones fiscales; v) comprobantes de ingresos (recibos de nómina,
                                        estados de cuenta, recibos de honorarios, comprobantes fiscales); vi)
                                        comprobantes de estudios y grados académicos, así como cédulas
                                        profesionales; v) Comprobantes de pago de obligaciones mercantiles,
                                        fiscales, seguridad social, etc. Contratos, convenios y documentos
                                        relacionados; y vi) Escrituras, pólizas y actas otorgadas ante fedatarios
                                        públicos.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El Titular manifiesta que los datos personales proporcionados al Responsable
                                        son veraces y actualizados, así mismo se obliga a comunicar al Responsable
                                        cualquier modificación a los datos personales proporcionados con la mayor
                                        brevedad posible.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Los datos personales que proporcione se conservarán o destruirán según se
                                        cumplan las finalidades de <b>Yo Rento </b>y sujetos a los términos
                                        señalados en el presente Aviso, así como para el cumplimiento de la
                                        normatividad aplicable, ya sea en materia de protección de datos personales,
                                        mercantil, administrativa y/o fiscal vigente.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        DATOS PERSONALES SENSIBLES
                                    </h1>
                                    <h1
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            reconoce que ciertos datos sometidos a tratamiento revisten o pueden
                                            revestir la calidad de Datos Personales Sensibles, por lo que{" "}
                                        </span>
                                        Yo Rento{" "}
                                        <span className="p">
                                            almacenará los datos personales y los datos personales Sensibles mediante
                                            sistemas manuales o electrónicos con acceso limitado, con el objetivo de
                                            protegerlos contra pérdidas, uso fraudulento, accesos no autorizados,
                                            revelación, modificación o destrucción.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Asimismo, le informamos que en <b>Yo Rento </b>no recabamos ni tratamos
                                        Datos Personales Sensibles como origen racial o étnico, estado de salud,
                                        información genética, creencias religiosas, filosóficas y morales,
                                        afiliación sindical, opiniones políticas o preferencia sexual.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        FINALIDADES Y/O USOS DE LOS DATOS PERSONALES
                                    </h1>
                                    <h1
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            recopila sus datos personales con el propósito (finalidades primarias) de:
                                        </span>
                                    </h1>
                                    <ul id="l2">
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "9pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Registrar su usuario en la plataforma <b>Yo Rento</b>;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Brindar atención al usuario;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "106%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Proveer los servicios y productos de <b>Yo Rento </b>requeridos por
                                                Usted, para hacer cumplir y/o ejecutar sus necesidades;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Gestionar citas con los representantes de venta;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Integrar expedientes, mantener bases de datos actualizadas y dar
                                                tratamiento a los mismos, ya sea directamente o a través de un tercero;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Realizar procedimientos de investigación;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Realizar búsquedas de información en fuentes de acceso públicas sobre
                                                antecedentes legales;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Realizar búsquedas de información ante sociedades de información
                                                crediticia;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Realizar búsquedas de información y antecedentes en motores de búsqueda,
                                                redes sociales y otras fuentes de información disponibles en internet;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Verificar las referencias profesionales y personales con terceros;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Generar perfiles de riesgo y recomendaciones;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "106%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Facilitar la celebración de contratos de arrendamiento y brindar
                                                servicios de asesoría en materia inmobiliaria;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "106%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Gestionar la cobranza mediante el uso de medios telefónicos, impresos,
                                                electrónicos y presenciales;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Procesar pagos a través de los distintos medios de pago señalados en el
                                                sitio web; y
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Mantener resguardos físicos, electrónicos y de procedimiento de la
                                                información personal en términos de la legislación y regulación
                                                aplicable, y cumplir con los requerimientos legales en materia civil,
                                                mercantil, administrativa y fiscal.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                De manera adicional, utilizaremos su información personal para las
                                                siguientes finalidades secundarias que no son necesarias para el
                                                servicio solicitado, pero que nos permiten y facilitan brindarle una
                                                mejor atención:
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Mercadotecnia o publicitaria;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Prospección comercial;
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Gestionar el envío de comunicados con avisos, mensajes, novedades,
                                                invitación a eventos y reuniones; y
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Evaluar la calidad de nuestros productos y servicios.
                                            </p>
                                        </li>
                                    </ul>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        La negativa para el uso de sus datos personales para estas finalidades no
                                        podrá ser un motivo para que le neguemos los servicios y productos que
                                        solicita o contrata con nosotros.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        CONSENTIMIENTO
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Usted en este acto reconoce que al proporcionar sus datos personales de
                                        manera presencial o a través de cualquier medio remoto, incluyendo nuestro
                                        sitio web, el presente aviso de privacidad le ha sido dado a conocer, mismo
                                        que ha leído, entendido y aceptado, por lo cual otorga su consentimiento
                                        para que recabemos, tratemos y, en su caso, transfiramos sus datos
                                        personales, en los términos del presente Aviso.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        TRANSFERENCIA DE DATOS PERSONALES
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Le informamos que sus datos personales pueden ser compartidos dentro y fuera
                                        del país con las siguientes personas, empresas, organizaciones o autoridades
                                        distintas a nosotros, sin requerir su consentimiento, para los siguientes
                                        fines:
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        a) Con Arrendadores, inquilinos y asesores inmobiliarios, a efecto de que
                                        valoren y en su caso celebren el contrato de arrendamiento del inmueble
                                        materia de la operación de que se trate; b) Con terceros proveedores de
                                        servicios legales y administrativos y soluciones tecnológicas, a efecto de
                                        que provean de servicios necesarios para llevar a cabo la operación
                                        contractual entre las partes involucradas en el arrendamiento o nos permiten
                                        brindar un mejor servicio y cumplir con obligaciones legales; c) Con
                                        empresas filiales o afiliadas integrantes del grupo corporativo de “Yo
                                        Rento”, a efecto de brindar apoyo en sus respectivas operaciones y prestar
                                        los servicios contratados; y d) Con autoridades, a efecto de dar
                                        cumplimiento a alguna ley, reglamento o disposición legal aplicable cuando
                                        la transferencia sea obligatoria.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Le informamos que sus datos personales biométricos en ningún momento serán
                                        transferidos a terceros.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN
                                        (ARCO)
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Usted tiene derecho a conocer qué datos personales tenemos de usted, para
                                        qué los utilizamos y las condiciones del uso que les damos (Acceso).
                                        Asimismo, es su derecho solicitar la corrección de su información personal
                                        en caso de que esté desactualizada, sea inexacta o incompleta
                                        (Rectificación); que la eliminemos de nuestros registros o bases de datos
                                        cuando considere que la misma no está siendo utilizada adecuadamente
                                        (Cancelación); así como oponerse al uso de sus datos personales para fines
                                        específicos (Oposición). Estos derechos se conocen como derechos ARCO.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar
                                        la solicitud respectiva a través del siguiente medio: (i) Presencial,
                                        formulando la solicitud en el domicilio de <b>Yo Rento </b>
                                        <a href="mailto:contacto@yorento.com" className="s1" target="_blank">
                                            o mediante correo electrónico dirigido a la dirección
                                            contacto@yorento.com.
                                        </a>
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        La solicitud de ejercicio de derechos ARCO podrá ser formulada por el
                                        Titular de los datos personales o, en su caso, por su representante legal,
                                        para lo cual deberá exhibirse una copia de los documentos que acrediten su
                                        identidad (credencial para votar, pasaporte o cualquier otra identificación
                                        oficial) o, en su caso, los documentos que acrediten la identidad del
                                        Titular y de su representante, así como aquellos que acrediten su
                                        representación legal, cuyo original deberá presentar.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Además, la solicitud deberá contener: a) Una descripción clara y precisa de
                                        los datos personales respecto de los cuales busca ejercer alguno de los
                                        Derechos ARCO; b) Cualquier documento o información que facilite la
                                        localización de sus datos personales; c) En caso de solicitar una
                                        rectificación de sus datos personales, deberá de indicar también, las
                                        modificaciones a realizarse y aportar la documentación que sustente su
                                        petición; y d) Información de contacto.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Yo Rento{" "}
                                        <span className="p">
                                            emitirá una respuesta en un plazo máximo de 20 (veinte) días hábiles para
                                            informar sobre la procedencia de la solicitud de sus derechos ARCO, lo que
                                            se hará de su conocimiento a través del correo electrónico que haya
                                            proporcionado.
                                        </span>
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Usted puede revocar el consentimiento que, en su caso, nos haya otorgado
                                        para el tratamiento de sus datos personales. Sin embargo, es importante que
                                        tenga en cuenta que no en todos los casos podremos atender su solicitud o
                                        concluir el uso de forma inmediata, ya que es posible que por alguna
                                        obligación legal requiramos seguir tratando sus datos personales. Asimismo,
                                        usted deberá considerar que, para ciertos fines, la revocación de su
                                        consentimiento implicará que no le podamos seguir prestando el servicio que
                                        nos solicitó, o la conclusión de su relación con nosotros.
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        INSTANCIA RESPONSABLE
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Para cualquier consulta o petición relacionada con el tratamiento de tus
                                        datos personales, incluyendo su actualización, limitación de su uso o
                                        divulgación, favor de
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        <a href="mailto:contacto@yorento.com" className="s1" target="_blank">
                                            contactar a nuestro equipo responsable en nuestro domicilio o enviando un
                                            correo electrónico a la dirección contacto@yorento.com.
                                        </a>
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        TECNOLOGÍAS DE RAESTREO
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Le informamos que en nuestra página de internet utilizamos cookies, web
                                        beacons u otras tecnologías, a través de las cuales es posible monitorear su
                                        comportamiento como usuario de internet, así como brindarle un mejor
                                        servicio y experiencia al navegar en nuestra página. Los datos personales
                                        que recabamos a través de estas tecnologías, los utilizaremos para los
                                        siguientes fines:
                                    </p>
                                    <ul id="l3">
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "7pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Identificar la forma en la cual usted hace uso de nuestros servicios con
                                                la finalidad de mejorar su rendimiento y futuros desarrollos.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingLeft: "41pt",
                                                    textIndent: "-18pt",
                                                    lineHeight: "107%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Recordar sus preferencias, opciones de navegación y funciones
                                                personalizadas en nuestras aplicaciones.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Permitirle un uso adecuado y operación de nuestras aplicaciones.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Proporcionarle una experiencia personalizada en nuestras aplicaciones.
                                            </p>
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    lineHeight: "108%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Los datos personales que obtenemos de estas tecnologías de rastreo son
                                                los siguientes:
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Identificadores, nombre de usuario y contraseñas de una sesión
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Idioma preferido por el usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Región en la que se encuentra el usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Tipo de navegador del usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Tipo de sistema operativo del usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{ paddingLeft: "41pt", textIndent: "-17pt", textAlign: "left" }}
                                            >
                                                Fecha y hora del inicio y final de una sesión de un usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Páginas web visitadas por un usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Búsquedas realizadas por un usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Publicidad revisada por un usuario
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p
                                                style={{
                                                    paddingTop: "1pt",
                                                    paddingLeft: "41pt",
                                                    textIndent: "-17pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Listas y hábitos de consumo en páginas de compras
                                            </p>
                                        </li>
                                    </ul>
                                    <p
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Se le recuerda que como Titular puede deshabilitar o ajustar el uso de
                                        cookies siguiendo los procedimientos del navegador de internet que utiliza a
                                        través de los siguientes enlaces:
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Microsoft Edge:
                                    </p>
                                    <p
                                        className="s2"
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "166%",
                                            textAlign: "left"
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#0562C1",
                                                fontFamily: "Arial, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "underline",
                                                fontSize: "12pt"
                                            }}
                                        >
                                            View cookies in Microsoft Edge - Microsoft Support
                                        </span>{" "}
                                        <span style={{ color: "#000" }}>Mozilla Firefox:</span>
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "14pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        <a href="http://support.mozilla.com/en-US/kb/Cookies">
                                            http://support.mozilla.com/en-US/kb/Cookies
                                        </a>
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "left"
                                        }}
                                    >
                                        <a
                                            href="http://www.google.com/support/chrome/bin/answer.py?hl=en&amp%3Bamp%3Banswer=95647"
                                            className="s1"
                                            target="_blank"
                                        >
                                            Google Chrome:{" "}
                                        </a>
                                        <span
                                            style={{
                                                color: "#0562C1",
                                                fontFamily: "Arial, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "underline",
                                                fontSize: "12pt"
                                            }}
                                        >
                                            http://www.google.com/support/chrome/bin/answer.py?hl=en&amp;amp;amp;answer=9
                                        </span>
                                        <a
                                            href="http://www.google.com/support/chrome/bin/answer.py?hl=en&amp%3Bamp%3Banswer=95647"
                                            style={{
                                                color: "#0562C1",
                                                fontFamily: "Arial, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "none",
                                                fontSize: "12pt"
                                            }}
                                            target="_blank"
                                        >
                                            {" "}
                                        </a>
                                        <a
                                            href="http://www.google.com/support/chrome/bin/answer.py?hl=en&amp%3Bamp%3Banswer=95647"
                                            target="_blank"
                                        >
                                            5647
                                        </a>
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Safari:
                                    </p>
                                    <p
                                        className="s2"
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "166%",
                                            textAlign: "left"
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#0562C1",
                                                fontFamily: "Arial, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "underline",
                                                fontSize: "12pt"
                                            }}
                                        >
                                            http://support.apple.com/kb/PH5042
                                        </span>{" "}
                                        <span style={{ color: "#000" }}>Opera:</span>
                                    </p>
                                    <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                        <a href="http://www.opera.com/browser/tutorials/security/privacy/">
                                            http://www.opera.com/browser/tutorials/security/privacy/
                                        </a>
                                    </p>
                                    <h1
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        CAMBIOS EN EL AVISO DE PRIVACIDAD
                                    </h1>
                                    <p
                                        style={{
                                            paddingTop: "9pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "108%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        El presente aviso de privacidad puede sufrir modificaciones, cambios o
                                        actualizaciones derivadas de nuevos requerimientos legales; de nuestras
                                        propias necesidades por los productos o servicios que ofrecemos; de nuestras
                                        prácticas de privacidad; de cambios en nuestro modelo de negocio, o por
                                        otras causas, reservándose <b>Yo Rento </b>el derecho, bajo su exclusiva
                                        discreción, de cambiar, modificar, agregar o eliminar partes del mismo en
                                        cualquier momento.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            lineHeight: "109%",
                                            textAlign: "justify"
                                        }}
                                    >
                                        En tal caso, nos comprometemos a mantenerlo informado sobre los cambios que
                                        pueda sufrir el presente aviso de privacidad, a través de nuestro sitio web.
                                    </p>
                                </>

                            </motion.section>
                            {/* Puedes añadir más secciones aquí */}
                        </motion.div>
                    </div>
                </div>
            </div>

            <Footer />

        </>

    );
};

export default AvisoPrivacidad;
