// Card.js
import React from "react";

const Card = ({ image, title, date, description }) => {

  return (
    <div className="w-[24rem] rounded overflow-hidden border border-gray-500">
      <img className="w-full h-48 object-cover" src={image} alt={title} />
      <div className="px-6 py-4">
        <div className="font-semibold text-xl mb-2">{title}</div>
        <p className="text-gray-500 text-1xl mb-2">{date}</p>
        <p className="font-normal text-base">{description}</p>
      </div>
      <div className="flex justify-center items-center px-6 py-4">
        {/* <button className="text-[#3E11B5]  py-2 px-4 rounded" >
          Seguir leyendo
        </button> */}
        <a className="text-[#3E11B5]  py-2 px-4 rounded" href="/admin/soon" target="_blank">Seguir leyendo</a>
      </div>
    </div>
  );
};

export default Card;
