import { faTimes } from "@fortawesome/pro-solid-svg-icons"; // Ícono de cierre
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "../../components/modals/modal";
import { postReportChat } from "../../services/chats.service";

const ReportarModal = ({ isOpen, onClose, chatRoomId, token, onReported }) => {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [error, setError] = useState('');

  const reasons = [
    { id: 'inappropriate', name: 'Contenido inapropiado' },
    { id: 'not_related', name: 'No relacionado con la propiedad' },
    { id: 'hate_speech', name: 'Discurso de odio' },
    { id: 'harassment', name: 'Acoso o intimidación' },
    { id: 'spam', name: 'Spam' },
    { id: 'other', name: 'Otro motivo' },
  ];

  const handleSubmitReport = async (event) => {
    event.preventDefault();

    // Validación: motivo seleccionado
    if (!reason) {
      setError('Por favor, selecciona un motivo para reportar el chat.');
      return;
    }

    // Validación: si se selecciona 'Otro motivo', se debe ingresar una descripción
    if (reason === 'other' && !otherReason.trim()) {
      setError('Por favor, describe el motivo para reportar el chat.');
      return;
    }

    setError(''); // Limpiar mensaje de error si pasa la validación

    const selectedReason = reason === 'other' ? otherReason : reason;

    try {
      const resp = await postReportChat(chatRoomId, selectedReason, token);
      onReported(); // Llama a la función callback después del éxito
      onClose();
    } catch (error) {
      console.error("Error al enviar el reporte:", error);
      setError('Hubo un error al enviar el reporte. Inténtalo de nuevo.');
    }
  };

  if (!isOpen) return null;

  return (
    <Modal>
      <div className="relative p-4">
        {/* Icono para cerrar el modal */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
        </button>

        <form onSubmit={handleSubmitReport}>
          <h2 className="text-lg font-medium mb-4 text-gray-900">Reportar Chat</h2>

          {/* Mostrar mensaje de error */}
          {error && (
            <div className="mb-4 text-red-600 text-sm">
              {error}
            </div>
          )}

          <fieldset className="mt-2">
            <legend className="text-sm font-semibold mb-2 text-gray-700">Motivo del reporte</legend>
            <div className="space-y-2">
              {reasons.map(({ id, name }) => (
                <div key={id} className="flex items-center">
                  <input
                    id={id}
                    type="radio"
                    name="report_reason"
                    value={id}
                    checked={reason === id}
                    onChange={() => setReason(id)}
                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <label htmlFor={id} className="ml-2 text-sm text-gray-700">
                    {name}
                  </label>
                </div>
              ))}
            </div>

            {/* Input de texto si selecciona 'Otro motivo' */}
            {reason === 'other' && (
              <div className="mt-4">
                <label htmlFor="other_reason" className="block text-sm font-medium text-gray-700">
                  Escribe el motivo
                </label>
                <input
                  type="text"
                  id="other_reason"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  className="mt-1 p-2 border border-gray-100 block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Describe el motivo"
                />
              </div>
            )}
          </fieldset>

          <button
            type="submit"
            className="mt-6 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Enviar Reporte
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ReportarModal;
