import { faAddressCard } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyDocuments from "../assets/drawe-documents.png";
import PdfIcon from "../assets/pdf.png";
import RootLayout from "../components/Layout";
import LayoutHome from "../components/LayoutHome";
import Button from "../components/form/button";
import Radio from "../components/form/radio";
import { getMeDocuments, getMeUserInfo } from "../services/auth.service";

const Documents = (props) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const getDocuments = async () => {
    try {
      setLoading(true);
      const resp = await getMeDocuments(props.token);
      if (resp.data) {
        setDocuments(resp.data.documents);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMyInfo = async () => {
    try {
      setLoading(true);
      const resp = await getMeUserInfo(props.token);
      if (resp.data) {
        setUserInfo(resp.data.user);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyInfo();
    getDocuments();
  }, []);

  return (
    <RootLayout admin>
      <LayoutHome token={props.token}>
        <div className="px-4 lg:px-8 py-6">
          <h1 className="text-[20px] text-[#3E11B5] text-center font-semibold mb-4">Documentos añadidos</h1>
          <div className="bg-white rounded-lg border border-gray-300 p-4">
            <div className="flex justify-between items-center mb-4">
              <Button
                disabled={loading}
                onClick={() =>
                  userInfo.investigation_requested
                    ? navigate("/admin/documents-upload")
                    : navigate("/admin/documents/edit")}
                className="bg-transparent border border-blue-500 text-blue-500 px-4 py-2 text-sm hover:bg-blue-500 hover:text-white transition-all duration-300"
              >
                Editar documentos
              </Button>
            </div>
            {documents.length > 0
              ? (
                <>
                  <div className="flex items-center bg-gray-100 p-4 rounded-lg mb-4">
                    <FontAwesomeIcon icon={faAddressCard} className="text-2xl text-blue-500 mr-2" />
                    <p className="text-base text-gray-800">
                      Te has identificado como arrendador(a)
                    </p>
                  </div>
                  {documents.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-md mb-4 p-4"
                    >
                      <div className="flex items-center mb-4">
                        <Radio checked className="mr-2" />
                        <p className="text-lg font-semibold">{item.name}</p>
                      </div>
                      <div className="mb-4">
                        {String(item.url).includes("pdf")
                          ? (
                            <img
                              src={PdfIcon}
                              alt="PDF document"
                              className="h-48 w-auto mx-auto"
                            />
                          )
                          : (
                            <img
                              src={item.url}
                              alt="Image document"
                              className="h-48 w-full object-contain"
                            />
                          )}
                      </div>
                    </div>
                  ))}
                </>
              )
              : (
                <div className="flex flex-col items-center justify-center my-8">
                  <img
                    src={EmptyDocuments}
                    alt="Empty documents"
                    className="h-40 mb-4"
                  />
                  <h3 className="text-xl font-semibold text-blue-500 text-center">
                    Aún no tienes documentos añadidos
                  </h3>
                </div>
              )}
          </div>
        </div>
      </LayoutHome>
    </RootLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(Documents);
