const Radio = (props) => {
    return (
        <div className="flex items-center mb-4">
            <input 
                id={props.value + "-radio"} 
                type="radio" 
                {...props}
                className="hidden" // Oculta el círculo predeterminado
            />
            <label 
                htmlFor={props.value + "-radio"} 
                className="relative flex items-center cursor-pointer"
            >
                <div className={`w-6 h-6 rounded-full mr-2 focus:ring-#4C44ED accent-[#3E11B5] ${props.checked ? 'border-0' : 'border border-purple-500'}`}>
                    {props.checked && (
                        <svg 
                            width="24" 
                            height="24" 
                            viewBox="0 0 22 22" 
                            fill="#4C44ED" 
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-full h-full"
                        >
                            <path d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z" />
                        </svg>
                    )}
                </div>
                <span className="text-sm font-medium text-gray-900">
                    <span className="ml-2">{props.label}</span>
                </span>
            </label>
        </div>
    );
};

export default Radio;
