import axios from "./index";

export const queryProperties = (query) => {
  return axios.get(`/app/operation/properties${query}`);
};

export const getPropertiesHighlighted = (payload, token, cancelToken = null) => {
  return axios.get(`/app/operation/highlighted-properties`, {
    headers: { Authorization: `Bearer ${token}` },
    params: payload,
    cancelToken,
  });
};

export const getProperties = (payload, token = null, cancelToken = null) => {
  console.log(payload);
  console.log(token);
  console.log(cancelToken);

  return axios.get(`/app/operation/properties/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: payload,
    cancelToken,
  });
};

export const getPropertie = (id, token = null, value=false) => {
  return axios.get(`/app/operation/properties/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getMeProperties = async (query, token = null) => {
  return await axios.get(`/app/operation/my-properties?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getLikedProperties = async (query, token = null) => {
  return await axios.get(`/app/operation/me-properties?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getMePublishProperties = async (token = null) => {
  return await axios.get(`/app/operation/my-properties`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getTypesProperties = async (token = null) => {
  return axios.get("/app/operation/property-types", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getTypesAmenities = async (token = null) => {
  return axios.get("/app/operation/amenities", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getRentsDurations = async (token = null) => {
  return axios.get("/app/operation/rent-durations", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getBest = async (token = null) => {
  return axios.get("/app/operation/best-amenities", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getOwnersHistory = async (query, token = null) => {
  return axios.get(`/app/core/users/rent-history?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
/*
{
    "title": "Depa sencillo y bien ubicado en el mejor vecindario",
    "description": "Departamento de estilo sobrio y apto para 2 personas máximo. Se acepta 1 mascota pequeña.",
    "property_type_id": 1,
    "rooms": 2,
    "amenities": [
        {"id": 2, "quantity": 2},
        {"id": 5, "quantity": 1}
    ],
    "location": {
        "latitude": 20.666927,
        "longitude": -103.333734
    },
    "monthly_rent": 5000
}
*/
export const createPropertie = (data, token = null) => {
  return axios.post("/app/operation/properties", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateOrderPhoto = (id, data, token = null) => {
  return axios.put(`/app/operation/properties/${id}/front-page`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updatePropertie = (id, data, token = null) => {
  return axios.put(`/app/operation/properties/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updatePropertieImages = (id, data, token = null) => {
  return axios.put(`/app/operation/properties/${id}/images`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const propertieMatch = (id, token=null, liked=false) => {
  return axios.post(
    `/app/operation/properties/${id}/matches`,
    { liked },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const updatePropertieStatus = (id, data, token = null) => {
  return axios.put(`/app/operation/properties/${id}/status`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPropertieStatus = async (id, token = null) => {
  return axios.get(`/app/chats/properties/${id}/status`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deletePropertie = async (id, token = null) => {
  return axios.delete(`/app/operation/properties/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteLikePropertie = async (id, token = null) => {
  return axios.delete(`/app/operation/properties/${id}/matches`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const publishedPropertieAvailable = (token = null) => {
  console.log(token);
  return axios.get(`/app/operation/publication-available`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const uploadCsv = (data, token = null) => {
  return axios.post(`/app/operation/csv/properties`, data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
}

export const postEvaluateRenter = (data, token = null) => {
  return axios.post("/app/core/score/renter", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const uploadDocuments = (data, token = null) => {
  return axios.post("/app/operation/documents", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deletePropertieImage = (property_id, image_id, token = null) => {
  return axios.delete(`/app/operation/properties/${property_id}/images/${image_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPropertyDetails = (property_id, token = null) => {
  return axios.get(`/app/operation/properties/${property_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPropertyPublicationStatus = (property_id, token = null) => {
  return axios.get(`/app/operation/properties/${property_id}/publications`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const publishProperty = (property_id, token = null) => {
  return axios.post(`/app/operation/properties/${property_id}/publications`, {}, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const unpublishProperty = (property_id, token = null) => {
  return axios.delete(`/app/operation/properties/${property_id}/publications`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getCandidatosRentar = (property_id, token, limit = 10, skip = 0) => {
  return axios.get(`/app/chats/properties/${property_id}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      skip,
    },
  });
};

export const getUserPassport = (user_id, token = null) => {
  return axios.get(`/app/core/passport/${user_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};