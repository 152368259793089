import React from "react";
import Navbar from "./Navbar";

const Header = ({ admin }) => {
  return (
    <div>
      {/* Main header (navbar) */}
      <Navbar admin={admin} />
    </div>
  );
};
export default Header;
