import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth-action";
import Loading from "./Loading";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    localStorage.removeItem('userData');
    localStorage.removeItem('token-yo-rento');
  }, [dispatch]);

  setTimeout(() => {
    window.location.href = '/';
  }, 1000);

  return <Loading />;

};

export default Logout;