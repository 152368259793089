import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Dropdown = ({
  name,
  icon,
  dropdownItems,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div className="relative dropdown dropdown-hover rounded-md">
      <label
        tabIndex={0}
        className="cursor-pointer"
        onClick={() => setActive(!active)}
      >
        {name}
        {"     "}
        {icon && <FontAwesomeIcon icon={icon} />}
      </label>
      <div
        className={`dropdown-menu absolute ${
          active ? "" : "hidden"
        } h-auto z-99 menu shadow bg-base-100 rounded-md w-52`}
      >
        {dropdownItems.map(({ name, onclick, icon }, index) => (
          <div
            key={index}
            className="bg-[#FFF] flex items-center text-black cursor-pointer border-b border-b-gray-200 hover:bg-[#F0F7FA] px-3 py-2 gap-x-3"
          >
            {icon}
            <div
              className=""
              onClick={() => {
                onclick();
                setActive(false);
              }}
            >
              {name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dropdown;
