import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { getOwnersHistory } from "../../services/properties.service";
import EmptyOwners from "../../assets/drawe-search.png";
import Pagination from '../../utils/Pagination';
import LessorEvaluation from "../../components/evaluations/LessorEvaluation";

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const EvaluateLandlord = ({ isOpen, setIsOpen }) => {
    const token = localStorage.getItem('token-yo-rento');
    const [owners, setOwners] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalOwners, setTotalOwners] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [activeOwnerId, setActiveOwnerId] = useState(null);

    const closeModal = () => {
        setActiveOwnerId(null);
    };

    const handleRate = (id) => {
        setActiveOwnerId(id);
    };

    const EvalOwner = ({ item, onRate }) => {
        const { id, property, owner } = item;

        return (
            <motion.div
                className="w-full p-4 flex flex-col lg:flex-row justify-between items-center gap-4 my-4 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200"
            >
                <div className="w-full flex flex-col lg:flex-row gap-4 max-w-full lg:max-w-[85%]">
                    <motion.div
                        className="relative w-full max-w-full lg:max-w-[190px] overflow-hidden rounded-xl shadow-sm"
                        whileHover={{ scale: 1.02 }}
                    >
                        <img
                            src={property?.image || ''}
                            alt="owner"
                            className="w-full h-36 object-cover rounded-xl"
                        />
                    </motion.div>
                    <div>
                        <h3 className="text-lg font-semibold">{property.title}</h3>
                        <span className="text-gray-500 text-sm block mb-1">{property.address}</span>
                    </div>
                </div>
                <div className="flex flex-col items-center mt-4 lg:mt-0">
                    <motion.button
                        className="bg-yellow-500 text-white py-2 px-4 rounded-xl hover:bg-yellow-600 transition-colors duration-200"
                        onClick={(e) => {
                            e.stopPropagation();
                            onRate(id);
                        }}
                    >
                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                        Calificar
                    </motion.button>
                </div>
                {activeOwnerId === id && (
                    <LessorEvaluation
                        rentHistoryId={id}
                        property={property}
                        token={token}
                        lessor={owner}
                        show={true}
                        onClose={closeModal}
                    />
                )}
            </motion.div>
        );
    };

    const getOwners = async () => {
        try {
            setIsLoading(true);
            const response = await getOwnersHistory(`limit=3&skip=${(currentPage - 1) * 3}`, token);
            if (response.data) {
                setTotalOwners(response.data.total);
                setOwners(response.data.rentHistory);
            }
        } catch (error) {
            console.error("Error al obtener los propietarios", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getOwners();
    }, [currentPage]);

    return (
        <div>
            <h1 className="text-[20px] text-purple-800 text-center font-semibold">Inmuebles guardados</h1>
            <div className="w-full min-h-[40vh] rounded-[4px] mt-[10px]">
                <AnimatePresence mode="wait">
                    {owners.length > 0 ? (
                        <motion.div
                            key={currentPage}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="">
                                {owners.map((item) => (
                                    <EvalOwner
                                        item={item}
                                        key={item.id}
                                        onRate={handleRate}
                                    />
                                ))}
                            </div>
                            <div className="p-4 flex items-end justify-end">
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalOwners}
                                    pageSize={3}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="empty"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                            className="w-full min-h-full my-[24px] flex flex-col justify-center items-center"
                        >
                            <img
                                src={EmptyOwners}
                                alt="empty properties"
                                className="max-w-full h-auto"
                            />
                            <h3 className="font-semibold text-[#4C44ED] text-center py-8">
                                Aún no tienes <br /> arrendadores que evaluar.
                            </h3>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default EvaluateLandlord;
