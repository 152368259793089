import { faComment, faCommentXmark, faCrown, faThumbsDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmptyProperties from '../../assets/drawe-like-unauth.png';
import { getMeUserInfo } from '../../services/auth.service';
import { sentRequestChat } from '../../services/chats.service';
import { deleteLikePropertie, getLikedProperties } from '../../services/properties.service';
import Pagination from '../../utils/Pagination';
import Photo from '../../components/modals/modal-photo';
import Spinner from '../../components/Loader/Spinner';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const MyLikes = (props) => {
    const navigate = useNavigate();
    const [properties, setProperties] = useState([]);
    const [requestChat, setRequestChat] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProperties, setTotalProperties] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
    const [idToChildren, setIdToChildren] = useState(0);

    const getProperties = async () => {
        try {
            setIsLoading(true);
            const resp = await getLikedProperties(`limit=3&skip=${(currentPage - 1) * 3}`, props.token);
            if (resp.data) {
                setTotalProperties(resp.data.total);
                setProperties(resp.data.properties);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmitDeletePropertie = async (propertyId) => {
        try {
            await deleteLikePropertie(propertyId, props.token);
            // Filtramos la propiedad eliminada para evitar recarga
            setProperties((prevProperties) => prevProperties.filter((item) => item.id !== propertyId));
        } catch (e) {
            console.log(e);
        }
    };

    const onVerifySelfie = async (id) => {
        try {
            setIsLoading(true)
            const resp = await getMeUserInfo(props.token);
            if (resp.data?.user?.profile_picture !== null) {
                await sentRequestChat(id, props.token);
                setRequestChat(null);
                getProperties();
            }
            else {
                setIsPhotoModalOpen(true);
                setIdToChildren(property_id);
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (props.token) getProperties();
    }, [props.token, currentPage]);

    const handleNavigateToDetail = (id) => {
        navigate(`/propiedad/${id}/detalle`);
    };

    const handleCerrar = async () => {
        setIsPhotoModalOpen(false);
    };

    const PropertieLike = ({ item, onDelete, setRequestChat }) => {
        return (
            <motion.div
                className="w-full p-4 flex flex-col lg:flex-row justify-between items-center gap-4 my-4 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200"
                onClick={() => handleNavigateToDetail(item.property_id)}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeUpVariants}
                transition={{ duration: 0.5 }}
            >
                <div className="w-full flex flex-col lg:flex-row gap-4 max-w-full lg:max-w-[85%]">
                    <motion.div
                        className="relative w-full max-w-full lg:max-w-[190px] overflow-hidden rounded-xl shadow-sm"
                        whileHover={{ scale: 1.02 }}
                    >
                        {item.is_highlighted && (
                            <FontAwesomeIcon icon={faCrown} className="absolute top-2 left-2 text-yellow-500" size="2x" />
                        )}
                        <img
                            src={item?.images ? item?.images[0].image : ''}
                            alt="property background"
                            className="w-full h-36 object-cover rounded-xl"
                        />
                    </motion.div>
                    <div className="flex flex-col items-start justify-between w-full">
                        {item.is_highlighted && (
                            <span className="text-yellow-500 font-bold">Propiedad destacada</span>
                        )}
                        <div>
                            <h3 className="text-lg font-semibold">{item.title}</h3>
                            <span className="text-gray-500 text-sm block mb-1 line-clamp-1">{item.address}</span>
                        </div>
                        <div
                            className="flex items-center gap-2 mt-3 bg-red-100 py-1 px-2 rounded-xl cursor-pointer hover:bg-red-200 transition-colors duration-200"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(item.id);
                            }}
                        >
                            <FontAwesomeIcon icon={faThumbsDown} className="text-red-600" />
                            <span className="text-red-600">Ya no me gusta</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center mt-4 lg:mt-0">
                    {item.chat_status === 'requested' ? (
                        <motion.div className="flex flex-col items-center" onClick={(e) => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faComment} color="#FF8A00" size="2x" />
                            <span className="text-xs mt-1 text-orange-500">En espera</span>
                        </motion.div>
                    ) : item.chat_status === 'accepted' ? (
                        <motion.div className="flex flex-col items-center" onClick={(e) => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faComment} color="#1DCA01" size="2x" />
                            <span className="text-xs mt-1 text-green-500">Aprobado</span>
                        </motion.div>
                    ) : item.chat_status === 'rejected' ? (
                        <motion.div className="flex flex-col items-center" onClick={(e) => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faCommentXmark} color="#DF2B2B" size="2x" />
                            <span className="text-xs mt-1 text-red-500 text-center">Rechazado</span>
                        </motion.div>
                    ) : (
                        <motion.div className="flex flex-col items-center cursor-pointer" onClick={(e) => {
                            e.stopPropagation();
                            setRequestChat(item.property_id);

                        }}>
                            <FontAwesomeIcon icon={faComment} color="#FFC800" size="2x" />
                            <span className="text-xs mt-1 text-yellow-500">Chatear</span>
                        </motion.div>
                    )}
                </div>
            </motion.div>
        );
    };

    return (
        <>
            <h1 className="text-[20px] text-purple-800 text-center font-semibold">Inmuebles guardados</h1>
            <div className="w-full min-h-[40vh] rounded-[4px] mt-[10px]">
                <AnimatePresence mode="wait">
                    {properties.length > 0 ? (
                        <motion.div
                            key={currentPage}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="">
                                {properties.map((item, index) => (
                                    <PropertieLike
                                        item={item}
                                        index={index}
                                        key={index}
                                        setRequestChat={onVerifySelfie}
                                        onDelete={(id) => onSubmitDeletePropertie(id)}
                                    />
                                ))}
                            </div>
                            <div className="p-4 flex items-end justify-end">
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalProperties}
                                    pageSize={3}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="empty"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                            className="w-full min-h-full my-[24px] flex flex-col justify-center items-center"
                        >
                            <img
                                src={EmptyProperties}
                                alt="empty properties"
                                className="max-w-full h-auto"
                            />
                            <h3 className="font-semibold text-[#4C44ED] text-center py-8">
                                Aún no tienes <br /> propiedades con like
                            </h3>
                        </motion.div>
                    )}
                </AnimatePresence>
                <Photo isOpen={isPhotoModalOpen} onClose={handleCerrar} id={idToChildren} onSuccess={() => { sentRequestChat(idToChildren, props.token); getProperties(); }} />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData: state.auth.userData,
});

export default connect(mapStateToProps)(MyLikes);