import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import RootLayout from '../components/Layout'

import { connect } from 'react-redux'
import LayoutHome from '../components/LayoutHome'
import { propertieMatch } from '../services/properties.service'
import PublishedProperties from './properties/PublishedProperties'

const MainProperties = (props) => {
  const navigate = useNavigate() // Usa useNavigate para navegación en v6

  const saveLikeToPropertie = async (id) => {
    try {
      await propertieMatch(id, props.token, true)
      localStorage.removeItem('PENDING_LIKE')
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('PENDING_LIKE')) {
      saveLikeToPropertie(localStorage.getItem('PENDING_LIKE'))
    }
  }, [])

  return (
    <RootLayout admin>
      <LayoutHome token={props.token}>
        <PublishedProperties />
      </LayoutHome>
    </RootLayout>
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user
})

export default connect(mapStateToProps)(MainProperties)
