import axios from "axios";
import { useEffect, useState } from "react";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../Loader/Spinner";

const StatsModal = ({show, property, onCloseStats}) =>{
        if (!show) return null;

        const token = localStorage.getItem('token-yo-rento');
        const [statsInfo, setStatsInfo] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        
        useEffect(() => {
            const fetchStatsData = async () => {
                setIsLoading(true);
                try{
                    const url = `/app/operation/properties/${property.id}/statistics`
                    const response = await axios.get(url, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    if(response.status === 200 ){
                        setStatsInfo(response.data.statistics);
                        console.log(statsInfo);
                    }
                    
                }
                catch(error){
                    console.log("Error al obtener las estadisticas de propiedad", error);
                }
                finally{
                    setIsLoading(false);
                }
            }

            fetchStatsData();
        }, [])

        return ( !isLoading ? 
            (<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 p-4">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-6 md:mx-8 lg:mx-12 p-6">
                    <h2 className="text-lg font-semibold mb-4 text-center">
                        Estadísticas {property.title}
                    </h2>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="bg-purple-800 text-white p-4 h-32 rounded-lg relative flex flex-col justify-between">
                            <div className="text-right">
                                <p className="text-xl font-semibold">Clicks en las fotos</p>
                                <p className="text-2xl">{statsInfo.imageClicks}</p>
                            </div>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-2 text-3xl"/>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-10 text-3xl"/>
                        </div>
                        <div className="bg-purple-800 text-white p-4 h-32 rounded-lg relative flex flex-col justify-between">
                            <div className="text-right">
                                <p className="text-xl font-semibold">Likes Recibidos</p>
                                <p className="text-2xl">{statsInfo.likes}</p>
                            </div>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-2 text-3xl"/>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-10 text-3xl"/>
                        </div>
                        <div className="bg-purple-800 text-white p-4 h-32 rounded-lg relative flex flex-col justify-between">
                            <div className="text-right">
                                <p className="text-xl font-semibold">Vistas</p>
                                <p className="text-2xl">{statsInfo.views}</p>
                            </div>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-2 text-3xl"/>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-10 text-3xl"/>
                        </div>
                        <div className="bg-purple-800 text-white p-4 h-32 rounded-lg relative flex flex-col justify-between">
                            <div className="text-right">
                                <p className="text-xl font-semibold">Chats</p>
                                <p className="text-2xl">{statsInfo.chats}</p>
                            </div>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-2 text-3xl"/>
                            <FontAwesomeIcon icon={faChartSimple} className="absolute bottom-2 left-10 text-3xl"/>
                        </div>
                    </div>
                    <div className="flex justify-end space-x-4 mt-6">
                        <button
                            onClick={onCloseStats}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>) : 
            (<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 p-4">
            <Spinner/>
            </div>
            )
            
        );        
        
    };

export default StatsModal;