import React from "react";
import GaugeChart from 'react-gauge-chart';

function ScoreIndicator({ value, maxValue }) {
    const percent = (value / maxValue);
    const gaugeData = [
        { label: 'Empty', value: 20, color: '#EA4228' },
        { label: 'Low', value: 40, color: '#F58B19' },
        { label: 'Fine', value: 60, color: '#F5CD19' },
        { label: 'Full', value: 100, color: '#5BE12C' },
    ];

    return (
        <>
            <div className="relative flex flex-col items-center justify-center w-full h-full p-4">
                <GaugeChart
                    id="gauge-chart"
                    nrOfLevels={20}
                    percent={percent}
                    arcsLength={gaugeData.map(d => d.value / 100)}
                    colors={gaugeData.map(d => d.color)}
                    arcPadding={0.02}
                    arcWidth={0.25}
                    needleColor="#4d368a"
                    needleBaseColor="#4d368a"
                    textColor="transparent"
                    style={{ width: '100%', height: 'auto' }}
                    animate={true}
                    animationDuration={2000}
                />
            </div>
            <span className="result">
                <span>{value}</span>/<span>{maxValue}</span>
            </span>
        </>
    );
}

export default ScoreIndicator;
