import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

export default function Button({
  className = "",
  children,
  right,
  disabled,
  ...props
}) {
  return (
    <button
      disabled={disabled}
      className={`w-full flex flex-row items-center justify-center rounded-[4px] px-[26px] py-[7px] text-center ${
        disabled
          ? `${className} border !border-[#E9E9E9] !bg-[#E9E9E9]`
          : `bg-[#4C44ED] text-[#FFF] ${className}`
      }`}
      {...props}
    >
      <span className="w-[95%]">{children}</span>
      {right && (
        <div className="w-[5%]">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      )}
    </button>
  );
}
