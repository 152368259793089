import {
  faArrowUp,
  faExclamationCircle,
  faEye,
  faImage,
  faTrashAlt,
  faChartLine,
  faCrown,
  faLifeRing
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/form/button";
import { requestAssistence } from "../../services/support.service";
import Delete from "../modals/modal-delete";
import StatsModal from "../modals/modal-stats";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Propertie = ({ item, index, token, inmob, onDelete, setModalOpen, setSelectedProperty }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [comment, setComment] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);

  const onSubmitAsistencia = async () => {
    const resp = await requestAssistence({ description: comment }, token, item.id);
    if (resp.data) {
      setComment('');
      alert('Petición realizada con éxito!');
    }
  };

  const isHighlighted = item.is_highlighted;
  const images = item.images || [];

  const handlePreview = () => {
    navigate(`/propiedad/${item.id}/detalle`);
  };

  const handleStatusClick = () => {
    setSelectedProperty(item);
    setModalOpen(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    onDelete(item.id);
    setShowDeleteModal(false);
  };

  const handleOpenStats = () => {
    setShowStatsModal(true);
  };

  const handleCloseStats = () => {
    setShowStatsModal(false);
  };

  return (
    <>
      <div className='bg-white shadow-lg rounded-lg p-6 mb-6 relative'>
        {isHighlighted && (
          <div className='absolute top-0 right-0 p-2 bg-yellow-600 rounded-full shadow-lg'>
            <FontAwesomeIcon icon={faCrown} size='lg' className='text-white' />
          </div>
        )}
        <div className='grid grid-cols-1 md:grid-cols-12 gap-6'>
          <div className='relative md:col-span-4'>
            <p className='text-sm text-gray-500 mb-2'>Propiedad #{item.id}</p>
            <div className='relative w-full h-48 md:h-56 rounded-lg overflow-hidden bg-gray-200'>
              {inmob && (
                <div
                  className='absolute inset-0 flex flex-col justify-center items-center cursor-pointer bg-opacity-70 bg-black'
                  onClick={() => navigate(`/admin/propertie/edit/image/${item.id}`, { state: { propertieId: item.id } })}
                >
                  {!images.length ? (
                    <>
                      <FontAwesomeIcon icon={faImage} size='2x' className='text-white' />
                      <span className='bg-white border border-gray-400 px-2 mt-2 text-gray-700 rounded-md'>
                        Iniciar
                      </span>
                    </>
                  ) : (
                    <span className='bg-white border border-gray-400 px-2 mt-2 text-gray-700 rounded-md'>
                      Iniciar
                    </span>
                  )}
                </div>
              )}
              {images.length > 1 ? (
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=''
                  containerClass='container'
                  dotListClass='custom-dot-list'
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=''
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 1
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 1
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1
                    }
                  }}
                  showDots
                  sliderClass=''
                  slidesToSlide={1}
                  swipeable
                  customLeftArrow={<CustomLeftArrow />}
                  customRightArrow={<CustomRightArrow />}
                >
                  {images.map((image, i) => (
                    <img key={i} src={image.image} className='object-cover w-full h-56' alt={`propertie-${i}`} />
                  ))}
                </Carousel>
              ) : images.length === 1 ? (
                <img src={images[0].image} className='object-cover w-full h-56' alt='propertie' />
              ) : (
                <div className='w-full h-full bg-gray-300 flex items-center justify-center'>
                  <FontAwesomeIcon icon={faImage} size='3x' className='text-gray-400' />
                </div>
              )}
            </div>
          </div>
          <div className="md:col-span-8 flex flex-col justify-between">

            <div>
              <h1 className={`text-xl font-bold ${isHighlighted ? 'text-yellow-600' : 'text-gray-800'}`}>{item.title}</h1>
              <div className="mt-4 space-y-2">
                <div className="flex justify-between text-sm text-gray-600">
                  <span>Publicado:</span>
                  <span>{moment(item.published_at).format("DD/MM/YYYY")}</span>
                </div>
                <div className="flex justify-between text-sm text-gray-600">
                  <span>Tipo de inmueble:</span>
                  <span className={`px-3 py-1 rounded-full ${item.property_type_id === "2" ? 'bg-gray-500 text-white' : ''} ${item.property_type_id === "1" ? 'bg-azul text-white' : ''} ${item.property_type_id === "3" ? 'bg-black text-white' : ''}`}>
                    {item.property_type}
                  </span>
                </div>
                <div className="flex justify-between text-sm text-gray-600 mt-2">
                  <span>Estado:</span>
                  <span className="bg-purple-800 text-white text-xs font-semibold px-3 py-1 rounded-full">
                    {item?.status}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Button
                className="bg-green-500 text-white rounded-lg px-6 py-3 w-full flex items-center justify-center animate-pulse shadow-lg"
                onClick={() => navigate("/admin/plan-upload", { state: { propertyId: item.id, from: "/admin/dashboard" } })}
              >
                <FontAwesomeIcon icon={faArrowUp} />
                <span className="ml-2">Mejorar plan o recontratar</span>
              </Button>
            </div>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <Button className="bg-white border border-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center justify-center" onClick={handlePreview}>
                <FontAwesomeIcon icon={faEye} />
                <span className="ml-2">Previsualizar</span>
              </Button>
              <Button className="bg-white border border-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center justify-center" onClick={handleStatusClick}>
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span className="ml-2">Estatus</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-6 bg-gray-50 p-4 rounded-lg">
          {/* <div className="flex justify-center">
            <Button
              className="border border-gray-300 text-gray-700 bg-white hover:bg-gray-100 rounded-full flex items-center justify-center space-x-2 px-4 py-2"
              onClick={handleOpenStats}
            >
              <FontAwesomeIcon icon={faChartLine} />
              <span className="ml-1">Estadisticas</span>
            </Button>
          </div> */}
          <div className="flex justify-center">
            <Button
              className='bg-red-600 text-white rounded-lg px-6 py-3 w-full flex items-center justify-center space-x-2 mt-3'
              onClick={handleDeleteClick}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
              <span className='ml-1'>Eliminar propiedad</span>
            </Button>
          </div>
          {active && (
            <div className="space-y-4 mt-5">
              <p className="text-gray-600 text-sm text-center">
                Esta propiedad está siendo anunciada actualmente con el plan {item?.tenant_plan_name}. La publicación podría ocultarse por motivos tales como un incumplimiento reportado.
              </p>
              <div className="w-full border-t border-gray-300" />
              <p className="text-gray-600 text-sm text-center">
                ¿Requieres asistencia o servicios como toma de fotografías o cómo realizar un contrato de arrendamiento? Por favor describe lo que solicitas en el siguiente campo y luego pulsa en el botón de enviar.
              </p>
              <div className="w-full flex flex-col mt-4">
                <label className="text-gray-900 text-base">Requiero de un asistente por la siguiente razón</label>
                <textarea
                  rows={4}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Escribe aquí el tipo de asistencia que requieres."
                  className="mt-2 p-3 border border-blue-500 rounded-lg text-sm text-gray-900 focus:ring-2 focus:ring-blue-300 focus:outline-none"
                />
              </div>
              <Button className="bg-azul text-white rounded-lg px-6 py-2 flex items-center justify-center" disabled={!comment} onClick={() => onSubmitAsistencia()}>
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span className="ml-2">Enviar</span>
              </Button>
            </div>
          )}
          <div className="flex justify-center  mt-3">
            <Button className="bg-azul text-white rounded-lg px-6 py-2 flex items-center justify-center" onClick={() => setActive(!active)}>
              <FontAwesomeIcon icon={faLifeRing} />
              <span className="ml-2">{active ? 'Cancelar solicitud' : 'Solicitar asistencia'}</span>
            </Button>
          </div>
        </div>
      </div>
      <Delete show={showDeleteModal} onClose={handleCloseModal} onConfirm={handleConfirmDelete} />
      <StatsModal show={showStatsModal} onCloseStats={handleCloseStats} property={item} />
    </>
  );
};

// Custom arrow components for the carousel
const CustomLeftArrow = ({ onClick }) => (
  <button
    className='absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-75 text-white rounded-full py-1 px-3 text-sm'
    onClick={onClick}
  >
    &lt;
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    className='absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-75 text-white rounded-full py-1 px-3 text-sm'
    onClick={onClick}
  >
    &gt;
  </button>
);

export default Propertie;
