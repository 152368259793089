import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import {
    faImage,
    faCrown,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PropertyHistory = ({ item }) => {
    const navigate = useNavigate();
    const images = item.property.images || [];
    const isHighlighted = item.is_highlighted;

    const handleViewProperty = () => {
        navigate(`/propiedad/${item.property.id}/detalle`);
    };

    return (
        <div className='bg-white shadow-lg rounded-lg p-6 mb-6 relative'>
            {isHighlighted && (
                <div className='absolute top-0 right-0 p-2 bg-yellow-600 rounded-full shadow-lg'>
                    <FontAwesomeIcon icon={faCrown} size='lg' className='text-white' />
                </div>
            )}
            <div className='grid grid-cols-1 md:grid-cols-12 gap-6'>
                <div className='relative md:col-span-4'>
                    <p className='text-sm text-gray-500 mb-2'>Propiedad #{item.property.id}</p>
                    <div className='relative w-full h-48 md:h-56 rounded-lg overflow-hidden bg-gray-200'>
                        {images.length > 1 ? (
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                infinite
                                responsive={{
                                    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
                                    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
                                    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
                                }}
                                showDots
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                            >
                                {images.map((image, i) => (
                                    <img key={i} src={image.image} className='object-cover w-full h-56' alt={`propertie-${i}`} />
                                ))}
                            </Carousel>
                        ) : images.length === 1 ? (
                            <img src={images[0].image} className='object-cover w-full h-56' alt='propertie' />
                        ) : (
                            <div className='w-full h-full bg-gray-300 flex items-center justify-center'>
                                <FontAwesomeIcon icon={faImage} size='3x' className='text-gray-400' />
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:col-span-8 flex flex-col justify-between">
                    <div>
                        <h1 className={`text-xl font-bold ${isHighlighted ? 'text-yellow-600' : 'text-gray-800'}`}>{item.title}</h1>
                        <div className="mt-4 space-y-2">
                            <div className="flex justify-between text-sm">
                                <span className="text-lg text-bold">{item.property.title}</span>
                            </div>
                            <div className="flex justify-between text-sm text-gray-600">
                                <span>Publicado:</span>
                                <span>{moment(item.published_at).format("DD/MM/YYYY")}</span>
                            </div>
                            <div className="flex justify-between text-sm text-gray-600">
                                <span>Tipo de inmueble:</span>
                                <span className={`px-3 py-1 rounded-full ${item.property_type_id === "2" ? 'bg-gray-500 text-white' : ''} ${item.property_type_id === "1" ? 'bg-azul text-white' : ''} ${item.property_type_id === "3" ? 'bg-black text-white' : ''}`}>
                                    {item.property.property_type}
                                </span>
                            </div>
                            <div className="flex justify-between text-sm text-gray-600 mt-2">
                                <span>Dueño:</span>
                                <span className="bg-purple-800 text-white text-xs font-semibold px-3 py-1 rounded-full">
                                    {item?.owner.name}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <button
                            className="bg-azul text-white rounded-lg px-6 py-3 w-full flex items-center justify-center shadow-lg"
                            onClick={handleViewProperty}
                        >
                            Ver propiedad
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyHistory;
