import casaRota from '../../assets/brokenHouse.png';
import { useNavigate } from 'react-router-dom';
import usingApp from '../../assets/drawe-woman-men-using-app.png';

const NotFound = () => {

  const navigate = useNavigate();
  const handleGoMainPage = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <h1 className="text-4xl md:text-6xl font-bold text-purple-800 text-center">ERROR 404</h1>
      <img src={casaRota} className="my-5 h-60 max-w-full object-contain" alt="Casa rota" />
      <p className="mt-4 text-lg text-center my-4">Página fuera de cobertura
        <br />
        Parece que este enlace no se encuentra en nuestro catálogo.
      </p>
      <button
        onClick={handleGoMainPage}
        className="bg-purple-800 text-sm hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center transition-transform duration-300 transform hover:scale-105 my-3"
      >
        Ir a la Página principal
      </button>
      <p className="text-lg text-center my-4">Explora nuestra plataforma y descubre todas las funciones que sí tenemos activas</p>
      <img src={usingApp} className="h-32 max-w-full object-contain mt-3" alt="Usando la aplicación" />
    </div>
  );
};

export default NotFound;
