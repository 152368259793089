import axios from 'axios'; // Asegúrate de importar CancelToken correctamente
import { useEffect, useState } from 'react';
import { getPropertiesHighlighted } from '../services/properties.service';

let cancel;

const usePropiedadesDestacadas = (filtros, token = null) => {
    const [propiedadesDestacadas, setPropiedadesDestacadas] = useState([]);

    useEffect(() => {
        const obtenerPropiedadesDestacadas = async () => {
            if (!filtros) return;

            if (cancel) {
                cancel("Nueva solicitud, cancelando la anterior");
            }

            try {
                const sanitizeJoinValues = (array) => array.map(item => item === '3+' ? 3 : item);

                const amenityIds = filtros.tiposSeleccionados.length > 0 ? sanitizeJoinValues(filtros.tiposSeleccionados).join(',') : null;
                const bestAmenityIds = filtros.bestAmenidadesSeleccionados.length > 0 ? sanitizeJoinValues(filtros.bestAmenidadesSeleccionados).join(',') : null;
                const rentDurationIds = filtros.tipoRenta.length > 0 ? sanitizeJoinValues(filtros.tipoRenta).join(',') : null;

                const params = {
                    houses: filtros.tiposPropiedadSeleccionados.includes('casa'),
                    departaments: filtros.tiposPropiedadSeleccionados.includes('departamento'),
                    colivings: filtros.tiposPropiedadSeleccionados.includes('coliving'),
                    priceMin: filtros.rangoPrecios[0],
                    priceMax: filtros.rangoPrecios[1],
                    rooms: filtros.habitacionesSeleccionadas,
                    antiquityMin: filtros.antiguedad ? filtros.antiguedad[0] : 0,
                    antiquityMax: filtros.antiguedad ? (filtros.antiguedad[1] * 365) : null,
                    lat: filtros.lat,
                    lng: filtros.lng,
                    radius: filtros.radio,
                    publishedFrom: filtros.fechaPublicacion,
                    format_address: filtros.direccionFormateada || null,
                    amenityIds,
                    bestAmenityIds,
                    rentDurationIds,
                    minLng: filtros.lngMin,
                    maxLng: filtros.lngMax,
                    minLat: filtros.latMin,
                    maxLat: filtros.latMax,
                    isPetAllowed: filtros.mascotasSeleccionadas,
                    isChildAllowed: filtros.niñosSeleccionados,
                    parking_lot: filtros.cochesSeleccionados,
                };

                const response = await getPropertiesHighlighted(params, token, new axios.CancelToken(function executor(c) {
                    cancel = c; // Asignamos la función de cancelación a la variable global 'cancel'
                }));

                console.log(response.data);

                const propiedades = response.data.properties;

                const propiedadesFormateadas = propiedades.map(propiedad => ({
                    ...propiedad, // Esto copia todas las propiedades originales
                    etiqueta: `$${propiedad.monthly_rent / 1000}K`,
                    info: `${propiedad.title}, ${propiedad.monthly_rent / 1000}k/mes, ${propiedad.rooms} recámaras, ${propiedad.bathrooms} baños`,
                    imagen: propiedad.images[0]?.image || ''
                }));

                setPropiedadesDestacadas(propiedadesFormateadas);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Solicitud cancelada:', error.message);
                } else {
                    console.error('Error al obtener propiedades destacadas:', error);
                }
            }
        };

        const delayDebounceFn = setTimeout(obtenerPropiedadesDestacadas, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            if (cancel) {
                cancel("Componente desmontado o filtros cambiados, cancelando la solicitud");
            }
        };
    }, [filtros]);

    return propiedadesDestacadas;
};

export default usePropiedadesDestacadas;
