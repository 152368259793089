import React from "react";
import Modal from "../../components/modals/modal";


const ContractsModal = ({ isOpen, onClose, token, userId, propertyId }) => {

    if (!isOpen) return null;

    return (
        <Modal>

        </Modal>
    );
};

export default ContractsModal;