import React from "react";
import { Link } from "react-router-dom";

const Dropdown = ({ name, dropdownItems }) => {
  return (
    <div className="dropdown dropdown-hover">
      <Link to={"#"} tabIndex={0} className="cursor-pointer">
        {name}
      </Link>
      <ul className="dropdown-menu absolute hidden h-auto z-[1] menu shadow bg-base-100 rounded-box w-52">
        {dropdownItems.map(({ name, url }, index) => (
          <li key={index} className="bg-[#FFF] p-2">
            <Link to={url}>{name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
