import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import LocationDrawer from '../../../assets/drawe-location-inmueble.png';
import { useSelector } from 'react-redux';
import { getMeUserInfo } from '../../../services/auth.service';
import { useState, useEffect } from 'react';

const AddressInfo = ({ step, setStep, contador }) => {

    const { token } = useSelector((state) => state.auth);
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        getMyInfo();
    }, [token]);

    const getMyInfo = async () => {
        const resp = await getMeUserInfo(token);
        if (resp.data) {
            setUserInfo(resp.data.user);
        }
    };

    const handleBack = () => {
        if (userInfo.lessor_type === 'agent') {
            setStep(step - 3);
        }
        else {
            setStep(step - 2)
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-8 rounded-lg"
        >
            {contador > 0 && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Ubica tu inmueble</h3>
            <img src={LocationDrawer} alt="add propertie" className="w-[20rem] mb-6" />
            <p className="text-gray-700 text-center mb-6">
                Necesitamos conocer la ubicación exacta de tu casa o departamento. Ayúdanos a ubicar tu inmueble correctamente. Por favor introduce la información requerida.
            </p>
            <div className="w-full flex justify-between items-center mt-4 gap-x-4">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={handleBack}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step + 1)}
                >
                    Continuar
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>
        </motion.div>
    );
};

export default AddressInfo;
