const ErrorModal = ({ show, text, title, onClose }) => {
    if (!show) return null;

    const lines = text.split("/n");

    const cerrar = () => {
        onClose();
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-30 flex items-center justify-center z-50">
            <div className="bg-red-100 text-red-700 rounded-lg shadow-lg w-2/3  md:w-1/3 max-w-md p-6">
                {title && (<h3 className="mb-2 text-center font-semibold text-lg">{title}</h3>)}
                {lines.map((line, index) => (
                    <p key={index} className="mb-2 text-center font-semibold text-lg px-3">
                        {line}
                    </p>
                ))}
                <div className="flex align-center justify-center">
                    <button onClick={cerrar} className="w-full md:w-auto flex items-center justify-center text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full shadow-lg">Ok</button>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
