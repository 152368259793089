import Casa from '../../assets/casa404.svg'
import { useNavigate } from 'react-router-dom'

const NotFoundProperty = () => {

    const navigate = useNavigate();
    const handleGoMainPage = () => {
        navigate('/');
    }
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 px-4">
            <h1 className="text-4xl md:text-5xl font-bold text-purple-800 text-center">Esta propiedad está fuera de nuestro inventario</h1>
            <img src={Casa} className="my-5" style={{ filter: 'grayscale(100%)' }} />
            <p className="mt-4 text-lg text-center my-6">Parece que esta propiedad ya no existe.<br></br>
                Pero seguro encontrarás otra igual de increíble en Yo rento.</p>
            <button
                onClick={handleGoMainPage}
                className="bg-purple-800 text-sm hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center transition-transform duration-300 transform hover:scale-105 mt-5"
            >Ir a la Página principal</button>
        </div>

    );
};


export default NotFoundProperty;
