import axios from 'axios';
import React, { useState, useEffect } from 'react';

const FormularioDinamico = () => {
    const token = localStorage.getItem('token-yo-rento');
    const [form, setForm] = useState(null);
    const [formResponses, setFormResponses] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const textoExito = "¡Formulario enviado con éxito!";

    const fetchFormData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/app/operation/investigation/form', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const fetchedForm = response.data.form;

            const existingResponses = fetchedForm.sections.flatMap(section =>
                section.questions.map(question => ({
                    question_id: question.question_id,
                    answer_text: question.answer_text
                }))
            );

            const responsesMap = {};
            existingResponses.forEach(answer => {
                responsesMap[answer.question_id] = answer.answer_text;
            });
            setForm(fetchedForm);
            setFormResponses(responsesMap);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching form data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFormData();
    }, []);

    const handleInputChange = (questionId, value) => {
        setFormResponses((prevResponses) => ({
            ...prevResponses,
            [questionId]: value,
        }));
    };

    const prepareAnswersForSubmission = () => {
        const answers = [];

        if (form) {
            form.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    answers.push({
                        answer_id: question.answer_id || null,
                        question_id: question.question_id,
                        answer_text: formResponses[question.question_id] || null,
                    });
                });
            });
        }

        return { answers };
    };

    const handleSubmit = async () => {
        const dataToSubmit = prepareAnswersForSubmission();
        const formId = form.id;

        try {
            await axios.put(`/app/operation/investigation/form/${formId}`, dataToSubmit, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log('Respuestas enviadas exitosamente');
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error al enviar las respuestas:', error);
        }
    };

    if (isLoading) {
        return <div className="text-center text-gray-500">Cargando formulario...</div>;
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
            className="max-w-4xl mx-auto p-8 rounded-lg"
        >
            {form.sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="mb-8">
                    <h2 className="text-xl font-bold mb-4 text-gray-700 text-center">{section.title}</h2>
                    {section.questions.map((question, questionIndex) => (
                        <div key={questionIndex} className="mb-6">
                            <label className="block text-gray-600 mb-2">{question.question_text}</label>
                            {question.question_type === 'text' && (
                                <input
                                    type="text"
                                    required={question.is_required}
                                    value={formResponses[question.question_id] || ''}
                                    onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            )}
                            {question.question_type === 'number' && (
                                <input
                                    type="number"
                                    required={question.is_required}
                                    value={formResponses[question.question_id] || ''}
                                    onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            )}
                            {question.question_type === 'multiple_option' && (
                                <select
                                    required={question.is_required}
                                    value={formResponses[question.question_id] || ''}
                                    onChange={(e) => handleInputChange(question.question_id, e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    <option value="">Seleccione una opción</option>
                                    {question.options.map((option) => (
                                        <option key={option.id} value={option.text}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
            >
                Enviar
            </button>

            {showSuccessModal && (
                <div className="mt-6 p-4 bg-green-100 border border-green-400 text-green-700 rounded-lg">
                    {textoExito}
                </div>
            )}
        </form>
    );
};

export default FormularioDinamico;
