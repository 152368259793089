import Button from "../components/form/button";
import React, { useState } from "react";
import { connect } from "react-redux";
import RootLayout from "../components/Layout";
import LayoutHome from "../components/LayoutHome";
import TextInput from "../components/form/textinput";
import { addTickerSupport } from "../services/support.service";
import Icon from "../assets/isotipo.png";
import DraweMenGood from "../assets/drawe-men-good.png";

const HelpPage = (props) => {
    const [confirmPage, setConfirmPage] = useState(false);
    const [name, setName] = useState("");
    const [propertiePublish, setPropertiePublish] = useState(false);
    const [subject, setSubject] = useState("");
    const [comment, setComment] = useState("");

    const onSubmitSupportTicker = async () => {
        try {
            await addTickerSupport({
                name,
                hasPropertyPublished: propertiePublish,
                affair: subject,
                description: comment,
            }, props.token);

            setName("");
            setPropertiePublish("");
            setComment("");
            setSubject("");

            setConfirmPage(true);
        } catch (e) {
            console.log(e);
            alert("Ocurrio algo inesperado");
        }
    };

  return (
    <RootLayout admin>
      <LayoutHome token={props.token}>
        {/* <h2>Formulario de ayuda</h2> */}
            {confirmPage ? (
                <div>
                    <div className="w-full flex justify-center mb-[3rem]">
                        <img src={Icon} alt="icon" className="w-[32px]" />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img src={DraweMenGood} alt="confirmation page" className=" mb-[4rem]" />
                        <span className="text-center max-w-md mb-[4rem]">
                            Hemos recibido tu mensaje, revisaremos con nuestros equipo y en breve nos comunicaremos contigo.
                        </span>

                        <span className="text-[#3E11B5] cursor-pointer" onClick={() => setConfirmPage(false)}>OK</span>
                    </div>
                </div>
            ): (
            <div className="grid grid-cols-8">
                <div className="col-span-8 lg:col-span-4 lg:col-start-3">
                    <h1 className="text-[20px] text-[#3E11B5] text-center font-semibold">¿Necesitas ayuda?</h1>

                    <p className="text-center mt-8">Por favor llena los siguientes espacios</p>

                    <div className="mt-12 flex flex-col gap-y-4">
                        <div>
                            <label className="text-[#000] text-[15px]">Nombre</label>
                            <TextInput
                                placeholder="Mariana Gómez Hernández"
                                className="!text-left text-[#000] !bg-[#fff] !border-[#3E11B5]"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="flex items-center gap-x-1">
                            <input
                                id="have-inmueble"
                                type="radio"
                                checked={propertiePublish}
                                onClick={() => setPropertiePublish(!propertiePublish)}
                                className="w-4 h-4 text-blue-600 rounded-[50%] accent-#4C44ED bg-gray-100 border-gray-300 focus:ring-blue-500 accent-[#3E11B5]"
                            />
                            <label htmlFor="have-inmueble">Tengo un inmueble publicado</label>
                        </div>

                        <div>
                            <label className="text-[#000] text-[15px]">Asunto</label>
                            <TextInput
                                placeholder="Sobre posible venta"
                                className="!text-left text-[#000] !bg-[#fff] !border-[#3E11B5]"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </div>

                        <div>
                            <label className="text-[#000] text-[15px]">Cuéntanos, ¿cómo podemos ayudarte?</label>
                            <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="border border-[#3E11B5] rounded-[4px] w-full p-2 mt-1" rows={4} placeholder="Quisiera saber si me pueden ayudar a vender mi departamento"></textarea>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Button disable={!name || !subject || !comment} onClick={() => onSubmitSupportTicker()} right>Enviar</Button>
                    </div>
                </div>
            </div>
          )}
      </LayoutHome>
    </RootLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps)(HelpPage);