const Success = ({ show, text }) => {
    if (!show) return null;

    const lines = text.split("/n");

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-green-100 text-green-700 rounded-lg shadow-lg w-1/3 max-w-md p-6">
                {lines.map((line, index) => (
                    <p key={index} className="mb-2 text-center font-semibold text-lg">
                        {line}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default Success;
