import { faBell, faCog, faCommentDots, faEnvelope, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import RootLayout from "../../components/Layout";
import LayoutHome from "../../components/LayoutHome";
import { getNotifications } from "../../services/auth.service";

const NotificationPage = (props) => {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [notificationSettings, setNotificationSettings] = useState({
        matchPush: true,
        chatPush: true,
        chatPreviews: true,
        accountActions: true,
        yorentoNotifications: true,
        emailNotifications: true,
    });

    const getDataNotification = async () => {
        try {
            const resp = await getNotifications(props.token);
            if (resp.data) {
                setNotifications(resp.data.notifications);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (props.token) getDataNotification();
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [props.token]);

    const handleToggle = (type) => {
        setNotificationSettings((prevSettings) => ({
            ...prevSettings,
            [type]: !prevSettings[type],
        }));
    };

    return (
        <RootLayout admin>
            <LayoutHome token={props.token}>
                <div className="container mx-auto px-4 md:px-8 mt-6">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-2xl text-indigo-700 font-semibold">Ajustes de Notificaciones</h1>
                        <button
                            onClick={() => navigate("/admin/notificacionesSettings")}
                            className="text-indigo-600 hover:text-indigo-800 transition-colors duration-300"
                        >
                            <FontAwesomeIcon icon={faCog} size="lg" />
                        </button>
                    </div>
                    <div className="w-full min-h-[30vh] rounded-lg mt-2 p-4 md:p-8 bg-white shadow-sm">
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold flex items-center">
                                <FontAwesomeIcon icon={faBell} className="mr-2 text-indigo-600" /> Coincidencias (match)
                            </h3>
                            <div className="flex items-center justify-between py-2 border-b">
                                <div className="flex items-center">
                                    <span className="mr-2">🏠</span>
                                    <span>Notificaciones push de coincidencias</span>
                                </div>
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    className={`${notificationSettings.matchPush ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
                                    onClick={() => handleToggle("matchPush")}
                                >
                                    <span
                                        className={`${notificationSettings.matchPush ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold flex items-center">
                                <FontAwesomeIcon icon={faCommentDots} className="mr-2 text-indigo-600" /> Chats
                            </h3>
                            <div className="flex items-center justify-between py-2 border-b">
                                <div className="flex items-center">
                                    <span className="mr-2">💬</span>
                                    <span>Notificaciones push mensajes de chat</span>
                                </div>
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    className={`${notificationSettings.chatPush ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
                                    onClick={() => handleToggle("chatPush")}
                                >
                                    <span
                                        className={`${notificationSettings.chatPush ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
                                    />
                                </button>
                            </div>
                            <div className="flex items-center justify-between py-2 border-b">
                                <div className="flex items-center">
                                    <span className="mr-2">👀</span>
                                    <span>Mostrar previews de mensajes de chat</span>
                                </div>
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    className={`${notificationSettings.chatPreviews ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
                                    onClick={() => handleToggle("chatPreviews")}
                                >
                                    <span
                                        className={`${notificationSettings.chatPreviews ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold flex items-center">
                                <FontAwesomeIcon icon={faUser} className="mr-2 text-indigo-600" /> Mi cuenta
                            </h3>
                            <div className="flex items-center justify-between py-2 border-b">
                                <div className="flex items-center">
                                    <span className="mr-2">👤</span>
                                    <span>Notificaciones push sobre acciones relacionadas con la cuenta</span>
                                </div>
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    className={`${notificationSettings.accountActions ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
                                    onClick={() => handleToggle("accountActions")}
                                >
                                    <span
                                        className={`${notificationSettings.accountActions ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold flex items-center">
                                <FontAwesomeIcon icon={faBell} className="mr-2 text-indigo-600" /> Notificaciones de YoRento
                            </h3>
                            <div className="flex items-center justify-between py-2 border-b">
                                <div className="flex items-center">
                                    <span className="mr-2">🔔</span>
                                    <span>Notificaciones de YoRento</span>
                                </div>
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    className={`${notificationSettings.yorentoNotifications ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
                                    onClick={() => handleToggle("yorentoNotifications")}
                                >
                                    <span
                                        className={`${notificationSettings.yorentoNotifications ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
                                    />
                                </button>
                            </div>
                            <div className="flex items-center justify-between py-2">
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-indigo-600" />
                                    <span>Recibir notificaciones por correo</span>
                                </div>
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    className={`${notificationSettings.emailNotifications ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
                                    onClick={() => handleToggle("emailNotifications")}
                                >
                                    <span
                                        className={`${notificationSettings.emailNotifications ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutHome>
        </RootLayout>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    user: state.auth.user,
});

export default connect(mapStateToProps)(NotificationPage);
