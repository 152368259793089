import Modal from "./modal";
import Drawer from "../../assets/drawe-like-unauth.png";

const ModalFirst = (props) => {
    if (props.show)
        return (
            <Modal>
                <div className="relative p-8 lg:p-[86px] flex-auto">
                    <div className="w-full lg:max-w-xl lg:w-auto">
                        <h3 className="text-center text-[#3E11B5] text-[20px] font-[600] mb-12">Pago realizado con exito</h3>

                        <div className="flex items-center mt-8 px-[20%]">
                            <button className="w-full h-12 bg-purple-600 text-white font-semibold rounded-lg shadow-md hover:bg-purple-400 transition flex items-center justify-center mt-4" onClick={props.onConfirm}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </Modal>
        )

    return null
}

export default ModalFirst;