import React, { useEffect, useRef } from 'react';
import useGoogleMaps from '../../hooks/useGoogleMaps';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

const MapsWrapper = ({ zoom, handleLocationChange, location }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const loaded = useGoogleMaps();

    useEffect(() => {
        if (loaded && !mapRef.current && location.lat && location.lng) {
            const google = window.google;
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: location.lat, lng: location.lng },
                zoom: zoom,
            });
            mapRef.current = map;

            const marker = new google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map: map,
                draggable: true,
            });
            markerRef.current = marker;

            // Evento al hacer clic en el mapa
            google.maps.event.addListener(map, 'click', (event) => {
                const newLocation = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                    select: true,
                };
                marker.setPosition(newLocation);
                fetchAddressDetails(newLocation);  // Obtenemos los detalles de la ubicación
            });

            // Evento al arrastrar el marcador
            google.maps.event.addListener(marker, 'dragend', (event) => {
                const newLocation = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                    select: true,
                };
                fetchAddressDetails(newLocation);  // Obtenemos los detalles de la ubicación
            });
        }
    }, [loaded, location.lat, location.lng]);

    useEffect(() => {
        if (markerRef.current && location.lat && location.lng) {
            const latLng = new window.google.maps.LatLng(location.lat, location.lng);
            markerRef.current.setPosition(latLng);
            mapRef.current.panTo(latLng);
        }
    }, [location]);

    // Función para obtener los detalles de la dirección con Geocoding
    const fetchAddressDetails = async (newLocation) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLocation.lat},${newLocation.lng}&key=${process.env.REACT_APP_GOOGLE_KEY}`
            );
            const data = await response.json();
            const addressDetails = data.results[0];

            const formatted_address = addressDetails.formatted_address || '';
            const postal_code = addressDetails.address_components.find((component) => component.types.includes('postal_code'))?.long_name || '';
            const city = addressDetails.address_components.find((component) => component.types.includes('locality'))?.long_name || '';
            const state = addressDetails.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || '';

            const updatedLocation = {
                lat: newLocation.lat,
                lng: newLocation.lng,
                formatted_address,
                postal_code,
                city,
                state,
                select: true,
            };

            handleLocationChange(updatedLocation);  // Actualizamos la ubicación con todos los detalles
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    return <div id="map" style={mapContainerStyle} />;
};

export default MapsWrapper;
