import axios from 'axios';
import { getProperties } from '../../services/properties.service';

let cancel;

const obtenerPropiedades = async (filtros, token = null) => {
  // Si existe una solicitud anterior, se cancela antes de hacer una nueva
  if (cancel) {
    cancel("Nueva solicitud, cancelando la anterior");
  }

  console.log({token});


  try {
    const {
      rangoPrecios, habitacionesSeleccionadas,
      antiguedad, lat, lng, radio, fechaPublicacion, direccionFormateada, tiposPropiedadSeleccionados, tipoRenta, tiposSeleccionados,
      bestAmenidadesSeleccionados, lngMin, lngMax, latMin, latMax, mascotasSeleccionadas, niñosSeleccionados, cochesSeleccionados, banosSeleccionados
    } = filtros;

    const houses = tiposPropiedadSeleccionados.includes('casa');
    const departaments = tiposPropiedadSeleccionados.includes('departamento');
    const colivings = tiposPropiedadSeleccionados.includes('coliving');
    const priceMin = rangoPrecios[0];
    const priceMax = rangoPrecios[1];
    const antiquityMin = antiguedad ? antiguedad[0] : 0;
    const antiquityMax = antiguedad ? (antiguedad[1] * 365) : null;

    // Reemplazo de "3+" por 3 en los arrays antes de hacer el join
    const sanitizeJoinValues = (array) => array.map(item => item === '3+' ? 3 : item);

    const amenityIds = tiposSeleccionados.length > 0 ? sanitizeJoinValues(tiposSeleccionados).join(',') : null;
    const bestAmenityIds = bestAmenidadesSeleccionados.length > 0 ? sanitizeJoinValues(bestAmenidadesSeleccionados).join(',') : null;
    const rentDurationIds = tipoRenta.length > 0 ? sanitizeJoinValues(tipoRenta).join(',') : null;

    const params = {
      houses,
      departaments,
      colivings,
      priceMin,
      priceMax,
      rooms: habitacionesSeleccionadas,
      antiquityMin,
      antiquityMax,
      lat,
      lng,
      radius: radio,
      publishedFrom: fechaPublicacion,
      format_address: direccionFormateada || null,
      amenityIds,
      bestAmenityIds,
      rentDurationIds,
      minLng: lngMin,
      maxLng: lngMax,
      minLat: latMin,
      maxLat: latMax,
      isPetAllowed: mascotasSeleccionadas,
      isChildAllowed: niñosSeleccionados,
      parking_lot: cochesSeleccionados,
      bathrooms: banosSeleccionados
    };

    // Creación del token de cancelación
    const response = await getProperties(params, token, new axios.CancelToken(function executor(c) {
      cancel = c; // Asignamos la función de cancelación a la variable global 'cancel'
    }));

    console.log(params);
    return response.data;

  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Solicitud cancelada:', error.message);
    } else {
      console.log('Error al obtener propiedades:', error);
      throw error;
    }
  }
};

export default obtenerPropiedades;
