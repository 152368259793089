// src/components/CarouselDestacadas.js

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TarjetaPropiedad from './../../cards/TarjetaPropiedad';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
};

const CarouselDestacadas = ({ propiedadesDestacadas }) => {
    console.log(propiedadesDestacadas);
    return (
        <div className='container w-full p-6 mx-auto mt-4'>
            <h2 className='text-2xl font-bold text-purple-600 text-center mb-4'>Propiedades similares</h2>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                showDots={false}
                containerClass="carousel-container w-full"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {propiedadesDestacadas.map((prop, index) => (
                    <div key={index} className='py-3 h-full flex justify-center'>
                        <TarjetaPropiedad expandir={true} propiedad={prop} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default CarouselDestacadas;
