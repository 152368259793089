import { faInfoCircle, faMapMarkerCheck } from '@fortawesome/pro-light-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/footer/Footer';
import NavBar from '../components/header/Navbar';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import UsingApp from '../assets/drawe-woman-men-using-app.png';
import Isotipo from '../assets/isotipo.png';
import logoCuadrado from '../assets/logoCasaCuadrado.png';
import logoMarker from '../assets/logoyorentomarker.png';
import { useState } from 'react';

const Carousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        <>
            Queremos evolucionar el arrendamiento en México a través de <strong>tecnología</strong>. En Yo rento <strong>eliminamos el estrés</strong> que te provocan las operaciones de arrendamiento, ya que estamos transformando la manera en que <strong>inquilinos, propietarios</strong> y <strong>profesionales inmobiliarios conectan entre sí</strong>.
        </>,
        <>
            Somos la primera <strong>plataforma tecnológica mexicana especializada 100% en arrendamiento.</strong> Accionamos con la misión de facilitar la publicación de propiedades y el match entre inquilinos y arrendadores. A través de tecnología avanzada, <strong>inteligencia artificial y humana</strong> con un fuerte enfoque en la seguridad, hemos diseñado herramientas que permiten a nuestros usuarios tomar <strong>decisiones inteligentes, informadas y confiables.</strong>
        </>,
        <>
            Existimos para solucionar la falta de <strong>seguridad</strong>, altos costos, comunicación ineficiente, procesos complicados, y des-actualización de inventarios. Existimos para ser un puente, un <strong>match perfecto</strong> entre un proyecto y un sueño, entre una <strong>propiedad y un inquilino, entre seres humanos que buscan una vida digna y exitosa.</strong>
        </>
    ];

    const slidesTitles = [

        'Yo Rento pone al usuario y su seguridad como centro de sus acciones',
        'Nuestra visión de las rentas',
        'Propósito de Yo Rento',
    ];

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    return (
        <div className="mt-8 px-2 md:px-0">
            <div className="bg-gray-100 rounded-lg shadow-lg p-4 flex flex-col md:flex-row items-center">
                <div className="w-1/3">
                    <img
                        src={logoMarker}
                        alt="Carousel"
                        className="rounded-lg w-full h-auto object-cover"
                    />
                </div>

                <div className="relative w-full md:w-2/3">
                    <div id="carouselContent" className="text-center flex flex-col w-full">
                        <h2 className="text-2xl font-bold text-center mb-8 pt-8 text-purple-800">{slidesTitles[currentSlide]}</h2>
                        <p className=" font-medium md:px-8 px-7">{slides[currentSlide]}</p>
                    </div>
                    <button
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-purple-800"
                        onClick={handlePrevSlide}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-purple-800"
                        onClick={handleNextSlide}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

const Nosotros = () => {
    return (
        <>
            <NavBar />
            <div className="container mx-auto py-10">

                <section className="mb-16 sm:mx-2 md:mx-2">
                    <div className="w-full md:w-9/12 items-center justify-center mx-auto shadow-2xl">
                        <h1 className="text-4xl font-bold text-center mb-8 pt-8 text-purple-800">
                            Un equipo humano detrás de Yo rento
                        </h1>

                        {/* Primera */}
                        <div className="flex flex-col md:flex-row items-center justify-center">
                            <img
                                src={UsingApp}
                                className="w-2/3 md:w-1/3 mr-4 lg:mr-8"
                                alt="Using App"
                            />
                            <p className="text-center w-2/3 p-2 md:w-1/2 mt-2">
                                Somos un equipo multidisciplinario con una visión cohesiva y humana.{" "}
                                <span className="font-bold">
                                    Deseamos cambiar la forma en la que se renta en México
                                </span>{" "}
                                y vemos la vivienda como un derecho humano fundamental.
                            </p>
                        </div>

                        {/* Segunda */}
                        <div className="mt-8 p-2 ">
                            <div className="flex flex-col md:flex-row items-center bg-gray-100 rounded-lg md:rounded-full xl:mx-8 p-2 lg:p-0">
                                <div className="text-center w-full md:w-2/3 md:w-1/2 grow">
                                    <p className="text-lg font-medium p-6">
                                        Es por ello que unimos mentes tecnológicas, de derecho, administradores,<br></br> inmobiliarios, en un solo equipo para crear un producto que resuene con<br></br> dueños, agente y futuros inquilinos.
                                    </p>
                                </div>
                                <div className="w-1/3 md:w-auto h-full rounded-r-xl p-3 md:p-0">
                                    <img
                                        src={logoCuadrado}
                                        alt="Team"
                                        className="h-auto md:h-60 object-cover p-3 md:p-0"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Tercera sección */}
                        <Carousel />
                    </div>
                </section>


                <section className="mb-16">
                    <h1 className="text-4xl font-bold text-center mb-8 text-purple-800 ">Planes y Tarifas para Publicar</h1>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 sm: mx-2 md: mx-2">


                        {/* Primera columna */}

                        <div className="bg-gray-100 shadow-md rounded-lg flex flex-col border-2 border-purple-800 ">
                            <div className="bg-purple-600 text-white p-5 text-center w-full h-32">
                                <h3 className="text-xl font-bold mb-4">Individuales</h3>
                                <p>Tarifa Mensual</p>
                            </div>
                            <p className="text-center">*Precios por usuario</p>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$0 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Publicación <br /> Individual</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                            Publicar 1 Propiedad por 30 días es totalmente GRATIS
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$349 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Publicación <br /> Básica</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                            Publica 2 propiedades simultáneamente por 30 días
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$1,299 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Publicación <br /> Destacada</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Destaca tu propiedad para darle
                                            mayor visibilidad en nuestros
                                            listados por 30 días
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Servicios Incluidos en la parte inferior */}
                            <div className="p-6 mt-auto">
                                <hr className="border-2 border-yellow-200 mx-8 mb-4"></hr>
                                <h4 className="font-semibold">Servicios Incluidos:</h4>
                                <ul className="list-none pl-0">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Alertas Inteligentes de Búsqueda
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Chat Interno
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Calculadora de Precios
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Recibe tu link de propiedad en tu WhatsApp
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Califique y sea calificado
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        PDF Marca Blanca
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faX} className="text-red-600 text-2xl p-1 mr-2" />
                                        Coach Yo Rento Chatbot
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faX} className="text-red-600 text-2xl p-1 mr-2" />
                                        Menú exclusivo con avisos de demanda de inmuebles por zonas
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faX} className="text-red-600 text-2xl p-1 mr-2" />
                                        Precio preferencial para destacar propiedad
                                    </li>
                                </ul>
                            </div>
                        </div>



                        {/* Segunda columna */}
                        <div className="bg-gray-100 shadow-md rounded-lg flex flex-col border-2 border-purple-800">
                            <div className="bg-purple-600 text-white p-5 text-center h-32">
                                <h3 className="text-xl font-bold mb-4">Paquetes Renta Anual</h3>
                                <p>Tarifa Mensual</p>
                            </div>
                            <p className="text-center">*Precios por usuario</p>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$849 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Membresía <br /> Plus</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Publica propiedades de
                                            Renta Anual/Larga Estancia de
                                            manera ilimitada durante 30 días
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$349 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Publicación <br /> Destacada</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Precio preferencial para
                                            destacar propiedades
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Servicios Incluidos en la parte inferior */}
                            <div className="p-6 mt-auto">
                                <hr className="border-2 border-yellow-200 mx-8 mb-4"></hr>
                                <h4 className="font-semibold">Servicios Incluidos:</h4>
                                <ul className="list-none pl-0">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Alertas Inteligentes de Búsqueda
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Chat Interno
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Calculadora de Precios
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Recibe tu link de propiedad en tu WhatsApp
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Califique y sea calificado
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        PDF Marca Blanca
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Coach Yo Rento Chatbot
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Menú exclusivo con avisos de demanda de inmuebles por zonas
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Precio preferencial para destacar propiedad
                                    </li>
                                </ul>

                            </div>
                        </div>


                        {/* Tercera columna */}
                        <div className="bg-gray-100 shadow-md rounded-lg flex flex-col border-2 border-purple-800">
                            <div className="bg-purple-600 text-white p-5 text-center h-32">
                                <h3 className="text-xl font-bold mb-4">Paquetes Renta Temporal o Vacacional</h3>
                                <p>Tarifa Mensual</p>
                            </div>
                            <p className="text-center">*Precios por usuario</p>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$849 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Membresía <br /> Pro</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Publica hasta 10 propiedades de
                                            Renta Temporal o Vacacional
                                            durante 30 días
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$1,499 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Membresía <br /> Pro Max</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Publica desde 11 hasta 30 propiedades de Renta Temporal o Vacacional durante 30 días
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$349 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Publicación <br /> Destacada</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Precio preferencial para
                                            destacar propiedades
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Servicios Incluidos en la parte inferior */}
                            <div className="p-6 mt-auto">
                                <hr className="border-2 border-yellow-200 mx-8 mb-4"></hr>
                                <h4 className="font-semibold">Servicios Incluidos:</h4>
                                <ul className="list-none pl-0">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Alertas Inteligentes de Búsqueda
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Chat Interno
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Calculadora de Precios
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Recibe tu link de propiedad en tu WhatsApp
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Califique y sea calificado
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        PDF Marca Blanca
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Coach Yo Rento Chatbot
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Menú exclusivo con avisos de demanda de inmuebles por zonas
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Precio preferencial para destacar propiedad
                                    </li>
                                </ul>


                            </div>
                        </div>


                        {/* Cuarta Columna */}

                        <div className="bg-gray-100 shadow-md rounded-lg border-solid border-purple-600 flex flex-col border-2 border-purple-800">
                            <div className="bg-purple-600 text-white p-5 text-center h-32">
                                <h3 className="text-xl font-bold mb-4">Paquetes Mixtos</h3>
                                <p>Tarifa Mensual</p>
                            </div>
                            <p className="text-center">*Precios por usuario</p>


                            {/* Publicación Individual */}
                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$1,999 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Membresía <br /> Premium</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                            Publica propiedades de
                                            Renta Anual/Larga Estancia o Renta Temporal o Vacacional de
                                            manera ilimitada durante 30 días
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Publicación Individual */}
                            <div className='flex items-center justify-between p-6 flex-col sm:flex-row sm:space-y-0 sm:space-x-4'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$349 <span className="text-sm">MXN</span></p>

                                {/* Container for nombre publi e icon */}
                                <div className="flex items-center space-x-2">
                                    <p className="text-center sm:text-left">Publicación <br /> Destacada</p>
                                    <div className="relative group">
                                        <FontAwesomeIcon icon={faInfoCircle} className="text-white bg-sky-300 rounded-full text-2xl" />

                                        {/* Tooltip */}
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none min-w-[150px] max-w-[300px]">
                                            Precio preferencial para
                                            destacar propiedades
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Servicios Incluidos en la parte inferior */}
                            <div className="p-6 mt-auto">
                                <hr className="border-2 border-yellow-200 mx-8 mb-4"></hr>
                                <h4 className="font-semibold">Servicios Incluidos:</h4>
                                <ul className="list-none pl-0">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Alertas Inteligentes de Búsqueda
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Chat Interno
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Calculadora de Precios
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Recibe tu link de propiedad en tu WhatsApp
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Califique y sea calificado
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        PDF Marca Blanca
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Coach Yo Rento Chatbot
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Menú exclusivo con avisos de demanda de inmuebles por zonas
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faMapMarkerCheck} className="text-white bg-green-400 rounded-full text-2xl p-1 mr-2" />
                                        Precio preferencial para destacar propiedad
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </section >

                {/* Otros serviciooooos*/}

                <section className="mb-16">
                    <h1 className="text-4xl font-bold text-center mb-8 text-purple-800">Otros servicios</h1>
                    <p className='text-center mb-4'>Ponemos a tu disposición los siguientes productos y servicios que te ayudaran a mejorar la seguridad y confianza en tus posibles operaciones</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {/* Columna 1 */}

                        <div className="bg-gray-100 shadow-md rounded-lg border-2 border-purple-800 sm: mx-2 md: mx-2">
                            <div className="bg-purple-600 text-white p-5 text-center w-full h-28">
                                <h3 className="text-xl font-bold mb-4">Investigación de <br></br> Profundidad</h3>
                            </div>

                            <div className='text-center p-5'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$1,299 <span className="text-sm">MXN</span></p>
                            </div>

                            <div className="p-6 text-center">
                                <p className="text-purple-900">Investigación y análisis detallado de inquilinos, que te ayudará a tomar decisiones informadas sobre quién rentará tu propiedad.</p>
                            </div>

                            <div className="p-5">
                                <p>*La investigación es válida por 30 días, y durante este tiempo, puedes compartirla con cualquier arrendador en Yo Rento.</p>
                            </div>
                        </div>

                        {/* Columna 2 */}

                        <div className="bg-gray-100 shadow-md rounded-lg border-2 border-purple-800 sm: mx-2 md: mx-2">
                            <div className="bg-purple-600 text-white p-5 text-center w-full h-28">
                                <h3 className="text-xl font-bold mb-2">Cita Segura</h3>
                                <p>Otorga una insignia de validación de identidad</p>
                            </div>

                            <div className='text-center p-5'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$99 <span className="text-sm">MXN</span></p>
                            </div>

                            <div className="p-6 text-center">
                                <p className="text-purple-900">Confirma de forma rápida y segura que tu prospecto es quien dice ser, brindándote más confianza para tu primera cita.</p>
                            </div>

                            <div className="p-5">
                                <p>*Si un arrendador la solicita, la validación de identidad será pagada por el inquilino por única ocasión.</p>
                            </div>
                        </div>

                        {/* Columna 3 */}

                        <div className="bg-gray-100 shadow-md rounded-lg border-2 border-purple-800 sm: mx-2 md: mx-2">
                            <div className="bg-purple-600 text-white p-5 text-center w-full h-28">
                                <h3 className="text-xl font-bold mb-2">Pase-Guro</h3>
                                <p>Otorga una insignia de validación de identidad</p>
                            </div>

                            <div className='text-center p-5'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$99 <span className="text-sm">MXN</span></p>
                            </div>

                            <div className="p-6 text-center">
                                <p className="text-purple-900">Obtén un perfilamiento del inquilino, con verificación de identidad y calificaciones previas en un mismo lugar, brindando confianza y seguridad al arrendador.</p>
                            </div>

                            <div className="p-5">
                                <p>*Producto recomendado para rentas temporales o vacacionales, duración de 6 meses.</p>
                            </div>
                        </div>

                        {/* Columna 4 */}

                        <div className="bg-gray-100 shadow-md rounded-lg border-2 border-purple-800 sm: mx-2 md: mx-2">
                            <div className="bg-purple-600 text-white p-5 text-center w-full h-28">
                                <h3 className="text-xl font-bold mb-2">Confía en lo que ves</h3>
                                <p>Otorga una insignia en la publicación</p>
                            </div>

                            <div className='text-center p-5'>
                                <p className="text-2xl font-semibold mb-4 sm:mb-0 text-sky-300">$499 <span className="text-sm">MXN</span></p>
                            </div>

                            <div className="p-6 text-center">
                                <p className="text-purple-900">Servicio de Validación de Anuncios, asegura que la información publicada sobre la propiedad es precisa y confiable, brindando mayor seguridad a los posibles inquilinos.</p>
                            </div>

                            <div className="p-5">
                                <p>*La duración de la validación es de 1 año, siempre y cuando no se actualice la publicación de la propiedad.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="mb-16 sm: mx-2 md: mx-2">
                    <h1 className="text-4xl font-bold text-center mb-8 text-purple-800">Anúnciate con nosotros</h1>

                    <div className="flex justify-center items-center w-full ">
                        <div className="bg-gray-100 shadow-md rounded-lg border-2 border-purple-800 w-full md:w-8/12 lg:w-4/12 mx-auto ">
                            <div className="bg-purple-600 text-white p-5 text-center w-full">
                                <h3 className="text-xl font-bold mb-4">Anuncios</h3>
                            </div>

                            <div className="p-6 text-center">
                                <p className='mb-5'>Contacta a atención a clientes para obtener más información sobre como anunciar tu negocio en nuestra plataforma.</p>
                                <a
                                    href="https://wa.me/5215654337226?text=Hola,%20tengo%20una%20duda%20sobre%20un%20inmueble%20en%20su%20página%20web."
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Enviar mensaje de WhatsApp"
                                >
                                    <FontAwesomeIcon icon={faWhatsapp} className='text-green-600 text-4xl cursor-pointer' />
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

            </div >
            <Footer />
        </>
    );
};

export default Nosotros;
