import axios from "./index";

export const addTickerSupport = async (data, token = null) => {
  return axios.post("/app/operation/tickets", { ...data }, { headers: { Authorization: `Bearer ${token}` } });
};

export const requestAssistence = async (data, token, id) => {
  return axios.post(
    `app/operation/tickets/properties/${id}`,
    { ...data },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
