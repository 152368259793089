import axios from "./index";

export const getSheetPayment = async (data, token = null) => {
  try {
    const response = await axios.post(
      `/app/payments/payment-sheet`,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const { paymentIntent, ephemeralKey, customer, setupIntent, paymentAttemptId } = response.data;

    return {
      paymentIntent,
      ephemeralKey,
      customer,
      setupIntent,
      paymentAttemptId
    };
  } catch (e) {
    console.log(e);
  }
};


export const setPropertyPlan = async (data, token = null) => {
  try {
    const { planId } = data;
    delete data.planId;
    const response = await axios.post(
      `/app/payments/plans/${planId}`,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    

    return response.status === 201;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getPlans = async (token = null) => {
  return axios.get(`/app/payments/memberships`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSheetPaymentInvestigation = async (token = null) => {
  try {
    const response = await axios.post(
      `/app/payments/investigation-sheet`,
      {
        plan_id: 3,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const { paymentIntent, ephemeralKey, customer } = response.data;

    return {
      paymentIntent,
      ephemeralKey,
      customer,
    };
  } catch (e) {
    console.log(e);
  }
};

export const confirmPropertyPayment = async (propertyId, paymentAttemptId, token = null) => {
  try {
    const response = await axios.put(
      `/app/operation/properties/${propertyId}`,
      {
        paymentAttemptId: paymentAttemptId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data; // Devuelve los datos de respuesta si es necesario
  } catch (error) {
    console.error('Error al actualizar el pago de la propiedad:', error);
    throw error; // Lanza el error para manejarlo donde se llame esta función
  }
};