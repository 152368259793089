import axios from "./index";

export const uploadDocuments = async (data, token = null) => {
  return axios.post("/app/operation/documents", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
