import { faAddressCard, faArrowLeft, faArrowRight, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import Radio from '../../../components/form/radio';

const UploadMain = ({ step, setStep, setDocumentsUpload, documentsUpload, contador }) => {
    const ref = useRef(null);
    const [name, setName] = useState("ine");
    const [selectedDocument, setSelectedDocument] = useState("");

    const onChangeDocument = (e) => {
        const file = e.target.files[0];
        setDocumentsUpload({
            ...documentsUpload,
            [name]: file,
        });

        e.target.value = null;
    };

    return (
        <>
            <input
                type="file"
                className="hidden"
                accept=".png, .jpg, .jpeg, .pdf"
                ref={ref}
                onChange={e => onChangeDocument(e)}
            />
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center bg-white p-8 rounded-lg w-full max-w-4xl"
            >
                <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Sube tu documentación</h3>

                {!selectedDocument && (
                    <div className="mb-8 text-center">
                        <span className="text-[14px] text-[#777]">Selecciona el tipo de documento:</span>
                        <div className="flex justify-center gap-x-6 mt-4">
                            <button
                                onClick={() => setSelectedDocument("ine")}
                                className="text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 px-4 py-2 rounded-full transition duration-300 mr-2"
                            >
                                INE
                            </button>
                            <button
                                onClick={() => setSelectedDocument("passport")}
                                className="text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 px-4 py-2 rounded-full transition duration-300 ml-2"
                            >
                                Pasaporte
                            </button>
                        </div>
                    </div>
                )}

                {selectedDocument === "ine" && (
                    <div className="mb-[4rem]">
                        <span className="text-[14px] text-[#777] text-center">
                            Añade la foto de las dos partes de tu INE
                        </span>
                        <div className="mt-4">
                            <div className="flex items-center gap-x-3 mb-4">
                                <FontAwesomeIcon icon={faAddressCard} />
                                <label className="text-[14px] flex items-center text-black">
                                    INE Frente
                                    <span className={`${!documentsUpload.ine ? 'text-red-500' : 'text-green-500'} ml-2`}>
                                        - {!documentsUpload.ine ? '(Pendiente de carga)' : 'Cargado'}
                                    </span>
                                </label>
                            </div>
                            <div className="w-full px-2 flex justify-between items-center mb-4">
                                <Radio
                                    label="INE"
                                    name="radio-document"
                                    value="ine"
                                    checked={documentsUpload.ine}
                                />
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="flex items-center text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-4 py-2 rounded-full"
                                    onClick={() => {
                                        setName("ine");
                                        ref.current?.click();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Añadir
                                </motion.button>
                            </div>
                            <div className="flex items-center gap-x-3 mb-4">
                                <FontAwesomeIcon icon={faAddressCard} />
                                <label className="text-[14px] flex items-center text-black">
                                    INE Atrás
                                    <span className={`${!documentsUpload.ine_back ? 'text-red-500' : 'text-green-500'} ml-2`}>
                                        - {!documentsUpload.ine_back ? '(Pendiente de carga)' : 'Cargado'}
                                    </span>
                                </label>
                            </div>
                            <div className="w-full px-2 flex justify-between items-center mb-4">
                                <Radio
                                    label="INE Atrás"
                                    name="radio-document"
                                    value="ine_back"
                                    checked={documentsUpload.ine_back}
                                />
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="flex items-center text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-4 py-2 rounded-full"
                                    onClick={() => {
                                        setName("ine_back");
                                        ref.current?.click();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Añadir
                                </motion.button>
                            </div>
                        </div>
                    </div>
                )}

                {selectedDocument === "passport" && (
                    <div className="mb-[4rem]">
                        <span className="text-[14px] text-[#777] text-center">Añade la foto de tu pasaporte</span>
                        <div className="mt-6">
                            <div className="flex items-center gap-x-3 mb-4">
                                <FontAwesomeIcon icon={faAddressCard} />
                                <label className="text-[14px] flex items-center text-black">
                                    Pasaporte
                                    <span className={`${!documentsUpload.passport ? 'text-red-500' : 'text-green-500'} ml-2`}>
                                        - {!documentsUpload.passport ? '(Pendiente de carga)' : 'Cargado'}
                                    </span>
                                </label>
                            </div>
                            <div className="w-full px-2 flex justify-between items-center mb-4">
                                <Radio
                                    label="Pasaporte"
                                    name="radio-document"
                                    value="passport"
                                    checked={documentsUpload.passport}
                                />
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="flex items-center text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-4 py-2 rounded-full ml-3"
                                    onClick={() => {
                                        setName("passport");
                                        ref.current?.click();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    Añadir
                                </motion.button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="w-full flex justify-between items-center mt-8">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                        onClick={() => setStep(step - 1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Regresar
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={`flex items-center text-white ${(selectedDocument === 'ine' && documentsUpload.ine && documentsUpload.ine_back) || (selectedDocument === 'passport' && documentsUpload.passport) ? 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700' : 'bg-gray-300 cursor-not-allowed'} transition duration-300 px-6 py-3 rounded-full`}
                        onClick={() => {
                            if ((selectedDocument === 'ine' && documentsUpload.ine && documentsUpload.ine_back) || (selectedDocument === 'passport' && documentsUpload.passport)) {
                                setStep(step + 1);
                            }
                        }}
                        disabled={!((selectedDocument === 'ine' && documentsUpload.ine && documentsUpload.ine_back) || (selectedDocument === 'passport' && documentsUpload.passport))}
                    >
                        Continuar
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </motion.button>
                </div>
            </motion.div>
        </>
    );
};

export default UploadMain;
