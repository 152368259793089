import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import DrawerProperty from '../../../assets/drawe-caracteristicas.png';

const PropertyInfo = ({ step, setStep, contador, isEditing }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full"
        >
            {contador > 0 && (
                <div className="flex items-center justify-center mb-6">
                </div>
            )}
            <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Selecciona las características de tu inmueble</h3>
            <img src={DrawerProperty} alt="add propertie" className="w-[18rem] mb-6" />
            <span className="text-[14px] text-center mb-6">
                Selecciona todas las características que tiene tu inmueble de la lista que se mostrará a continuación.
            </span>

            <div className="flex flex-col-reverse md:flex-row justify-between items-center gap-4 w-full mt-4">
                {!isEditing && (
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full md:w-auto flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                        onClick={() => setStep(step - 1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Regresar
                    </motion.button>
                )}

                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setStep(step + 1)}
                    className="w-full md:w-auto flex items-center text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition duration-300 px-6 py-3 rounded-full"
                >
                    Seleccionar características
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>

        </motion.div >
    );
};

export default PropertyInfo;
