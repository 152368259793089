import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate
} from "react-router-dom"; // Importa BrowserRouter
import { getMeUserInfo, getNotifications } from "../services/auth.service";

const LayoutHome = ({ children, token = "", chat = false }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();

  const getDataNotification = async () => {
    try {
      const resp = await getNotifications(token);
      if (resp.data) {
        setNotifications(resp.data.notifications);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMyInfo = async () => {
    const resp = await getMeUserInfo(token);
    if (resp.data) {
      setUserInfo(resp.data.user);
    }
  };

  useEffect(() => {
    if (token) {
      getMyInfo();
      getDataNotification();
    }
  }, [token]);

  return (
    <>

      {/* <div className="bg-[#F0F4F5] h-[92px]">
        <div className="px-3 mx-auto py-2 gap-4 lg:max-w-7xl md:items-center"> <h2 className="text-center text-[24px]">Mi cuenta</h2> */}
      {/* {userInfo &&
          userInfo.roles &&
          userInfo.roles?.find((r) => r === "estate_agency") ? (
            <div className="bg-[#FFC9AA] border rounded border-[#6C3400] p-1 !max-w-[20vh] mx-auto">
              <div className="text-center text-[#6C3400] text-[14px]">
                Agente
              </div>
            </div>
          ) : userInfo.roles && userInfo.roles.includes("tenant") ? (
            <div className="bg-[#BEE3F8] border rounded border-[#3182CE] p-1 !max-w-[20vh] mx-auto my-2">
              <div className="text-center text-[#3182CE] text-[14px]">
                Arrendador(a)
              </div>
            </div>
          ) : null} */}
      {/* <div
            className={
              "flex flex-row justify-end mb-2 " +
              (userInfo.roles && userInfo.roles.includes("tenant")
                ? ""
                : "mt-6")
            }
          > */}
      {/* <Button
              onClick={() => navigate("/admin/notificaciones")}
              className="!max-w-[32vh] !py-[0px] !px-[0px] !h-auto !bg-[#fff] border border-[#D0CFCF] !text-[#000] mr-4 -mt-9 realtive"
            >
              {notifications.length > 0 && (
                <div className="h-3 w-3 bg-[#FFC804] rounded-xl absolute -mt-2 -ml-2" />
              )}
              <FontAwesomeIcon icon={faBell} className="mr-2" />
              Notificaciones
            </Button> */}
      {/* <Button
              onClick={() => navigate("/admin/chats")}
              className={`!w-[16vh] !py-[0px] !px-[0px] !bg-[#fff] border border-[#D0CFCF] !text-[#000] -mt-9 ${
                chat && "border !border-[#0085BC] !bg-[#DFF5FE] !text-[#0085BC]"
              }`}
            >
              <FontAwesomeIcon icon={faComment} className="mr-2" />
              Chats
            </Button> */}
      {/* </div>
        </div>
      </div> */}

      <div className="w-full">{children}</div>
    </>
  );
};

export default LayoutHome;
