import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { getReferrals } from '../../services/auth.service';
import Spinner from '../../components/Loader/Spinner';
import PersonaSentada from '../../assets/drawe-chat-unauth.png';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const MyReferrals = ({ token }) => {
    const [referrals, setReferrals] = useState([]);
    const [referralCode, setReferralCode] = useState('');
    const [totalReferrals, setTotalReferrals] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchReferrals = async () => {
        try {
            setIsLoading(true);
            const response = await getReferrals(token);
            console.log(response);
            if (response.data?.referals) {
                setReferralCode(response.data.referalCode);
                setTotalReferrals(response.data.total);
                setReferrals(response.data.referals);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReferrals();
    }, []);

    const handleShareCode = () => {
        navigator.clipboard.writeText(`Únete a la comunidad de Yo Rento con mi código de referido: ${referralCode}`);
        alert('Código copiado al portapapeles');
    };

    return (
        <div className="w-full min-h-[40vh] mt-5 p-4 bg-white rounded-xl shadow-md">
            <h1 className="text-[24px] text-purple-800 text-center font-semibold">Referidos</h1>
            <div className="text-center mt-4">
                <p className="text-gray-700">
                    Comparte tu código de referido: <span className="font-bold">{referralCode}</span>
                </p>
                <button
                    onClick={handleShareCode}
                    className="mt-2 px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition"
                >
                    <FontAwesomeIcon icon={faCopy} className="mr-2" />
                    Compartir
                </button>
                <p className="mt-4 text-gray-700">Referidos: <span className="font-bold">{totalReferrals}</span></p>
            </div>

            <AnimatePresence>
                {isLoading ? (
                    <div className="flex justify-center items-center mt-8">
                        <Spinner />
                    </div>
                ) : referrals.length > 0 ? (
                    referrals.map((referral) => (
                        <motion.div
                            key={referral.id}
                            className="flex items-center gap-4 p-4 my-4 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={fadeUpVariants}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="w-12 h-12 bg-gray-200 rounded-full flex-shrink-0 overflow-hidden">
                                {referral.profile_picture ? (
                                    <img
                                        src={referral.profile_picture}
                                        alt={`${referral.name}'s profile`}
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center bg-gray-300 text-gray-600">
                                        {referral.name[0]}
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3 className="font-semibold text-gray-800">{referral.name}</h3>
                                <p className="text-sm text-gray-500">Código: {referral.code}</p>
                                <p className="text-sm text-gray-500">
                                    Fecha: {new Date(referral.created_at).toLocaleDateString()}
                                </p>
                            </div>
                        </motion.div>
                    ))
                ) : (
                    <motion.div
                        key="no-referrals"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={fadeUpVariants}
                        transition={{ duration: 0.5 }}
                        className="text-center text-gray-500 mt-8 flex flex-col items-center"
                    >
                        <p className="font-bold text-xl">No tienes referidos</p>
                        <div className="flex justify-center mt-4">
                            <img src={PersonaSentada} className="mx-auto" alt="Persona sentada" />
                        </div>
                    </motion.div>

                )}
            </AnimatePresence>
        </div>
    );
};

export default MyReferrals;