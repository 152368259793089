import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'; // Importa el icono de check
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Modal from "../../components/modals/modal"; // Asegúrate de que la ruta del modal sea correcta
import { getTags as fetchTagsFromAPI, postTag } from "../../services/chats.service"; // Ajusta la importación según sea necesario

const TagModal = ({ isOpen, onClose, token, chatId }) => {
  const [tags, setTags] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState(null); // Estado para manejar el tag seleccionado

  useEffect(() => {
    if (isOpen) {
      fetchTags();
      setSelectedTagId(null); // Reiniciar el estado del tag seleccionado
    }
  }, [isOpen]);

  const fetchTags = async () => {
    try {
      const resp = await fetchTagsFromAPI(token);
      if (resp.data.tags) {
        setTags(resp.data.tags);
      } else {
        setTags([]);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
      setTags([]);
    }
  };

  const postTags = async (idTag, tag, chatId, token = null) => {
    try {
      const resp = await postTag(idTag, chatId, token);
      // Marca el tag como seleccionado
      setSelectedTagId(idTag);
      setTimeout(() => {
        onClose(tag); // Cierra el modal después de mostrar el éxito
      }, 2000); // Tiempo antes de cerrar el modal
    } catch (error) {
      console.error("Error posting tag:", error);
      setTags([]);
    }
  };

  const handleTagClick = (tag) => {
    postTags(tag.id, tag, chatId, token); // Marca el tag seleccionado
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            className="p-4 flex flex-col align-end justify-center"
          >
            <h2 className="text-xl font-bold text-gray-600 mb-4">Selecciona un Tag</h2>
            <ul>
              {tags.length > 0 ? (
                tags.map((tag) => (
                  <motion.li
                    key={tag.id}
                    onClick={() => handleTagClick(tag)}
                    className="p-2 border-b border-gray-200 cursor-pointer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="flex items-center">
                      {/* Si el tag está seleccionado, muestra el check */}
                      {selectedTagId === tag.id ? (
                        <motion.div
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 0.5, ease: "easeInOut" }}
                          className="w-4 h-4 rounded-full mr-2 text-green-500"
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </motion.div>
                      ) : (
                        <div
                          className="w-4 h-4 rounded-full mr-2"
                          style={{ backgroundColor: `#${tag.color}` }}
                        ></div>
                      )}

                      {/* Primer renglón: nombre del tag */}
                      <span className={`font-semibold ${selectedTagId === tag.id ? 'text-green-500' : ''}`}>
                        {tag.name}
                      </span>
                    </div>

                    {/* Segundo renglón: descripción que cambia cuando el tag está seleccionado */}
                    <p className={`text-sm mt-1 ${selectedTagId === tag.id ? 'text-green-500' : 'text-gray-600'}`}>
                      {selectedTagId === tag.id ? 'Agregado con éxito' : tag.description}
                    </p>
                  </motion.li>
                ))
              ) : (
                <li>No tags</li>
              )}
            </ul>
            <button
              onClick={() => onClose(null)}
              className="mt-4 ml-auto mr-0 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cerrar
            </button>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default TagModal;
