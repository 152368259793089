import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from "../components/Loader";
import NavBar from '../components/header/Navbar';
import obtenerPropiedades from '../components/map/obtenerPropiedades';
import usePropiedadesDestacadas from '../hooks/usePropiedadesDestacadas';
import '../index.css';
import WelcomeModal from '../components/modals/modal-welcome';

const ListaExpandiblePropiedades = lazy(() => import('../components/map/ListaExpandiblePropiedades'));
const Mapa = lazy(() => import('../components/map/Mapa'));

const MapPage = () => {
  const [navHeight, setNavHeight] = useState(0);
  const [zonaVisible, setZonaVisible] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [buscando, setBuscando] = useState(false); // Nuevo estado para manejar la búsqueda en curso
  const [searchId, setSearchId] = useState(null);
  const [filtros, setFiltros] = useState({
    ubicacionBusqueda: null,
    rangoPrecios: [0, 100000],
    antiguedad: null,
    tiposSeleccionados: [],
    bestAmenidadesSeleccionados: [],
    tiposPropiedadSeleccionados: [],
    habitacionesSeleccionadas: null,
    banosSeleccionados: null,
    cochesSeleccionados: null,
    mascotasSeleccionadas: null,
    niñosSeleccionados: null,
    direccionFormateada: '',
    houses: null,
    departaments: null,
    colivings: null,
    publishedFrom: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    tipoRenta: [/*1, 2, 3*/],
  });

  const token = useSelector(state => state.auth.token);

  const [propiedadesRecomendadas, setPropiedadesRecomendadas] = useState([]);
  const propiedadesDestacadas = usePropiedadesDestacadas(filtros, token);

  useEffect(() => {
    const navbar = document.querySelector('nav');
    setNavHeight(navbar.offsetHeight);
  }, []);

  // Función para actualizar las propiedades recomendadas
  const actualizarPropiedadesRecomendadas = useCallback(async () => {
    setBuscando(true); // Iniciamos la búsqueda
    try {
      const resp = await obtenerPropiedades(filtros, token);

      if (typeof resp !== 'undefined') {
        const propiedades = resp.properties ?? [];
        setSearchId(resp.searchId);
        if (!propiedades) {
          setPropiedadesRecomendadas([]);
        } else {
          const recomendadas = propiedades.map(prop => {
            const imagen_frontal = prop.images.find(img => img.front_page) || prop.images[0];
            const imagen = imagen_frontal.image;
            return { ...prop, imagen };
          });
          setPropiedadesRecomendadas(recomendadas);
        }

        setBuscando(false); // Finalizamos la búsqueda
      }
    } catch (error) {
      console.error('Error al obtener propiedades recomendadas:', error);
    }
  }, [filtros]);

  // Actualizamos los filtros cuando cambia la zona visible
  useEffect(() => {
    if (zonaVisible) {
      setFiltros(prevFiltros => ({
        ...prevFiltros,
        ...zonaVisible // Actualizamos los filtros con la zona visible
      }));
    }
  }, [zonaVisible]);

  // Cuando los filtros cambian, actualizamos las propiedades recomendadas
  useEffect(() => {
    if (filtros) {
      actualizarPropiedadesRecomendadas();
    }
  }, [filtros, actualizarPropiedadesRecomendadas]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="relative h-screen bg-gray-100">
      <NavBar />
      <div className="flex relative" style={{ height: `calc(100vh - ${navHeight}px)` }}>
        <Suspense fallback={<Loader />}>
          <Mapa
            setZonaVisible={setZonaVisible}
            expanded={expanded}
            datosPropiedades={propiedadesRecomendadas}
            filtros={filtros}
            setFiltros={setFiltros}
            buscando={buscando} // Pasamos el estado de búsqueda
          />
          <WelcomeModal />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <ListaExpandiblePropiedades
            searchId={searchId}
            propiedadesDestacadas={propiedadesDestacadas}
            propiedadesRecomendadas={propiedadesRecomendadas}
            expanded={expanded}
            toggleExpand={toggleExpand}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default MapPage;