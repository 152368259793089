import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth-action";
import RootLayout from "../../components/Layout";
import Breadcrumb from "../../components/breadcrumb";
import TextInput from "../../components/form/textinput";
import Button from "../../components/form/button";
import { sendForgotPassword, updatePasswordForgotPassword, validateCodeForgotPassword } from "../../services/auth.service";
import Icon from "../../assets/isotipo.png";
import DrawerPc from "../../assets/drawe-person-pc.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import Spinner from "../../components/Loader/Spinner";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [steps, setSteps] = useState(0);
  const [token, setToken] = useState("");

  const items = {
    0: <EmailStep setEmail={setEmail} setSteps={setSteps} steps={steps} />,
    1: <StepInfoPrevCod email={email} setSteps={setSteps} steps={steps} />,
    2: <CodeStep email={email} setSteps={setSteps} steps={steps} setToken={setToken} />,
    3: <PasswordStep token={token} setSteps={setSteps} steps={steps} email={email} />
  }

  return (
    <RootLayout>
      <div className="flex flex-col">
        <div className="py-[12px]">
          <Breadcrumb options={[{ name: "Iniciar sesión", link: "/auth/login" }]} />
        </div>
        <div className="grid grid-cols-3 gap-4 justify-center">
          <div className="lg:col-span-1"></div>
          <div className="lg:col-span-1">
            {items[steps]}
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

const EmailStep = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = async () => {
    setIsLoading(true);
    try {
      const resp = await sendForgotPassword({ email });
      if (resp.data) {
        props.setEmail(email);
        props.setSteps(1);
      }
    } catch (e) {
      console.log(e);
      alert("Tenemos inconvenientes en el servicio, vuelve a intertarlo");
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        ¿Has olvidado tu contraseña?
      </h2>
      <p className="text-center mb-12">
        Ingresa el correo electrónico con el que te has registrado y sigue las instrucciones
      </p>
      <div className="mb-[39px]">
        <TextInput
          placeholder="Correo electrónico"
          value={email}
          type={'search'}
          onChange={(e) => setEmail(e.target.value)}
          styleInput={{ marginBottom: 20 }}
        />
      </div>

      {isLoading ? (<div className="flex items-center justify-center" ><Spinner /></div>) : (<Button disabled={!email} onClick={forgotPassword} right>Enviar</Button>)}
    </div>
  )
}

const StepInfoPrevCod = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = async () => {
    setIsLoading(true);
    try {
      const resp = await sendForgotPassword({ email: props.email });
      if (resp.data) {
        props.setSteps(1);
      }
    } catch (e) {
      console.log(e);
      alert("Tenemos inconvenientes en el servicio, vuelve a intertarlo");
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-[4rem]">
      <div className="w-full flex justify-center mb-[6rem]">
        <img src={Icon} alt="icon" className="w-[32px]" />
      </div>
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Revisa tu correo electrónico
      </h2>
      <div className="w-full flex justify-center mb-[2rem]">
        <img src={DrawerPc} className="w-72" alt="" />
      </div>
      <p className="text-center mb-12">
        Para poder crear una nueva contraseña es necesario que introduzcas el código que se te envió a tu correo.
      </p>

      <Button onClick={() => props.setSteps(2)}>Introducir código</Button>
    </div>
  )
}

const CodeStep = (props) => {
  const [code, setCode] = useState("");
  const [securePass, setSecurePass] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = async () => {
    setIsLoading(true);
    try {
      const resp = await validateCodeForgotPassword({ code, email: props.email });
      if (resp.data) {
        props.setToken(resp.data.token);
        props.setSteps(3);
      }
    } catch (e) {
      console.log(e);
      alert("Tenemos inconvenientes en el servicio, vuelve a intertarlo");
    }

  };
  const resendCode = async () => {
    setIsLoading(true);
    try {
      const resp = await sendForgotPassword({ email: props.email });
      if (resp.data) {
        console.log("Code resent successfully");
      }
    } catch (e) {
      console.log(e);
      alert("Tenemos inconvenientes en el servicio, vuelve a intentarlo");
    }
    finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className="w-full flex justify-center mb-[6rem]">
        <img src={Icon} alt="icon" className="w-[32px]" />
      </div>
      <h2 className="text-[#4C44ED] text-[20px] font-semibold text-center mb-10">
        Por favor introduce el código solicitado
      </h2>
      <div className="relative mb-[39px]">
        <TextInput
          placeholder=""
          type={securePass ? "password" : "text"}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          styleInput={{ marginBottom: 20 }}
        />
        {securePass ? (
          <FontAwesomeIcon
            icon={faEye}
            style={{ position: "absolute", right: 10, top: 14 }}
            onClick={() => setSecurePass(!securePass)}
            className="cursor-pointer"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            style={{ position: "absolute", right: 10, top: 14 }}
            onClick={() => setSecurePass(!securePass)}
            className="cursor-pointer"
          />
        )}
      </div>
      <Button disabled={!code} onClick={forgotPassword} right>Continuar</Button>

      {isLoading ? (<div className="flex items-center justify-center mt-[9rem]" ><Spinner /></div>) :
        (<p className="text-center text-[#777] mt-[9rem] cursor-pointer" onClick={() => resendCode()}>
          Enviar código de nuevo
        </p>)}
    </div>
  )
}

const PasswordStep = (props) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [securePass, setSecurePass] = useState({
    newPass: true,
    confirmNewPass: true
  })
  const [matchVerified, setMatchVerified] = useState({
    mayuscula: null,
    numero: null,
    especial: null,
    tamano: null,
  });
  const [percentage, setPercentage] = useState(0);
  const verifiedRegexPassword = () => {
    setMatchVerified({
      mayuscula: password.match(/[A-Z]/g) !== null,
      numero: password.match(/[0-9]/g) !== null,
      especial:
        password.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== null,
      tamano: password.length > 7,
    });
  };

  const getPercentage = () => {
    let complete = 0;
    const verify = Object.values(matchVerified);
    complete = verify.filter((v) => v).length;
    setPercentage((complete * 25) / 100);
  };

  useEffect(() => {
    verifiedRegexPassword();
  }, [password]);

  useEffect(() => {
    getPercentage();
  }, [password, matchVerified]);

  const onSubmitPasswordForgot = async () => {
    setIsLoading(true);
    try {
      await updatePasswordForgotPassword({ password }, props.token);
      navigate("/auth/login");
    } catch (e) {
      console.log(e);
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div>
        {percentage * 100 === 100 &&
          <div className="flex flex-row items-center justify-center p-2 gap-x-2 mb-[10px] bg-[#CFF1FF] border border-[#0097DF] rounded-[4px] min-w-[20%]">
            <p className="text-[#0097DF]">Verificación exitosa</p>
            <FontAwesomeIcon icon={faCheckCircle} color="#0097DF" />
          </div>
        }
      </div>
      <h2 className="text-#4C44ED text-[20px] font-semibold text-center mb-10">
        Nueva contraseña
      </h2>
      <div className="relative mb-[68px]">
        <p className="text-center mb-4 mt-12">Crea una contraseña</p>
        <TextInput
          placeholder="Contraseña"
          type={securePass.newPass ? "password" : "text"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {securePass.newPass ? (
          <FontAwesomeIcon
            icon={faEye}
            style={{ position: "absolute", right: 10, top: 55 }}
            onClick={() => setSecurePass({ ...securePass, newPass: !securePass.newPass })}
            className="cursor-pointer"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            style={{ position: "absolute", right: 10, top: 55 }}
            onClick={() => setSecurePass({ ...securePass, newPass: !securePass.newPass })}
            className="cursor-pointer"
          />
        )}

        <p className="text-center mb-4 mt-12">Confirma tu contraseña</p>
        <TextInput
          placeholder="Confirma tu contraseña"
          type={securePass.confirmNewPass ? "password" : "text"}
          value={confirmPass}
          onChange={(e) => setConfirmPass(e.target.value)}
        />
        {securePass.confirmNewPass ? (
          <FontAwesomeIcon
            icon={faEye}
            style={{ position: "absolute", right: 10, top: 185 }}
            onClick={() => setSecurePass({ ...securePass, confirmNewPass: !securePass.confirmNewPass })}
            className="cursor-pointer"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            style={{ position: "absolute", right: 10, top: 185 }}
            onClick={() => setSecurePass({ ...securePass, confirmNewPass: !securePass.confirmNewPass })}
            className="cursor-pointer"
          />
        )}
      </div>
      <div className="mb-[4rem]">
        <Progress percent={percentage * 100} strokeWidth={1} theme={{
          active: {
            color: '#30D45E'
          },
          success: {
            symbol: "100%",
            color: '#30D45E'
          }
        }} />

        <div className="flex flex-row items-center gap-x-3 py-1 mt-2">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.tamano ? "#30D45E" : "#777"}
          />
          <p>Al menos 8 caracteres</p>
        </div>
        <div className="flex flex-row items-center gap-x-3 py-1">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.mayuscula ? "#30D45E" : "#777"}
          />
          <p>Al menos una mayúscula</p>
        </div>
        <div className="flex flex-row items-center gap-x-3 py-1">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.numero ? "#30D45E" : "#777"}
          />
          <p>Al menos un números</p>
        </div>
        <div className="flex flex-row items-center gap-x-3 py-1">
          <FontAwesomeIcon
            icon={faCheck}
            size={18}
            color={matchVerified.especial ? "#30D45E" : "#777"}
          />
          <p>Al menos un carácter especial</p>
        </div>
      </div>

      {isLoading ? (<div className="flex items-center justify-center" ><Spinner /></div>) : (<Button disabled={!password || confirmPass !== password || percentage * 100 !== 100} onClick={onSubmitPasswordForgot} right>Continuar</Button>)}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.auth.isLoading,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (data, token = null) => dispatch(actions.loginSuccess(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
