import axios from "./index";


export const trackEvent = (path) => {

    const domainName = window.location.hostname;

    return axios.post('/app/core/tracking', {
        'route_name': path,
        'args': null,
        'platform': "WEB",
        'package_name': domainName,
        'app_version': new Date(),
        'app_build': null,
        'screens': null,
    });
}