import { faChevronRight, faHome, faTimes, faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import womanPayment from '../../assets/drawe-woman-payment.png';
import { getCandidatosRentar, getPropertieStatus, getRentsDurations, updatePropertieStatus } from '../../services/properties.service';
import RenterEvaluation from '../evaluations/RenterEvaluation';

const PropertyStatusModal = ({ isOpen, setIsOpen, property }) => {
    //console.log(property);
    const [step, setStep] = useState(1);
    const [candidatos, setCandidatos] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [rentsDurations, setRentsDurations] = useState([]);
    const [isPropertyAvailable, setIsPropertyAvailable] = useState(property.is_available);
    const [isInvestigationRequested, setIsInvestigationRequested] = useState(false);
    const [isRentedInternal, setIsRentedInternal] = useState(false);
    const [rentedBy, setRentedBy] = useState(null);
    const [isExternalRent, setIsExternalRent] = useState(false);
    const [canEvaluateRenter, setCanEvaluateRenter] = useState(false);
    const { token } = useSelector(state => state.auth);
    const [rentHistoryId, setRentHistoryId] = useState(null);

    useEffect(() => {
        if (!isOpen) {
            resetModal(); // Resetear modal al cerrarlo
            return;
        }

        const fetchData = async () => {
            try {
                const [respRents, respCandidatos] = await Promise.all([
                    getRentsDurations(token),
                    getCandidatosRentar(property.id, token)
                ]);

                if (respRents.data.message) {
                    setRentsDurations(respRents.data.durationTypes);
                }

                setCandidatos(respCandidatos.data.total.length > 0 ? respCandidatos.data.chatRooms : []);
                await reloadPropertyStatus(); // Cargar el estado de la propiedad al abrir el modal
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [token, property.id, isOpen]);

    // Recargar el estado de la propiedad
    const reloadPropertyStatus = async () => {
        try {
            const propertyStatus = await getPropertieStatus(property.id, token);
            if (propertyStatus.data) {

                if (propertyStatus.data.rentHistoryId) {
                    setRentHistoryId(propertyStatus.data.rentHistoryId);
                }

                setIsPropertyAvailable(propertyStatus.data.propertyStatus.status === 'available');

                if (propertyStatus.data.propertyStatus.status !== 'available') {
                    if (propertyStatus.data.propertyStatus.rented_by_external) {
                        setIsExternalRent(true); // Si está rentada externamente
                    } else {
                        setIsRentedInternal(true); // Si está rentada con "Yo Rento"
                        setRentedBy(propertyStatus.data.propertyStatus.rented_by); // Guardar el inquilino actual
                    }
                }
            }
        } catch (error) {
            console.error('Error al recargar el estado de la propiedad:', error);
        }
    };

    // Resetea el modal al cerrarlo
    const resetModal = () => {
        setStep(1);
        setCandidatos([]);
        setSelectedCandidate(null);
        setSelectedDuration(null);
        setIsRentedInternal(false);
        setIsExternalRent(false);
        setRentedBy(null);
    };

    // Cierra el modal
    const closeModal = () => setIsOpen(false);

    // Cambia la disponibilidad de la propiedad y recarga los datos
    const handleAvailabilityChange = async () => {
        const newStatus = isPropertyAvailable;
        setIsPropertyAvailable(newStatus);

        let payload = {};

        if (newStatus) {
            payload = {
                is_rented: true,
                rented_by_external: true
            };
        } else {
            payload = {
                is_rented: false,
            };
        }

        console.log(payload);

        resetModal(); // Resetea el modal al cambiar la disponibilidad

        try {
            await updatePropertieStatus(property.id, payload, token);
            await reloadPropertyStatus(); // Recarga el estado de la propiedad
        } catch (error) {
            console.error('Error al actualizar el estado de la propiedad:', error);
        }
    };

    // Maneja la selección de un candidato para alquilar
    const handleCandidateSelection = (candidate) => {
        setSelectedCandidate(candidate);
        setStep(3);
    };

    // Maneja la selección de la duración del alquiler
    const handleDurationSelection = async (duration) => {
        setSelectedDuration(duration.id);

        const payload = {
            is_rented: true,
            renter_id: selectedCandidate ? selectedCandidate.user_id : null,
            rent_duration_id: duration.id,
            rented_by_external: selectedCandidate ? false : true
        };

        try {
            await updatePropertieStatus(property.id, payload, token);
            closeModal();
        } catch (error) {
            console.error('Error al actualizar el estado de la propiedad:', error);
        }
    };

    // Envía al usuario a la evaluación del inquilino
    const handleEvaluationClick = () => {
        setStep(4); // Cambia a la vista de evaluación
    };

    // Vuelve al paso anterior
    const goBack = () => {
        setStep(step - 1);
    };

    if (!isOpen) return null;

    console.log(property);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60"
        >
            <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center px-4 py-3">
                    {step > 1 && (
                        <button onClick={goBack} className="text-gray-400 hover:text-gray-600 transition text-sm">
                            <FontAwesomeIcon icon={faChevronRight} size="lg" className="rotate-180" />
                        </button>
                    )}
                    <button onClick={closeModal} className="text-gray-400 hover:text-gray-600 transition ml-auto text-md">
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                </div>

                <div className="flex justify-center p-4">
                    <img src={womanPayment} alt="Payment Illustration" className="w-32 h-auto mb-4" />
                </div>

                {/* Vista principal */}
                {step === 1 && (
                    <div className="text-center px-6 py-4">
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">
                            {isRentedInternal || isExternalRent
                                ? 'Propiedad actualmente rentada' // Cambia el título si ya está rentada
                                : '¿Cómo rentaste tu propiedad?'}
                        </h3>
                        <div className={`${(isRentedInternal || isExternalRent) ? 'flex justify-center' : `grid grid-cols-2`} gap-4 mb-4`}>
                            {/* Primer botón: Yo Rento */}
                            {isRentedInternal && rentedBy ? (
                                <div className="flex flex-col mx-auto items-center bg-white p-6 rounded-xl shadow-lg">
                                    <img
                                        src={rentedBy.profile_picture} // Foto del inquilino
                                        alt={rentedBy.name}
                                        className="w-16 h-16 rounded-full object-cover mb-4"
                                    />
                                    <h4 className="text-lg font-semibold text-gray-900">{rentedBy.name}</h4>
                                    <span className="text-gray-500 text-sm">{rentedBy.email}</span>
                                    <button
                                        onClick={handleEvaluationClick}
                                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition"
                                    >
                                        Evaluar inquilino
                                    </button>
                                </div>
                            ) : (
                                !isExternalRent && ( // Solo muestra el botón si no está rentada externamente
                                    <button
                                        className="flex flex-col items-center py-4 text-blue-600 border border-blue-600 rounded-full hover:bg-blue-50 transition"
                                        onClick={() => setStep(2)} // Si no está rentada, pasa a seleccionar candidato
                                    >
                                        <FontAwesomeIcon icon={faHome} className="text-3xl mb-2" />
                                        <span>Renté a un usuario de Yo Rento</span>
                                    </button>
                                )
                            )}

                            {/* Segundo botón: Renté Externamente */}
                            {!isRentedInternal && ( // Solo muestra el botón si no está rentada internamente
                                <button
                                    className="flex flex-col items-center p-4 text-red-600 border border-red-600 rounded-full hover:bg-red-50 transition"
                                    onClick={() => {
                                        if (!isExternalRent) {
                                            setStep(3); // Si no está rentada externamente, pasa a seleccionar duración
                                        }
                                        // Si está rentada externamente, no hace nada
                                    }}
                                >
                                    <FontAwesomeIcon icon={faUserTie} className="text-3xl mb-2" />
                                    <span>{isExternalRent ? "Rentada externamente" : "Renté externamente"}</span>
                                </button>
                            )}
                        </div>
                        {/* Switch para marcar como disponible */}
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="px-6 py-4 space-y-"
                        >
                            <div className="flex justify-between items-center">
                                <span className="text-gray-800">La propiedad se encuentra disponible</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" className="sr-only" onChange={handleAvailabilityChange} checked={isPropertyAvailable} />
                                    <div
                                        className={`w-11 h-6 bg-gray-200 rounded-full shadow-inner ${isPropertyAvailable ? 'bg-green-500' : 'bg-gray-300'} transition`}
                                    ></div>
                                    <span
                                        className={`dot absolute left-1 top-[2px] w-5 h-5 bg-white rounded-full shadow transform transition ${isPropertyAvailable ? 'translate-x-5' : ''}`}
                                    ></span>
                                </label>
                            </div>
                        </motion.div>
                    </div>
                )}

                {/* Selección de candidatos (Paso 2) */}
                {step === 2 && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="px-6 py-4 space-y-4"
                    >
                        <h3 className="text-center text-gray-900 mb-4">Selecciona un candidato para rentar</h3>
                        {candidatos.length > 0 ? (
                            candidatos.map((candidate) => (
                                <div
                                    key={candidate.id}
                                    className="flex items-center justify-between w-full p-3 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition cursor-pointer"
                                    onClick={() => handleCandidateSelection(candidate)}
                                >
                                    <div className="flex items-center space-x-3">
                                        <img
                                            src={candidate.profile_picture}
                                            alt={candidate.user}
                                            className="w-12 h-12 rounded-full object-cover"
                                        />
                                        <div className="max-w-xs">
                                            <span className="text-gray-900 font-semibold block truncate">{candidate.user}</span>
                                            <p className="text-gray-600 text-sm truncate">{candidate.message}</p>
                                        </div>
                                    </div>
                                    <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
                                </div>
                            ))
                        ) : (
                            <p className='text-muted p-4 bg-gray-100 rounded-full text-center'>No hay candidatos disponibles</p>
                        )}
                    </motion.div>
                )}

                {/* Selección de duración (Paso 3) */}
                {step === 3 && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="px-6 py-4 space-y-4"
                    >
                        <h3 className="text-center text-gray-900 mb-4">Selecciona la duración del alquiler</h3>
                        {rentsDurations.map((duration) => (
                            <button
                                key={duration.id}
                                onClick={() => handleDurationSelection(duration)}
                                className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg shadow-sm hover:bg-gray-100 transition"
                            >
                                <span>{duration.name}</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        ))}
                    </motion.div>
                )}

                {/* Evaluación del inquilino (Paso 4) */}
                {step === 4 && rentedBy && (
                    <RenterEvaluation rentHistoryId={rentHistoryId} property={property} token={token} renter={rentedBy} onClose={closeModal} />
                )}

                <div className="flex justify-end px-6 py-4">
                    <button
                        onClick={closeModal}
                        className="px-6 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-lg transition"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </motion.div>
    );
};

export default PropertyStatusModal;
