import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation } from 'react-router-dom';
import TarjetaDetallePropiedad from '../components/cards/TarjetaDetallePropiedad';
import NavBar from '../components/header/Navbar';

const DetallePropiedadPreview = () => {
    const location = useLocation();
    // Asumiendo que el objeto de ubicación está dentro de la propiedad en el estado del enrutador
    const propiedad = location.state ? location.state.propiedad : null;
    console.log(location);
    const [isShortStay, setIsShortStay] = useState(false);

    console.log(location);

    if (!propiedad) {
        // Si no hay datos de propiedad, posiblemente porque se recargó la página, manejarlo adecuadamente
        return <div>No se ha encontrado la información de la propiedad.</div>;
    }

    console.log(propiedad);

    const formatData = (data) => data ? data.toString() : 'No disponible';

    return (
        <>
            <Helmet>
                <title>{formatData(propiedad.format_address.address_name)}</title>
                <meta property="og:title" content={formatData(propiedad.format_address.address_name)} />
                <meta property="og:description" content={`Ubicada en ${formatData(propiedad.format_address.formatted_address)}`} />
                <meta property="og:image" content={propiedad.images ? formatData(propiedad.images[0]?.image) : undefined} />
                <meta property="og:url" content={`http://yorento.com/propiedad/${formatData(propiedad.id)}/detalle`} />
                <meta property="og:type" content="article" />
            </Helmet>
            <NavBar />
            <TarjetaDetallePropiedad property={propiedad} />
        </>
    );
};

export default DetallePropiedadPreview;
