import { useState, useEffect } from 'react';
import Conversation from '../../assets/drawe-icon-conversation.png';
import axios from 'axios';
import Spinner from '../Loader/Spinner';
import { useOutletContext } from 'react-router-dom';
import { sentRequestChat } from '../../services/chats.service';
import { updateUserData } from '../../redux/actions/auth-action';
import { useDispatch } from 'react-redux';

const Photo = ({ isOpen, onClose, id, onSuccess }) => {
    const [step, setStep] = useState(1);
    const [imageFile, setImageFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const token = useOutletContext().token;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isOpen) {
            setStep(1);
            setImageFile(null);
            setPreviewUrl(null);
        }
    }, [isOpen]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        if (imageFile) {
            try {
                setIsLoading(true);
                const formData = new FormData();
                formData.append('profilePicture', imageFile);
                const response = await axios.put('/app/core/users', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });

                const updatedUserData = response.data;
                dispatch(updateUserData(updatedUserData));

                onSuccess && onSuccess(true);
                onClose();
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
                    {step === 1 && (
                        <div className="text-center">
                            <img src={Conversation} alt="Conversación" />
                            <h2 className="text-2xl font-bold text-purple-700">¿Deseas enviar una solicitud de chat al dueño de este inmueble?</h2>
                            <p className="text-gray-600 mt-4">Para que puedas chatear necesitamos corroborar que eres tú quien envía la solicitud mediante una selfie.</p>
                            <div className="mt-6">
                                <button
                                    className="bg-purple-700 text-white px-4 py-2 rounded-lg mr-4 hover:bg-purple-800 transition"
                                    onClick={() => setStep(2)}
                                >
                                    Comenzar
                                </button>
                                <button
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="text-center">
                            <h2 className="text-2xl font-bold text-blue-600">Subir imagen</h2>
                            <input
                                type="file"
                                accept="image/*"
                                className="mt-4 border border-gray-300 rounded-lg px-3 py-2 w-full"
                                onChange={handleFileChange}
                            />
                            {previewUrl && (
                                <div className="mt-4">
                                    <h3 className="text-lg text-gray-700">Vista previa:</h3>
                                    <img
                                        src={previewUrl}
                                        alt="Vista previa"
                                        className="mt-2 rounded-lg shadow-lg max-w-full max-h-60 mx-auto"
                                    />
                                </div>
                            )}
                            <div className="mt-6">
                                <button
                                    className={`bg-blue-600 text-white px-4 py-2 rounded-lg mr-4 hover:bg-blue-700 transition ${!imageFile ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={() => setStep(3)}
                                    disabled={!imageFile}
                                >
                                    Aceptar
                                </button>
                                <button
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="text-center">
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <div>
                                    <h2 className="text-2xl font-bold text-purple-700">Confirmación de envío</h2>
                                    <p className="text-gray-600 mt-4">Haz click en "Enviar" para completar la acción.</p>
                                    <div className="mt-6">
                                        <button
                                            className="bg-purple-700 text-white px-4 py-2 rounded-lg mr-4 hover:bg-purple-800 transition"
                                            onClick={handleSubmit}
                                        >
                                            Enviar
                                        </button>
                                        <button
                                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
                                            onClick={onClose}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default Photo;